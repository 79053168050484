import { Button, Input, Modal, Row, Col } from 'antd';
import axios from 'axios';
import { v4 } from 'uuid';
import { Fragment, Component, FormEvent } from 'react';
import update from 'immutability-helper';

import {
  ApiErrorType,
  FloorPlanLocationListType,
  LocationListType,
} from '../../../../type-definitions/api-types';
import {
  getKeysFromEnum,
  handleSorting,
  handleTableSearch,
} from '../../../../utils';
import { mapFormElements, MapFormElementsType } from '../helpers';
import { locationApi } from '../../../../api-services/api-list';
import { webViewApiCall } from '../../../services/api-call';
import { checkValidation } from '../../../../utils/validation';
import { LocationListEnumKeys } from '../../../../api-services/api-responses';
import AntdInput from '../../../../components/AntdInput';
import AntdTable from '../../../../components/AntdTable';
import { AntdTableColumnsType } from '../../../../type-definitions';
import { cloneDeep } from '../../../../shared/helpers';

interface PropsType {
  showLocationModal: boolean;
  handleAddLocationModal: () => void;
  onLocationSelectModal: (locationData: LocationListType) => void;
  floorPlanLat: number;
  floorPlanLng: number;
  floorPlanLocationList: FloorPlanLocationListType[];
  token: string;
}

interface StateType {
  loading: boolean;
  searchInput: string;
  locationList: LocationListType[];
  initLocationList: LocationListType[];
  formElements: MapFormElementsType;
  error: ApiErrorType;
}

class AddLocationModal extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      loading: true,
      searchInput: '',
      locationList: [],
      initLocationList: [],
      formElements: { ...mapFormElements },
      error: {},
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleFetchedData = async (formElem?: MapFormElementsType) => {
    const { formElements } = this.state;
    const { floorPlanLat, floorPlanLng, token } = this.props;

    let tempFormElements = { ...formElements };

    if (formElem) {
      tempFormElements = formElem;
    }

    try {
      const { url, method, params, contentType } = locationApi.getLocations({
        region: 'circle',
        centre: `${floorPlanLat || 0},${floorPlanLng || 0}`,
        radius: tempFormElements.radius.value,
      });
      const response = await webViewApiCall({
        token,
        url,
        method,
        params,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result.data) {
        const { floorPlanLocationList } = this.props;

        let tempLocationList: LocationListType[] = result.data.map(
          (item: LocationListType) => ({
            ...item,
            uuid: v4(),
          })
        );
        if (floorPlanLocationList.length > 0) {
          tempLocationList = tempLocationList.filter((item) => {
            const temp = floorPlanLocationList.find((el) => {
              return item.locationID === el.locationID;
            });
            if (temp) {
              return null;
            }
            return item;
          });
        }
        this._isMounted &&
          this.setState({
            loading: false,
            locationList: tempLocationList,
            initLocationList: tempLocationList,
          });
      } else {
        this._isMounted &&
          this.setState({
            loading: false,
          });
      }
    } catch (error: any) {
      this._isMounted &&
        this.setState({
          loading: false,
          error,
        });
    }
  };

  inputChangedHandler = (name: keyof MapFormElementsType, value: string) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    if (name) {
      tempFormElements = update(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });

      this._isMounted &&
        this.setState({ formElements: tempFormElements, loading: true }, () => {
          this.handleFetchedData(tempFormElements);
        });
    }
  };

  handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;
    const { initLocationList } = this.state;

    const stateData: Partial<StateType> = {
      searchInput: value,
    };

    const tempInitData = cloneDeep(initLocationList);

    if (value) {
      const tableColumnsKeys = getKeysFromEnum(LocationListEnumKeys);
      const tempData = handleTableSearch({
        data: tempInitData,
        columnList: tableColumnsKeys,
        searchData: value,
      });
      stateData.locationList = tempData;
    } else {
      stateData.locationList = [...tempInitData];
    }

    this.setState((prev) => ({ ...prev, ...stateData }));
  };

  render() {
    const { showLocationModal, handleAddLocationModal, onLocationSelectModal } =
      this.props;
    const { formElements, searchInput, loading, locationList } = this.state;
    return (
      <Fragment>
        <Modal
          bodyStyle={{ cursor: 'pointer', resize: 'none' }}
          closeIcon={false}
          visible={showLocationModal}
          onCancel={handleAddLocationModal}
          footer={null}
          width={760}>
          <Row justify="center">
            <Col xs={24} sm={12}>
              <AntdInput
                {...formElements.radius}
                onInputChanged={this.inputChangedHandler}
              />
            </Col>
          </Row>
          <Row justify="center" className="px-1 py-3">
            <Col xs={24} sm={12} className="text-center">
              <Input
                size="large"
                onChange={this.handleSearch}
                value={searchInput}
                placeholder="Search..."
                style={{ width: '100%' }}
              />
            </Col>
          </Row>

          <Row className="px-1">
            <Col xs={24} className="col-lg-12">
              <AntdTable
                loading={loading}
                dataSource={locationList}
                columns={getColumns(onLocationSelectModal)}
                customConfig={{ rowKeyValue: 'uuid' }}
                pagination={{
                  pageSize: 3,
                  total: locationList.length,
                  size: 'small',
                }}
              />
            </Col>
          </Row>
        </Modal>
      </Fragment>
    );
  }
}

export default AddLocationModal;

function getColumns(
  handleLocationSelect: (data: LocationListType) => void
): AntdTableColumnsType<LocationListType>[] {
  return [
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.locationName, b.locationName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Friendly Name',
      dataIndex: 'friendlyName',
      key: 'friendlyName',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.friendlyName, b.friendlyName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Partner ID',
      key: 'partnerID',
      dataIndex: 'partnerID',
      sorter: (a: LocationListType, b: LocationListType) =>
        handleSorting(a.partnerID, b.partnerID),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record: LocationListType) => {
        return (
          <Fragment>
            <div className="row">
              <div className="col-2">
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => handleLocationSelect?.(record)}>
                  Select
                </Button>
              </div>
            </div>
          </Fragment>
        );
      },
    },
  ];
}
