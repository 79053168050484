import {
  Fragment,
  PureComponent,
  createRef,
  ReactNode,
  CSSProperties,
} from 'react';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import * as Leaflet from 'leaflet';

import MarkerIconBlue from '../../assets/images/map/marker-icon-blue.png';
import MarkerIconRed from '../../assets/images/map/marker_red.png';
import MarkerIconGrey from '../../assets/images/map/marker_grey.png';
import MarkerIconGreen from '../../assets/images/map/marker_green.png';
import MarkerShadow from '../../assets/images/map/marker-shadow.png';

import 'leaflet/dist/leaflet.css';

import cssStyles from './leafletMapMultiple.module.scss';

const blueIcon = Leaflet.icon({
  iconUrl: MarkerIconBlue,
  shadowUrl: MarkerShadow,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
});

const redIcon = Leaflet.icon({
  iconUrl: MarkerIconRed,
  shadowUrl: MarkerShadow,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
});

const greenIcon = Leaflet.icon({
  iconUrl: MarkerIconGreen,
  shadowUrl: MarkerShadow,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
});

const greyIcon = Leaflet.icon({
  iconUrl: MarkerIconGrey,
  shadowUrl: MarkerShadow,
  iconSize: [25, 41],
  shadowSize: [41, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
});

type PropsType = {
  center: Leaflet.LatLng | Leaflet.LatLngLiteral | Leaflet.LatLngTuple;
  mapList: any[];
  classes?: string;
  zoom?: number;
  height?: number;
  onMarkerMove?: (lat: number, lng: number, name: any) => void;
  onMapZoom?: (zoom: number) => void;
  styles?: CSSProperties;
  popupElement?: ReactNode;
  markerProps?: { draggable?: boolean };
};

interface StateType {
  mapBounds?: Leaflet.LatLngBounds;
  mapInstance?: Leaflet.Map;
  mapCenter: Leaflet.LatLng;
}

class LeafletMapMultipleDraggable extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      mapBounds: undefined,
      mapInstance: undefined,
      mapCenter: Leaflet.latLng(0, 0),
    };
  }

  tileLayerRef = createRef<Leaflet.TileLayer>();
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleMap = (map: Leaflet.Map) => {
    if (map) {
      const { onMapZoom } = this.props;
      const bounds = map.getBounds();
      const center = map.getBounds().getCenter();
      map.fitBounds(bounds);

      this._isMounted &&
        this.setState({
          mapBounds: bounds,
          mapInstance: map,
          mapCenter: center,
        });

      map.on('zoomend', function (event) {
        // onMapZoom?.(map.getZoom());
        onMapZoom?.(event.target?.getZoom?.());
      });
    }
  };

  onMoveMarker = (event: Leaflet.DragEndEvent, key: string) => {
    const { onMarkerMove } = this.props;
    const tempLatLng = event?.target?.getLatLng?.();

    onMarkerMove?.(tempLatLng.lat, tempLatLng.lng, key);
  };

  render() {
    const { classes, center, zoom, mapList, styles, markerProps } = this.props;

    const mapContainerProps: {
      style?: CSSProperties;
      className?: string;
      center?: Leaflet.LatLng | Leaflet.LatLngLiteral | Leaflet.LatLngTuple;
    } = {};

    if (styles && classes) {
      mapContainerProps.style = styles;
      mapContainerProps.className = classes || '';
    } else if (styles) {
      mapContainerProps.style = styles;
    } else if (classes) {
      mapContainerProps.className = classes || '';
    } else {
      mapContainerProps.className = cssStyles.map;
    }

    if (mapList.length > 0) {
      mapContainerProps.center = [mapList[0].lat, mapList[0].lng];
    } else {
      mapContainerProps.center = center || [0, 0];
    }

    return (
      <Fragment>
        <MapContainer
          {...mapContainerProps}
          tap={false}
          whenCreated={this.handleMap}
          zoom={zoom || 10}
          maxZoom={18}>
          <TileLayer
            ref={this.tileLayerRef}
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {mapList &&
            mapList.length > 0 &&
            mapList.map((item) => {
              let icon = blueIcon;

              if (item.iconColor === 'green') {
                icon = greenIcon;
              }

              if (item.iconColor === 'grey') {
                icon = greyIcon;
              }

              if (item.iconColor === 'red') {
                icon = redIcon;
              }

              if (item.iconColor === 'blue') {
                icon = blueIcon;
              }

              return (
                <Fragment key={item.uuid}>
                  <Marker
                    position={[item.lat, item.lng]}
                    // position={center}
                    icon={icon}
                    draggable={
                      markerProps?.draggable !== undefined
                        ? markerProps?.draggable
                        : true
                    }
                    eventHandlers={{
                      dragend: (event) => {
                        this.onMoveMarker(event, item.markerName);
                      },
                    }}>
                    <Popup>
                      <div>Latitude: {item.lat}</div>
                      <div>Longitude: {item.lng}</div>
                    </Popup>
                  </Marker>
                </Fragment>
              );
            })}
        </MapContainer>
      </Fragment>
    );
  }
}

export default LeafletMapMultipleDraggable;
