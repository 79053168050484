import { FormInputType } from '../../../../type-definitions';

export interface AddressFormElementsType {
  street: FormInputType;
  street2: FormInputType;
  city: FormInputType;
  province: FormInputType;
  postalCode: FormInputType;
  country: FormInputType;
}

export const initAddressFormElements: AddressFormElementsType = {
  street: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Address Line 1',
      name: 'street',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Address Line 1 is required',
    label: 'Address',
    colValue: 12,
  },

  street2: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Address Line 2',
      name: 'street2',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Address Line 2 is required',
    colValue: 12,
  },

  city: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Town/City',
      name: 'city',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Town/City is required',
    colValue: 6,
  },

  province: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'State/Province/County',
      name: 'province',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'State/Province/County is required',
    colValue: 6,
  },

  postalCode: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Postal Code',
      name: 'postalCode',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Postal Code is required',
    colValue: 6,
  },

  country: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Country',
      name: 'country',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Country is required',
    colValue: 6,
  },
};
