import { Fragment, ReactNode } from 'react';
import { Row, Col } from 'antd';
import AntdCoverSpinner from '../../components/AntdCoverSpinner';
import AntdCard from '../AntdCard';

import cssStyles from './cardWrapper.module.scss';

interface PropsType {
  loading?: boolean;
  spinner?: boolean;
  children?: ReactNode;
  height?: number;
  className?: string;
}

const CardWrapper = ({
  loading = false,
  spinner = false,
  children,
  className,
}: PropsType) => {
  return (
    <Fragment>
      <Row className={`px-3 ${cssStyles.root} ${className ?? ''}`}>
        <Col xs={24}>
          <AntdCoverSpinner active={spinner}>
            <AntdCard loading={loading}>
              <Row className="h-100">
                <Col xs={24}>{children}</Col>
              </Row>
            </AntdCard>
          </AntdCoverSpinner>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CardWrapper;
