import {
  AddressFormElementsType,
  initAddressFormElements,
} from '../../../shared/components/Address/helpers';
import { updateImmutably } from '../../../shared/helpers';
import { reducerHookActions } from '../../../shared/helpers/reducer';
import {
  FormInputType,
  ReducerHookActionType,
} from '../../../type-definitions';

export interface StateType {
  formElements: FormElementsType;
  loading: boolean;
  hasInputChanged: boolean;
  currentTabIndex: string;
}

export interface FormElementsType extends AddressFormElementsType {
  buildingName: FormInputType;
  partner: FormInputType;
}

export const initFormElements: FormElementsType = {
  buildingName: {
    elementType: 'input',
    elementConfig: {
      name: 'buildingName',
      type: 'text',
      placeholder: 'Building Name',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Building Name is required',
    label: 'Building Name *',
  },
  partner: {
    elementType: 'select',
    elementConfig: {
      name: 'partner',
      type: 'text',
      placeholder: 'Partner',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner is required',
    label: 'Partner *',
  },
  ...initAddressFormElements,
};

export const initState: StateType = {
  formElements: { ...initFormElements },
  loading: false,
  hasInputChanged: false,
  currentTabIndex: '1',
};

export const reducer = (state: StateType, action: ReducerHookActionType) => {
  switch (action.type) {
    case reducerHookActions.setState: {
      const newState = updateImmutably(state, {
        $merge: { ...action.payload },
      });
      return { ...newState };
    }

    default:
      return { ...state };
  }
};
