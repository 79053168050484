import { Fragment, useEffect } from 'react';
import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Typewriter from 'typewriter-effect';

import cssStyles from '../styles/journal.module.scss';

const GroupAssignmentContent = ({
  locationID,
  groupID,
  entryType,
  userName,
}: {
  locationID: string;
  groupID: string;
  entryType: string;
  userName: string;
}) => {
  const screen = useBreakpoint();

  useEffect(() => {
    const wrapperElements = document.getElementsByClassName(
      'Typewriter__wrapper'
    );
    if (wrapperElements && wrapperElements.length > 0) {
      for (const elem of wrapperElements) {
        elem.classList.add(cssStyles.wrapper);
      }
    }
  }, [groupID, locationID]);

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          {entryType && (
            <Row justify="center">
              <Col style={{ fontSize: 24 }}>
                <b>{entryType}</b>
              </Col>
            </Row>
          )}
          {userName && (
            <Row justify="center">
              <Col>
                <b>Actioned By: {userName}</b>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row justify="center" align="middle" className="h-100">
        <Col xs={24}>
          <Row justify="center" align="middle">
            <Col
              xs={24}
              md={10}
              style={{ textAlign: screen.md ? 'right' : 'center' }}>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString('Location ID:')
                    .pauseFor(500)
                    .start()
                    .callFunction(() => {
                      hideTypewriterCursor();
                    });
                }}
              />
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                textAlign: screen.md ? 'left' : 'center',
                paddingLeft: screen.md ? 12 : undefined,
              }}>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(locationID)
                    .pauseFor(500)
                    .start()
                    .callFunction(() => {
                      hideTypewriterCursor();
                    });
                }}
              />
            </Col>
          </Row>
          <Row justify="center" align="middle" className="pt-4">
            <Col
              xs={24}
              md={10}
              style={{ textAlign: screen.md ? 'right' : 'center' }}>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString('Group ID:')
                    .pauseFor(500)
                    .start()
                    .callFunction(() => {
                      hideTypewriterCursor();
                    });
                }}
              />
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                textAlign: screen.md ? 'left' : 'center',
                paddingLeft: screen.md ? 12 : undefined,
              }}>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(groupID)
                    .pauseFor(500)
                    .start()
                    .callFunction(() => {
                      hideTypewriterCursor();
                    });
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default GroupAssignmentContent;

const hideTypewriterCursor = () => {
  const cursorElements = document.getElementsByClassName('Typewriter__cursor');
  if (cursorElements && cursorElements.length > 0) {
    for (const elem of cursorElements) {
      elem.classList.add('v-hidden');
    }
  }
};
