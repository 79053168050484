import { FormInputElementType, FormInputType } from '../../type-definitions';

export const createDynamicFormInput = (
  fieldName: string,
  elementType: FormInputElementType
): FormInputType => {
  const formInput: { [k: string]: FormInputType } = {
    [fieldName]: {
      elementType: elementType,
      elementConfig: {
        type: elementType === 'input' ? 'text' : undefined,
        placeholder: '',
        name: fieldName,
      },
      value: '',
      optionValues:
        elementType === 'select' || elementType === 'multiSelect'
          ? []
          : undefined,
      validation: {},
      valid: false,
      touched: false,
      errorMessage: '',
      label: '',
    },
  };
  return formInput[fieldName];
};
