import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { authApi } from './api-services/api-list';
import AntdNavbar from './components/AntdNavbar';
import ErrorBoundary from './components/ErrorBoundary';
import useHttp from './hooks/use-http-allSettled';
import { authLogout, authSuccess, sharedSuccess } from './redux/actions';
import {
  getUserData,
  getUserPermissionList,
  getUserRightList,
  getAuthError,
} from './redux/selectors';
import Routes from './Routes';
import { getFromLocal } from './shared/helpers';
import {
  ApiResponseConfigDataType,
  ApiResponseDataType,
  UserDataType,
  UserRightListType,
} from './type-definitions/api-types';
import { handleUserPermssion } from './utils';
import { handleNotification } from './utils/notification-handler';

const App = () => {
  const { sendRequest } = useHttp();
  const userData = useSelector(getUserData);
  const userPermissionList = useSelector(getUserPermissionList);
  const userRightList = useSelector(getUserRightList);
  const authError = useSelector(getAuthError);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = getFromLocal('token');
    if (!userData.token && token) {
      const { url, method, contentType } = authApi.getTokenLogin();

      const handleResponses = (
        responses: PromiseSettledResult<
          ApiResponseConfigDataType<ApiResponseDataType>
        >[]
      ) => {
        if (responses.length > 0) {
          if (responses[0].status === 'fulfilled') {
            const tokenResult = responses[0].value;
            if (tokenResult.data?.data) {
              const tempUserData: UserDataType = tokenResult.data.data;
              if (tempUserData) {
                dispatch(authSuccess({ userData: tempUserData }));
              }
            }
          } else {
            handleNotification(
              'error',
              responses[0].reason?.response?.data?.message
            );
          }
        }
      };

      sendRequest({
        requestConfig: [{ url, method, contentType }],
        headersConfig: { storeToken: token },
        applyData: handleResponses,
      });
    }
  }, [dispatch, sendRequest, userData.token]);

  useEffect(() => {
    if (userData.token && userRightList.length === 0) {
      const { url, method, contentType } = authApi.getUserRights();

      const handleResponses = (
        responses: PromiseSettledResult<
          ApiResponseConfigDataType<ApiResponseDataType>
        >[]
      ) => {
        if (responses.length > 0) {
          if (responses[0].status === 'fulfilled') {
            const result = responses[0].value;
            if (result.data?.data) {
              const tempUserRightList: UserRightListType[] = result.data.data;
              if (tempUserRightList) {
                dispatch(
                  authSuccess({
                    userRightList: tempUserRightList,
                  })
                );

                if (userPermissionList.length === 0 && userData.partnerID) {
                  // const tempPermission = handleUserPermssion(
                  //   tempUserRightList,
                  //   userData.partnerID
                  // );
                  const tempPermission = handleUserPermssion(
                    tempUserRightList
                    // userData.partnerID
                  );
                  dispatch(
                    sharedSuccess({ userPermissionList: tempPermission })
                  );
                }
              }
            } else {
              handleNotification('error', {
                message: "Sorry, you don't have rights to access any page!",
              });
            }
          } else {
            handleNotification('error', {
              message: responses[0].reason?.response?.data?.message,
            });
          }
        }
      };

      sendRequest({
        requestConfig: [{ url, method, contentType }],
        headersConfig: { storeToken: userData.token },
        applyData: handleResponses,
      });
    }
  }, [
    dispatch,
    sendRequest,
    userData.partnerID,
    userData.token,
    userPermissionList.length,
    userRightList.length,
  ]);

  useEffect(() => {
    if (
      authError.status === 'invalidTokenError' ||
      authError.status === 'notAuthorized'
    ) {
      handleNotification('error', { message: authError.message });
      dispatch(authLogout());
    }
  }, [authError.message, authError.status, dispatch]);

  useEffect(() => {
    if (userRightList.length > 0 && userPermissionList.length === 0) {
      const tempPermission = handleUserPermssion(userRightList);
      dispatch(sharedSuccess({ userPermissionList: tempPermission }));
    }
  }, [dispatch, userPermissionList.length, userRightList]);

  let navBarVisibility = true;
  if (location?.pathname.includes('webview')) {
    navBarVisibility = false;
  }

  return (
    <Fragment>
      <ErrorBoundary>
        {navBarVisibility && <AntdNavbar />}
        <Routes />
      </ErrorBoundary>
    </Fragment>
  );
};

export default App;
