import update from 'immutability-helper';
import { reducerHookActions } from '../../../shared/helpers';

import {
  BooleanObjectType,
  FormInputType,
  ReducerHookActionType,
} from '../../../type-definitions';

export type StateType = {
  loading: boolean;
  loadingButton: boolean;
  currentTabIndex: string;
  detailsTabFormElements: DetailsTabFormElementsType;
  showMap: boolean;
  mapZoom: number;
  initLat: string;
  initLng: string;
  initZoom: number;
  hasInputChanged: boolean;

  allowEdit: boolean;
  partnerID: string;
};

export type DetailsTabFormElementsType = {
  // 0
  partnerID: FormInputType;
  // 1
  partnerName: FormInputType;
  // 2
  latitude: FormInputType;
  // 3
  longitude: FormInputType;
  // 4
  prefix: FormInputType;
  // 5
  childrenAllowed: boolean;
};

export const detailsTabInitElements: DetailsTabFormElementsType = {
  partnerID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Partner ID',
      name: 'partnerID',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner ID is required',
    label: 'Partner ID *',
    disabled: true,
  },
  partnerName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Partner Name',
      name: 'partnerName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner Name is required',
    label: 'Partner Name *',
  },
  latitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Latitude',
      name: 'latitude',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Latitude is required',
    label: 'Lat *',
    colValue: 8,
  },
  longitude: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Longitude',
      name: 'longitude',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Longitude is required',
    label: 'Long *',
    colValue: 8,
  },
  prefix: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Prefix',
      name: 'prefix',
    },
    value: '',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Prefix is required',
    label: 'Prefix *',
    disabled: true,
  },
  childrenAllowed: false,
};

export const createRoleInitFormElements: CreateRoleFormElementsType = {
  // 0
  groupID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Group ID',
      name: 'groupID',
    },
    value: '',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Group ID is required',
    label: 'Group ID',
    // disabled: true
  },
  // 1
  partnerID: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Partner ID',
      name: 'partnerID',
    },
    optionValues: [],
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Partner ID is required',
    label: 'Partner ID',
    disabled: true,
  },
  // 2
  groupName: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Group Name',
      name: 'groupName',
    },
    value: '',
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    errorMessage: 'Group Name is required',
    label: 'Group Name *',
    // disabled: true
  },
  // 3
  // groupType: {
  //   elementType: 'select',
  //   elementConfig: {
  //     type: 'text',
  //     // placeholder: 'Group Type',
  //     name: 'groupType',
  //   },
  //   optionValues: [
  //     { value: '', text: 'Please Select' },
  //     { value: 'NORMAL', text: 'NORMAL' },
  //     { value: 'SYSTEM', text: 'SYSTEM' },
  //     { value: 'ROLE', text: 'ROLE' },
  //   ],
  //   value: '',
  //   validation: {
  //     // required: true
  //   },
  //   valid: false,
  //   touched: false,
  //   errorMessage: 'Group Type is required',
  //   label: 'Group Type',
  //   colValue: 12,
  //   // disabled: true
  // },
  // 4
  dateTimeRadio: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      // placeholder: 'Partner ID',
      name: 'dateTimeRadio',
    },
    optionValues: [
      {
        value: 'dateAccess',
        text: 'Date Access',
      },
      {
        value: 'rollingAccess',
        text: 'Rolling Access',
      },
      {
        value: 'unlimitedAccess',
        text: 'Unlimited Access',
      },
    ],
    value: 'dateAccess',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: '',
    label: '',
    colValue: 8,
    breakPoint: 'sm',
  },
  // 5
  minDate: {
    elementType: 'date',
    elementConfig: {
      // type: 'text',
      // placeholder: 'From',
      name: 'minDate',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'From is required',
    label: 'From',
    // disabled: true
  },
  // 6
  minTime: {
    elementType: 'time',
    elementConfig: {
      // type: 'text',
      // placeholder: 'From',
      name: 'minTime',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: '',
    label: 'nbsp',
  },
  // 7
  maxDate: {
    elementType: 'date',
    elementConfig: {
      // type: 'text',
      // placeholder: 'To',
      name: 'maxDate',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'To is required',
    label: 'To',
    // disabled: true
  },
  // 8
  maxTime: {
    elementType: 'time',
    elementConfig: {
      // type: 'text',
      // placeholder: 'From',
      name: 'maxTime',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: '',
    label: 'nbsp',
    // disabled: true
  },
  // 9
  maxDaysFromToday: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Max Days From Today',
      name: 'maxDaysFromToday',
    },
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Max Days From Today is required',
    label: 'Max Days From Today',
    // disabled: true
  },
  // 10
  phenoms: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      // placeholder: 'Phenom List',
      name: 'phenoms',
      readOnly: true,
    },
    value: '',
    validation: {},
    valid: false,
    touched: false,
    errorMessage: 'Phenom List is required',
    label: 'Phenom List',
    disabled: false,
    styles: { cursor: 'pointer' },
  },
  // 11
  extendedPermission: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      // placeholder: 'Extended Permission',
      name: 'extendedPermission',
      readOnly: true,
    },
    value: '',
    validation: {},
    valid: false,
    touched: false,
    errorMessage: 'Extended Permission is required',
    label: 'Extended Permission',
    disabled: false,
    styles: { cursor: 'pointer' },
  },
  // 12
  deviceManagementPermission: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      name: 'deviceManagementPermission',
      readOnly: true,
    },
    value: '',
    validation: {},
    valid: false,
    touched: false,
    errorMessage: 'Device Management Permission is required',
    label: 'Device Management Permission',
    disabled: false,
    styles: { cursor: 'pointer' },
  },
  roleTemplate: {
    elementType: 'select',
    elementConfig: {
      type: 'text',
      // placeholder: 'Group Type',
      name: 'roleTemplate',
    },
    optionValues: [],
    value: '',
    validation: {
      // required: true
    },
    valid: false,
    touched: false,
    errorMessage: 'Role Template is required',
    label: 'Role Template',
    colValue: 12,
    // disabled: true
  },
  allPhenoms: false,
};

export interface CreateRoleFormElementsType {
  groupID: FormInputType;
  groupName: FormInputType;
  // groupType: FormInputType;
  partnerID: FormInputType;
  dateTimeRadio: FormInputType;
  minDate: FormInputType;
  minTime: FormInputType;
  maxDate: FormInputType;
  maxTime: FormInputType;
  maxDaysFromToday: FormInputType;
  phenoms: FormInputType;
  extendedPermission: FormInputType;
  deviceManagementPermission: FormInputType;
  roleTemplate: FormInputType;
  allPhenoms: boolean;
}

export interface RolesTabStateType {
  rolesList: string[];
  showCreateRoleModal: boolean;
}

export const rolesTabState: RolesTabStateType = {
  rolesList: [],
  showCreateRoleModal: false,
};

export const actionTypes = {
  ...reducerHookActions,
  setModalVisibility: 'setModalVisibility',
  setRoleType: 'setRoleType',
};

export const rolesTabReducer = (
  state: RolesTabStateType,
  action: ReducerHookActionType
) => {
  switch (action.type) {
    case actionTypes.setState: {
      const newState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...newState };
    }

    default:
      return { ...state };
  }
};

export interface CreateRoleModalStateType {
  formElements: CreateRoleFormElementsType;
  showPhenomModal: boolean;
  showExtendedPermissionModal: boolean;
  showDeviceManagementPermissionModal: boolean;
  phenomList: BooleanObjectType;
  extendedList: BooleanObjectType;
  deviceMgtList: BooleanObjectType;
  chooseRoleType: FormInputType;
}

export const createRoleModalState: CreateRoleModalStateType = {
  formElements: createRoleInitFormElements,
  showPhenomModal: false,
  showExtendedPermissionModal: false,
  showDeviceManagementPermissionModal: false,
  phenomList: {},
  extendedList: {},
  deviceMgtList: {},
  chooseRoleType: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      // placeholder: 'Partner ID',
      name: 'chooseRoleType',
    },
    optionValues: [
      {
        value: 'customNewRole',
        text: 'Custom New Role',
      },
      {
        value: 'roleFromTemplate',
        text: 'Role from Template',
      },
    ],
    value: 'customNewRole',
    validation: {
      // required: true,
    },
    valid: false,
    touched: false,
    errorMessage: '',
    label: '',
    colValue: 8,
    breakPoint: 'sm',
  },
};

export const createRoleModalReducer = (
  state: CreateRoleModalStateType,
  action: ReducerHookActionType
) => {
  switch (action.type) {
    case actionTypes.setState: {
      const newState = update(state, {
        $merge: { ...action.payload },
      });
      return { ...newState };
    }

    case actionTypes.setModalVisibility: {
      const name: keyof CreateRoleModalStateType = action.payload?.name;
      const newState = update(state, {
        [name]: { $set: !state[name] },
      });
      return { ...newState };
    }

    case actionTypes.setRoleType: {
      const roleType = action.payload?.roleType;
      const newState = update(state, {
        chooseRoleType: { value: { $set: roleType } },
      });
      return { ...newState };
    }

    default:
      return { ...state };
  }
};
