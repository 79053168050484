import { Fragment, ReactNode } from 'react';
import * as Leaflet from 'leaflet';
import { Marker } from 'react-leaflet';
import { FloorPlanLocationListType } from '../../type-definitions/api-types';
import { getLeafletIcon } from '@airsensa/react-components';

export interface MarkerRenderHandlerType {
  coordinates: Leaflet.LatLngExpression;
  icon?: Leaflet.Icon<Leaflet.IconOptions>;
  matchedLocation?: FloorPlanLocationListType;
  popupDetails?: {
    locationName?: string;
  };
}

interface MapMarkerProps {
  floorPlanLocations: FloorPlanLocationListType[];
  handleMarkerRender: (
    loc: FloorPlanLocationListType
  ) => MarkerRenderHandlerType;
  movedFloorPlanLocations?: FloorPlanLocationListType[];
  isEditable?: boolean;
  isDraggable?: boolean;
  onMoveMarker?: (
    event: Leaflet.DragEndEvent,
    location: FloorPlanLocationListType
  ) => void;
  markerPopupRender?: (params: {
    matchedLocation?: FloorPlanLocationListType;
    floorPlanLocation?: FloorPlanLocationListType;
    popupDetails?: {
      locationName?: string;
    };
    isEditable?: boolean;
    handleReset?: (location: FloorPlanLocationListType) => void;
    handleConfirm?: (location: FloorPlanLocationListType) => void;
    handleDelete?: (location: FloorPlanLocationListType) => void;
  }) => ReactNode;
  handleReset?: (location: FloorPlanLocationListType) => void;
  handleConfirm?: (location: FloorPlanLocationListType) => void;
  handleDelete?: (location: FloorPlanLocationListType) => void;
}

const MapMarkers = ({
  floorPlanLocations,
  movedFloorPlanLocations,
  isDraggable,
  onMoveMarker,
  handleMarkerRender,
  markerPopupRender,
  ...restProps
}: MapMarkerProps) => {
  return (
    <Fragment>
      {floorPlanLocations.length > 0 &&
        floorPlanLocations.map((el, idx) => {
          if (el.x && el.y) {
            const { coordinates, ...rest } = handleMarkerRender(el);

            return (
              <Fragment key={idx}>
                <Marker
                  title={el.locationID}
                  icon={rest.icon ?? getLeafletIcon('blue')}
                  position={coordinates}
                  eventHandlers={
                    isDraggable
                      ? {
                          dragend: (event) => {
                            onMoveMarker?.(event, el);
                          },
                        }
                      : undefined
                  }
                  draggable={isDraggable ?? false}>
                  {markerPopupRender?.({
                    matchedLocation: rest.matchedLocation,
                    popupDetails: rest.popupDetails,
                    floorPlanLocation: el,
                    ...restProps,
                  })}
                </Marker>
              </Fragment>
            );
          }
          return null;
        })}
    </Fragment>
  );
};

export default MapMarkers;
