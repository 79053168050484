import { Button, Col, Row } from 'antd';
import { Fragment } from 'react';
import {
  CurrentTabIndexHandlerType,
  InputChangedHandlerType,
} from '../../../type-definitions';
import AntdInput from '../../AntdInput';

import { FormElementsType } from '../helpers';

interface PropsType {
  formElements: FormElementsType;
  inputChangedHandler: InputChangedHandlerType;
  setCurrentTabIndex: CurrentTabIndexHandlerType;
}

const DetailsTab = ({
  formElements,
  inputChangedHandler,
  setCurrentTabIndex,
}: PropsType) => {
  return (
    <Fragment>
      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.buildingName}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.partner}
            onInputChanged={inputChangedHandler}
          />
        </Col>
      </Row>

      <Row justify="center">
        <Col xs={20} md={8}>
          <Button
            htmlType="button"
            type="primary"
            size="large"
            block
            onClick={() => setCurrentTabIndex('2')}>
            Next
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DetailsTab;
