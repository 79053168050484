import { Fragment } from 'react';
import { Col, Row, Typography } from 'antd';

const NoteContent = ({
  text,
  entryType,
  userName,
}: {
  text: string;
  entryType: string;
  userName: string;
}) => {
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          {entryType && (
            <Row justify="center">
              <Col style={{ fontSize: 24 }}>
                <b>{entryType}</b>
              </Col>
            </Row>
          )}
          {userName && (
            <Row justify="center">
              <Col>
                <b>Actioned By: {userName}</b>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row justify="center" align="middle" className="h-100">
        <Col>
          <Typography.Text keyboard strong style={{ fontSize: '1.5rem' }}>
            {text}
          </Typography.Text>
        </Col>
      </Row>
    </Fragment>
  );
};

export default NoteContent;
