import { Col, Row } from 'antd';
import { Component, Fragment } from 'react';
import * as Leaflet from 'leaflet';
import { FloorPlanMap, getLeafletIcon } from '@airsensa/react-components';
import {
  FloorPlanMapDetailsType,
  MapCoordinatesType,
} from '@airsensa/react-components/dist/@types';

import {
  FloorPlanLocationListType,
  LocationListType,
} from '../../../../type-definitions/api-types';

import cssStyles from '../styles/webViewFloorPlanDetails.module.scss';
import MapMarkers, {
  MarkerRenderHandlerType,
} from '../../../../components-shared/MapMarkers';
import PopupWithEdit from '../../../../components-shared/MarkerPopup/PopupWithEdit';
import { cloneDeep } from '../../../../shared/helpers';

interface MapContentPropsType {
  mapDetails: FloorPlanMapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocation: Partial<LocationListType>;
  onUpdateLocation: (data: FloorPlanLocationListType) => void;
  onRemoveLocation: (data: FloorPlanLocationListType) => void;
  setMapDefaultParameters: (coordinates: { x: number; y: number }) => void;
  isEditable?: boolean;
}

interface MapContentStateType {
  floorPlanLocations: FloorPlanLocationListType[];
  selectedLoc: Partial<LocationListType>;
  movedFloorPlanLocations: FloorPlanLocationListType[];
  mapInstance?: Leaflet.Map;
}

class MapContent extends Component<MapContentPropsType, MapContentStateType> {
  constructor(props: MapContentPropsType) {
    super(props);

    this.state = {
      floorPlanLocations: [],
      selectedLoc: {},
      movedFloorPlanLocations: [],
      mapInstance: undefined,
    };
  }

  componentDidMount() {
    this.handleFloorPlanLocations();
  }

  componentDidUpdate(
    prevProps: MapContentPropsType,
    prevState: MapContentStateType
  ) {
    const { selectedLocation } = this.props;
    if (
      prevProps.selectedLocation?.locationID !== selectedLocation?.locationID
    ) {
      this.setState({ selectedLoc: selectedLocation });
    }

    this.handleFloorPlanLocations();
  }

  handleFloorPlanLocations = () => {
    const { floorPlanLocations } = this.state;
    const { floorPlanLocationList } = this.props;

    if (floorPlanLocationList.length !== floorPlanLocations.length) {
      this.setState({ floorPlanLocations: floorPlanLocationList });
    }
  };

  handleMap = ({
    center,
    map,
  }: {
    map: Leaflet.Map;
    center: MapCoordinatesType;
  }) => {
    const { setMapDefaultParameters } = this.props;
    const { mapInstance } = this.state;
    if (center) {
      setMapDefaultParameters?.({ x: center[0], y: center[1] });
    }

    if (map && !mapInstance) {
      this.setState({ mapInstance: map });
    }
  };

  onMoveMarker = (
    event: Leaflet.DragEndEvent,
    location: FloorPlanLocationListType
  ) => {
    const { movedFloorPlanLocations } = this.state;

    const coordinates = event?.target?.getLatLng?.();

    let temp: FloorPlanLocationListType[] = [];
    if (movedFloorPlanLocations.length > 0 && coordinates?.x) {
      temp = cloneDeep(movedFloorPlanLocations);
      const matched = temp.find((el) => el.locationID === location?.locationID);
      if (matched) {
        temp = temp.map((item) => {
          if (location.locationID === item.locationID) {
            item.x = coordinates.lat;
            item.y = coordinates.lng;
          }
          return item;
        });
      } else {
        temp.push({ ...location, x: coordinates.lat, y: coordinates.lng });
      }
    } else {
      temp.push({ ...location, x: coordinates.lat, y: coordinates.lng });
    }

    this.setState({ movedFloorPlanLocations: temp, selectedLoc: {} });
  };

  handleReset = (location: Partial<FloorPlanLocationListType>) => {
    const { movedFloorPlanLocations, mapInstance } = this.state;
    const temp = movedFloorPlanLocations.filter(
      (item) => item.locationID !== location.locationID
    );
    mapInstance?.closePopup?.();
    this.setState({
      movedFloorPlanLocations: temp,
    });
  };

  handleDelete = (location: FloorPlanLocationListType) => {
    const { floorPlanLocations, mapInstance } = this.state;
    const { onRemoveLocation } = this.props;
    const temp = floorPlanLocations.filter(
      (item) => item.locationID !== location.locationID
    );
    mapInstance?.closePopup?.();
    onRemoveLocation(location);
    this.setState({
      floorPlanLocations: temp,
      selectedLoc: {},
    });
  };

  handleConfirm = (locationData: FloorPlanLocationListType) => {
    const { mapInstance, floorPlanLocations, movedFloorPlanLocations } =
      this.state;
    mapInstance?.closePopup?.();
    const { onUpdateLocation } = this.props;

    const temp = movedFloorPlanLocations.filter(
      (item) => item.locationID !== locationData.locationID
    );
    const tempFloorPlans = floorPlanLocations.filter(
      (item) => item.locationID !== locationData.locationID
    );

    tempFloorPlans.push({
      floorplanID: locationData.floorplanID,
      locationID: locationData.locationID,
      deviceID: locationData.deviceID,
      locationName: locationData.locationName,
      x: locationData.x,
      y: locationData.y,
      height: locationData.height,
      lastContact: locationData.lastContact,
      status: locationData.status,
      uuid: locationData.uuid,
    });
    onUpdateLocation(locationData);

    this.setState({
      movedFloorPlanLocations: temp,
      floorPlanLocations: tempFloorPlans,
    });
  };

  handleMarkerRender = (
    el: FloorPlanLocationListType
  ): MarkerRenderHandlerType => {
    const { movedFloorPlanLocations, selectedLoc } = this.state;
    let coordinates: Leaflet.LatLngExpression = [el.x ?? 0, el.y ?? 0];
    const popupDetails = {
      locationName: el.locationName,
    };
    let matchedLocation: FloorPlanLocationListType | undefined;
    if (movedFloorPlanLocations.length > 0) {
      matchedLocation = movedFloorPlanLocations.find(
        (elem) => elem.locationID === el.locationID
      );
    }

    if (matchedLocation) {
      popupDetails.locationName = matchedLocation.locationName;
      coordinates = [matchedLocation.x ?? 0, matchedLocation.y ?? 0];
    }

    let icon = getLeafletIcon('blue');
    if (selectedLoc === el.locationID) {
      icon = getLeafletIcon('grey');
    }
    if (matchedLocation?.locationID === el.locationID) {
      icon = getLeafletIcon('red');
    }

    return {
      icon,
      matchedLocation,
      coordinates,
      popupDetails,
    };
  };

  render() {
    const { isEditable = false, mapDetails } = this.props;

    const { movedFloorPlanLocations, floorPlanLocations } = this.state;

    return (
      <Fragment>
        <Row>
          <Col xs={24}>
            {mapDetails.image && (
              <FloorPlanMap
                mapDetails={mapDetails}
                mapContainerProps={{
                  className: `${cssStyles.floorPlanMap} leaflet-map`,
                }}
                onMapMount={this.handleMap}
                markerRender={() => (
                  <MapMarkers
                    floorPlanLocations={floorPlanLocations}
                    handleMarkerRender={this.handleMarkerRender}
                    onMoveMarker={this.onMoveMarker}
                    movedFloorPlanLocations={movedFloorPlanLocations}
                    handleDelete={this.handleDelete}
                    handleConfirm={this.handleConfirm}
                    handleReset={this.handleReset}
                    isEditable={isEditable}
                    markerPopupRender={(props) => {
                      return <PopupWithEdit {...props} />;
                    }}
                  />
                )}
              />
            )}
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default MapContent;
