import { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';

import { navbarLinks } from './helpers';
import DefaultNav from './DefaultNav';
import MobileNav from './MobileNav';

import cssStyles from './styles/navbar.module.scss';
import { authLogout } from '../../redux/actions';
import { getUserData, getUserPermissionList } from '../../redux/selectors';

const AntdNavbar = () => {
  const userData = useSelector(getUserData);
  const userPermissionList = useSelector(getUserPermissionList);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authLogout());
  };

  return (
    <Fragment>
      <Row
        gutter={[0, { xs: 12, sm: 16, md: 32 }]}
        className={`px-0 py-0 ${cssStyles.navbar_root}`}>
        <Col xs={24} className="default_nav">
          <DefaultNav
            navbarLinks={navbarLinks}
            userPermissionList={userPermissionList}
            isAuth={!!userData.token}
            userData={userData}
            handleLogout={handleLogout}
          />
        </Col>
        <Col xs={24} className="mobile_nav">
          <MobileNav
            navbarLinks={navbarLinks}
            userPermissionList={userPermissionList}
            isAuth={!!userData.token}
            userData={userData}
            handleLogout={handleLogout}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default AntdNavbar;
