import { Button, Col, Row } from 'antd';
import { Fragment } from 'react';
import Address from '../../../shared/components/Address';
import { AddressFormElementsType } from '../../../shared/components/Address/helpers';
import { InputChangedHandlerType } from '../../../type-definitions';

interface PropsType {
  formElements: AddressFormElementsType;
  onInputChanged: InputChangedHandlerType;
}

const AddressTab = ({ formElements, onInputChanged }: PropsType) => {
  return (
    <Fragment>
      <Address formElements={formElements} onInputChanged={onInputChanged} />

      <Row justify="center">
        <Col xs={20} md={8}>
          <Button htmlType="submit" type="primary" size="large" block>
            Submit
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AddressTab;
