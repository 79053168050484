import { FormEvent, Fragment, ReactNode } from 'react';
import { Select, Input, Button, Row, Col } from 'antd';

import cssStyles from './tableWrapper.module.scss';
import AntdCoverSpinner from '../AntdCoverSpinner';
import AntdCard from '../../components-shared/AntdCard';
import AntdTable, { AntdTablePropsType } from '../AntdTable';

type PropsType = {
  loading?: boolean;
  pageTitle?: string;
  inputComponents: () => ReactNode;
  tableDetails: AntdTablePropsType;
};

function TableWrapper({
  loading,
  pageTitle,
  inputComponents,
  tableDetails,
}: PropsType) {
  return (
    <Fragment>
      <Row className={`${cssStyles.root}`}>
        <Col xs={24}>
          <AntdCoverSpinner active={loading}>
            <AntdCard>
              <Row>
                <Col xs={24}>
                  <Row className="py-2 title">
                    <Col xs={24} lg={12}>
                      <h5>{pageTitle}</h5>
                    </Col>
                  </Row>
                  <Row align="middle" className="py-2 input_components">
                    {inputComponents?.()}
                  </Row>
                  <Row align="middle" className="py-2">
                    <Col xs={24}>
                      <AntdTable {...tableDetails} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </AntdCard>
          </AntdCoverSpinner>
        </Col>
      </Row>
    </Fragment>
  );
}

export default TableWrapper;

export function PartnerDropdown({
  data,
  onSelectChange,
  value,
}: {
  data: string[];
  onSelectChange: (value: string) => void;
  value: string;
}) {
  return (
    <Fragment>
      <Select
        value={value}
        placeholder="Select Partner..."
        size="large"
        defaultValue=""
        style={{ width: '100%' }}
        onChange={onSelectChange}>
        <Select.Option value="">All</Select.Option>
        {data.length > 0 &&
          data.map((item, index) => {
            return (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            );
          })}
      </Select>
    </Fragment>
  );
}

export function SearchInput({
  value,
  onInputChanged,
}: {
  value: string;
  onInputChanged?: (name: string, value: string) => void;
}) {
  function handleInputChange(event: FormEvent<HTMLInputElement>) {
    onInputChanged?.(event.currentTarget.name, event.currentTarget.value);
  }
  return (
    <Fragment>
      <Input
        name="searchInput"
        value={value}
        onChange={handleInputChange}
        size="large"
        placeholder="Seacrh..."
      />
    </Fragment>
  );
}

const paginationList = [10, 20, 50, 100];

export function PaginationDropdown({
  onSelectChange,
  classes,
}: {
  onSelectChange: (value: string) => void;
  classes?: string;
}) {
  return (
    <Fragment>
      <Select
        placeholder="Select Pagination..."
        className={cssStyles.pagination || classes}
        defaultValue={'10'}
        size="large"
        onChange={onSelectChange}>
        {paginationList &&
          paginationList.length > 0 &&
          paginationList.map((item) => {
            return (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            );
          })}
      </Select>
    </Fragment>
  );
}

export function AddButton({ onButtonClick }: { onButtonClick: () => void }) {
  return (
    <Fragment>
      <Button
        type="primary"
        size="large"
        block
        htmlType="button"
        onClick={onButtonClick}>
        ADD
      </Button>
    </Fragment>
  );
}
