import { Fragment, Component, FormEvent } from 'react';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import { v4 } from 'uuid';
import { Input } from 'antd';

import {
  ApiResultType,
  AssignableLocationsType,
  LocationListType,
  UserDataType,
} from '../../../type-definitions/api-types';
import axios from 'axios';
import { groupApi, locationApi } from '../../../api-services/api-list';
import { apiCall } from '../../../api-services/api';

import { handleNotification } from '../../../utils/notification-handler';
import {
  handleFormBody,
  handleSorting,
  handleTableSearch,
} from '../../../utils';

import AntdTable from '../../AntdTable';
import { AntdTableColumnsType } from '../../../type-definitions';
import { updateImmutably } from '../../../shared/helpers';

type PropsType = {
  groupID: string;
  userData: Partial<UserDataType>;
  allowEdit: boolean;
};

type StateType = {
  locationList: LocationListType[];
  assignableLocationList: AssignableLocationsType[];
  loading: boolean;
  locationListSearch: string;
  assignableLocationListSearch: string;
};

class LocationsTab extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      locationList: [],
      assignableLocationList: [],
      loading: true,
      locationListSearch: '',
      assignableLocationListSearch: '',
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();
  initLocationList: LocationListType[] = [];
  initAssignableLocationList: AssignableLocationsType[] = [];

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          ...data,
        };
      });
  };

  handleFetchedData = async () => {
    const { userData, groupID } = this.props;

    let stateData: Partial<StateType> = {};
    if (groupID) {
      try {
        const { url, method, contentType, params } = locationApi.getLocations({
          groupID,
        });
        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          params,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result: ApiResultType = response?.data;

        if (result?.status === 'ok') {
          if (result.data) {
            const tempData: LocationListType[] = result.data.map(
              (el: LocationListType) => ({
                ...el,
                uuid: v4(),
              })
            );
            stateData = updateImmutably(stateData, {
              locationList: { $set: [...tempData] },
            });
            this.initLocationList = updateImmutably(this.initLocationList, {
              $set: [...tempData],
            });
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error.data);
      }

      try {
        const { url, method, contentType } =
          groupApi.getGroupAssignableLocations(undefined, { groupID });

        const response = await apiCall({
          storeToken: userData.token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });

        const result: ApiResultType = response?.data;

        if (result?.status === 'ok') {
          if (result.data) {
            const tempData: AssignableLocationsType[] = result.data.map(
              (el: AssignableLocationsType) => ({
                ...el,
                uuid: v4(),
              })
            );

            stateData = updateImmutably(stateData, {
              assignableLocationList: { $set: [...tempData] },
            });
            this.initAssignableLocationList = updateImmutably(
              this.initAssignableLocationList,
              {
                $set: [...tempData],
              }
            );
          }
        } else {
          this._isMounted && handleNotification('error', result);
        }
      } catch (error: any) {
        this._isMounted && handleNotification('error', error.data);
      }
    }

    stateData = updateImmutably(stateData, { loading: { $set: false } });

    this.handleState(stateData);
  };

  handleDelete = async (locationID: string) => {
    const { userData, groupID } = this.props;
    this.handleState({ loading: true });
    try {
      const { url, method, contentType } = locationApi.deleteLocationGroup(
        undefined,
        {
          groupID,
          locationID,
        }
      );
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result: ApiResultType = response?.data;
      if (result?.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this._isMounted && this.handleFetchedData();
      } else {
        this._isMounted && handleNotification('error', result);
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error.data);
    }
  };

  handleAdd = async (locationID: string) => {
    const { userData, groupID } = this.props;
    this.handleState({ loading: true });
    try {
      const { url, method, contentType } = locationApi.postLocationGroup(
        undefined,
        {
          locationID,
        }
      );
      const response = await apiCall({
        storeToken: userData.token,
        url,
        method,
        contentType,
        data: handleFormBody({ groupid: groupID }),
        cancelToken: this.axiosCancelSource.token,
      });
      const result: ApiResultType = response?.data;
      if (result?.status === 'ok') {
        this._isMounted && handleNotification('success', result);
        this._isMounted && this.handleFetchedData();
      } else {
        this._isMounted && handleNotification('error', result);
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error.data);
    }
  };

  handleAssignableLocationSearch = (event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;

    if (value) {
      const columns = this.getAssignableLocationColumns().map((el) => el.key);
      const sortedData = handleTableSearch({
        data: [...this.initAssignableLocationList],
        columnList: columns,
        searchData: value,
      });

      this.handleState({
        assignableLocationList: sortedData,
        assignableLocationListSearch: value,
      });
    } else {
      this.handleState({
        assignableLocationList: [...this.initAssignableLocationList],
        assignableLocationListSearch: value,
      });
    }
  };

  handleLocationSearch = (event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;

    if (value) {
      const columns = this.getLocationColumns().map((el) => el.key);
      const sortedData = handleTableSearch({
        data: [...this.initLocationList],
        columnList: columns,
        searchData: value,
      });

      this.handleState({
        locationList: sortedData,
        locationListSearch: value,
      });
    } else {
      this.handleState({
        locationList: [...this.initLocationList],
        locationListSearch: value,
      });
    }
  };

  getLocationColumns = (): AntdTableColumnsType<LocationListType>[] => {
    const { allowEdit } = this.props;
    return [
      {
        title: 'Location ID',
        dataIndex: 'locationID',
        key: 'locationID',
        sorter: (a: LocationListType, b: LocationListType) =>
          handleSorting(a.locationID, b.locationID),
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Location Name',
        dataIndex: 'locationName',
        key: 'locationName',
        sorter: (a: LocationListType, b: LocationListType) =>
          handleSorting(a.locationName, b.locationName),
      },
      {
        title: 'Friendly Name',
        dataIndex: 'friendlyName',
        key: 'friendlyName',
        sorter: (a: LocationListType, b: LocationListType) =>
          handleSorting(a.friendlyName, b.friendlyName),
      },
      {
        title: 'Partner ID',
        dataIndex: 'partnerID',
        key: 'partnerID',
        sorter: (a: LocationListType, b: LocationListType) =>
          handleSorting(a.partnerID, b.partnerID),
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record: LocationListType) => (
          <Fragment>
            {allowEdit && (
              <FaTrashAlt
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => this.handleDelete(record.locationID)}
              />
            )}
          </Fragment>
        ),
      },
    ];
  };

  getAssignableLocationColumns =
    (): AntdTableColumnsType<AssignableLocationsType>[] => {
      const { allowEdit } = this.props;
      return [
        {
          title: 'Location ID',
          dataIndex: 'locationID',
          key: 'locationID',
          sorter: (a: AssignableLocationsType, b: AssignableLocationsType) =>
            handleSorting(a.locationID, b.locationID),
          defaultSortOrder: 'ascend',
        },
        {
          title: 'Location Name',
          dataIndex: 'locationName',
          key: 'locationName',
          sorter: (a: AssignableLocationsType, b: AssignableLocationsType) =>
            handleSorting(a.locationName, b.locationName),
        },
        {
          title: 'Friendly Name',
          dataIndex: 'friendlyName',
          key: 'friendlyName',
          sorter: (a: AssignableLocationsType, b: AssignableLocationsType) =>
            handleSorting(a.friendlyName, b.friendlyName),
        },
        {
          title: 'Partner ID',
          dataIndex: 'partnerID',
          key: 'partnerID',
          sorter: (a: AssignableLocationsType, b: AssignableLocationsType) =>
            handleSorting(a.partnerID, b.partnerID),
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record: AssignableLocationsType) => (
            <Fragment>
              {allowEdit && (
                <FaPlus
                  style={{ color: 'green', cursor: 'pointer' }}
                  onClick={() => this.handleAdd(record.locationID)}
                />
              )}
            </Fragment>
          ),
        },
      ];
    };

  render() {
    const {
      loading,
      locationList,
      assignableLocationList,
      locationListSearch,
      assignableLocationListSearch,
    } = this.state;

    return (
      <Fragment>
        <div className="row pt-3">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-6">
                <strong
                  style={{
                    fontSize: `1.25rem`,
                  }}>{`Current Locations`}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 offset-md-8">
                <Input
                  value={locationListSearch}
                  onChange={this.handleLocationSearch}
                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <AntdTable
                  loading={loading}
                  columns={this.getLocationColumns()}
                  dataSource={locationList}
                  pagination={{
                    pageSize: 5,
                    total: locationList.length,
                  }}
                  customConfig={{ rowKeyValue: 'uuid' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row py-5">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-6">
                <strong
                  style={{
                    fontSize: `1.25rem`,
                  }}>{`Other Locations`}</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 offset-md-8">
                <Input
                  value={assignableLocationListSearch}
                  onChange={this.handleAssignableLocationSearch}
                />
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-lg-12">
                <AntdTable
                  loading={loading}
                  columns={this.getAssignableLocationColumns()}
                  dataSource={assignableLocationList}
                  pagination={{
                    pageSize: 5,
                    total: assignableLocationList.length,
                  }}
                  customConfig={{ rowKeyValue: 'uuid' }}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LocationsTab;
