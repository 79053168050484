import {
  FloorPlanMapDetailsType,
  MapCoordinatesType,
} from '@airsensa/react-components/dist/@types';
import { Fragment } from 'react';

import {
  BuildingFloorPlanType,
  BuildingListType,
  FloorPlanLocationListType,
  LocationListType,
} from '../../../type-definitions/api-types';

// import FloorPlanMapWithPolygon from '../../../components-shared/FloorPlanMapWithPolygon';
import PolygonMap from '../PolygonMap';

interface PropsType {
  mapDetails: FloorPlanMapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocationFromModal: Partial<LocationListType>;
  selectedFloorPlan: Partial<BuildingFloorPlanType>;
  token: string;
  buildingList: BuildingListType[];
  onUpdateLocation: (data: FloorPlanLocationListType) => void;
  onRemoveLocation: (data: FloorPlanLocationListType) => void;
  setMapDefaultParameters: (coordinates: MapCoordinatesType) => void;
}

const MapContent = ({
  mapDetails,
  floorPlanLocationList,
  selectedLocationFromModal,
  onUpdateLocation,
  onRemoveLocation,
  setMapDefaultParameters,
  selectedFloorPlan,
  token,
  buildingList,
}: PropsType) => {
  return (
    <Fragment>
      {/* {mapDetails.image && selectedFloorPlan.floorplanID && (
        <FloorPlanMapWithPolygon
          mapDetails={mapDetails}
          floorPlanLocationList={floorPlanLocationList}
          newLocation={selectedLocationFromModal}
          onUpdateLocation={onUpdateLocation}
          onRemoveLocation={onRemoveLocation}
          setMapDefaultParameters={setMapDefaultParameters}
          isEditable={true}
          floorPlanId={selectedFloorPlan.floorplanID}
          token={token}
        />
      )} */}
      {mapDetails.image && selectedFloorPlan.floorplanID && (
        <PolygonMap
          mapDetails={mapDetails}
          floorPlanLocationList={floorPlanLocationList}
          newLocation={selectedLocationFromModal}
          onUpdateLocation={onUpdateLocation}
          onRemoveLocation={onRemoveLocation}
          setMapDefaultParameters={setMapDefaultParameters}
          isEditable={true}
          floorPlanID={selectedFloorPlan.floorplanID}
          token={token}
          buildingList={buildingList}
        />
      )}
    </Fragment>
  );
};

export default MapContent;
