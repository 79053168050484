export function getApiPath(param: string) {
  if (process.env.NODE_ENV === 'development') {
    // if (param === `prod`) {
    //   return `http://localhost:8080`;
    // }
    // if (param === `api`) {
    //   // return `http://localhost:8083`;
    //   return `https://${param}dev.airsensa.io`;
    // }
    return `https://${param}dev.airsensa.io`;
  }
  if (process.env.NODE_ENV === 'production') {
    return `https://${param}dev.airsensa.io`;
  }
}

export function getApiPathMaint() {
  if (process.env.NODE_ENV === 'development') {
    return `https://maint.airsensa.tech`;
  }
  if (process.env.NODE_ENV === 'production') {
    return `https://maint.airsensa.tech`;
  }
}
