import axios from 'axios';
import { Fragment, Component, FormEvent } from 'react';
import update from 'immutability-helper';
import { v4 } from 'uuid';
import { Row, Col, Button } from 'antd';

import { assetApi, floorPlanApi } from '../../api-services/api-list';
import {
  PropsType,
  StateType,
  detailsTabElements,
  FormElementsType,
} from '../components/WebViewFloorPlanDetails/helpers';
import { webViewApiCall } from '../services/api-call';
import {
  FloorPlanLocationListType,
  LocationListType,
} from '../../type-definitions/api-types';
import { handleFormBody } from '../../utils';
import { checkValidation } from '../../utils/validation';
import MapContent from '../components/WebViewFloorPlanDetails/MapContent';
import AddLocationModal from '../components/WebViewFloorPlanDetails/AddLocationModal';
import AntdCoverSpinner from '../../components/AntdCoverSpinner';

// const defaultValues = {
//   token: `testtoken`,
//   floorPlanID: `c0eiseseg3lf8ebs58rg`,
//   isEditable: true,
//   assetID: 'bshe2fus4k9fqjt3tusg',
//   width: 470,
//   height: 967,
// };

class WebViewFloorPlanDetails extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      formElements: {
        ...detailsTabElements,
      },
      loading: true,
      error: {},
      success: {},
      mapDetails: {
        width: undefined,
        height: undefined,
        image: undefined,
        defaultCoordinates: undefined,
      },
      floorPlanLocationList: [],
      selectedLocation: {},
      showLocationModal: false,
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;
    const { match } = this.props;
    const floorPlanID = match?.params?.floorPlanID;
    if (floorPlanID) {
      this.handleFetchedData();
    }
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const { location } = this.props;
    const { mapDetails } = this.state;
    if (
      mapDetails.defaultCoordinates?.x !==
      prevState.mapDetails.defaultCoordinates?.x
    ) {
      let newLocationID: string | undefined;
      let newLocationName: string | undefined;
      const search = location?.search;
      if (search) {
        const params = new URLSearchParams(search);
        const tempNewLocationID = params.get('newLocationID');
        const tempNewLocationName = params.get('newLocationName');
        if (tempNewLocationID) {
          newLocationID = tempNewLocationID;
        }
        if (tempNewLocationName) {
          newLocationName = tempNewLocationName;
        }
      }

      if (newLocationID && newLocationName) {
        const locationData: LocationListType = {
          locationID: newLocationID,
          locationName: newLocationName,
          description: '',
          friendlyName: '',
          locationMac: '',
          status: '',
          partnerID: '',
          level: 0,
          capacity: 0,
          lat: 0,
          lng: 0,
          lastContact: '',
          rights: [],
          uuid: v4(),
        };
        this.onLocationSelectModal(locationData);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleFetchedData = async () => {
    const { match, location } = this.props;
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    const floorPlanID = match?.params?.floorPlanID;
    const token = match?.params?.token;
    let isEditable = false;
    const tempRight = match?.params?.isEditable;
    if (tempRight) {
      isEditable = JSON.parse(tempRight);
    }

    const search = location?.search;
    let assetID: string | undefined;
    let mapWidth: number | undefined;
    let mapHeight: number | undefined;
    let userLat: number | undefined;
    let userLng: number | undefined;

    if (search) {
      const params = new URLSearchParams(search);
      const tempID = params.get('assetid');
      const tempWidth = params.get('mapwidth');
      const tempHeight = params.get('mapheight');
      const tempLat = params.get('lat');
      const tempLng = params.get('lng');
      if (tempID) {
        assetID = tempID;
      }
      if (tempWidth) {
        mapWidth = JSON.parse(tempWidth);
      }
      if (tempHeight) {
        mapHeight = JSON.parse(tempHeight);
      }
      if (tempLat) {
        userLat = JSON.parse(tempLat);
      }
      if (tempLng) {
        userLng = JSON.parse(tempLng);
      }
    }

    if (floorPlanID && token && assetID && mapWidth && mapHeight) {
      try {
        const floorPlanLocations = floorPlanApi.getFloorPlanLocations(
          undefined,
          {
            floorPlanID,
          }
        );

        const asset = assetApi.getAsset(undefined, { assetID });

        const floorPlanLocationsResponse = await webViewApiCall({
          token,
          url: floorPlanLocations.url,
          method: floorPlanLocations.method,
          contentType: floorPlanLocations.contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const floorPlanLocationsResult = floorPlanLocationsResponse?.data;
        const assetResponse = await webViewApiCall({
          token,
          url: asset.url,
          method: asset.method,
          contentType: asset.contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const assetResult = assetResponse?.data;
        let apiResponseContentType = '';
        if (assetResponse?.headers) {
          apiResponseContentType = assetResponse.headers?.['content-type'];
        }
        const stateData = {} as StateType;

        let tempFloorPlanLocationList: FloorPlanLocationListType[] = [];

        if (
          floorPlanLocationsResult?.status !== 'error' &&
          floorPlanLocationsResult.data &&
          floorPlanLocationsResult.data.length > 0
        ) {
          tempFloorPlanLocationList = floorPlanLocationsResult.data.map(
            (item: FloorPlanLocationListType) => ({
              ...item,
              coordinates: { x: item.x, y: item.y },
              uuid: v4(),
            })
          );
        } else {
          stateData.error = floorPlanLocationsResult || {};
        }

        const mapDetails = {
          width: mapWidth,
          height: mapHeight,
          image:
            apiResponseContentType === 'image/x-icon' ? '' : assetResult || '',
        };

        this._isMounted &&
          this.setState((prevState) => {
            return {
              floorPlanLocationList: tempFloorPlanLocationList,
              mapDetails: {
                ...prevState.mapDetails,
                width: mapDetails.width,
                height: mapDetails.height,
                image: mapDetails.image,
              },
              loading: false,
              formElements: tempFormElements,
              error: stateData.error || {},
              assetID,
              token,
              floorPlanID,
              isEditable: isEditable,
              userLat,
              userLng,
            };
          });
      } catch (error: any) {
        this._isMounted && this.setState({ loading: false, error });
      }
    }
  };

  // Locations Tab
  onUpdateLocation = async (locationData: FloorPlanLocationListType) => {
    const { floorPlanLocationList, floorPlanID, token } = this.state;

    if (floorPlanID && token) {
      try {
        const data = {
          x: locationData.x,
          y: locationData.y,
        };
        const formData = handleFormBody(data);
        const { url, method, contentType } = floorPlanApi.putFloorPlanLocation(
          undefined,
          {
            floorPlanID,
            locationID: locationData.locationID,
          }
        );
        const response = await webViewApiCall({
          token,
          url,
          method,
          contentType,
          data: formData,
          cancelToken: this.axiosCancelSource.token,
        });
        const result = response?.data;
        if (result.status === 'ok') {
          let tempFloorPlanLocationList = [...floorPlanLocationList];

          tempFloorPlanLocationList = tempFloorPlanLocationList.filter(
            (item) => {
              return item.locationID !== locationData.locationID;
            }
          );

          tempFloorPlanLocationList.push({
            floorplanID: floorPlanID,
            locationID: locationData.locationID,
            deviceID: locationData.deviceID,
            locationName: locationData.locationName,
            x: locationData.x,
            y: locationData.y,
            height: locationData.height,
            lastContact: locationData.lastContact,
            status: locationData.status,
            uuid: locationData.uuid,
          });

          this._isMounted &&
            this.setState({
              success: result,
              floorPlanLocationList: tempFloorPlanLocationList,
              selectedLocation: {},
            });
        } else {
          this._isMounted && this.setState({ error: result || {} });
        }
      } catch (error: any) {
        this._isMounted && this.setState({ error });
      }
    }
  };

  onRemoveLocation = async (locationData: FloorPlanLocationListType) => {
    const { floorPlanLocationList, floorPlanID, token } = this.state;

    if (floorPlanID && token) {
      try {
        const { url, method, contentType } =
          floorPlanApi.deleteFloorPlanLocation(undefined, {
            floorPlanID,
            locationID: locationData.locationID,
          });
        const response = await webViewApiCall({
          token,
          url,
          method,
          contentType,
          cancelToken: this.axiosCancelSource.token,
        });
        const result = response?.data;
        if (result?.status === 'ok') {
          let tempFloorPlanLocationList = [...floorPlanLocationList];
          tempFloorPlanLocationList = tempFloorPlanLocationList.filter(
            (item) => {
              return item.locationID !== locationData.locationID;
            }
          );

          this._isMounted &&
            this.setState({
              success: result,
              floorPlanLocationList: tempFloorPlanLocationList,
              selectedLocation: {},
            });
        } else {
          this._isMounted && this.setState({ error: result || {} });
        }
      } catch (error: any) {
        this._isMounted && this.setState({ error });
      }
    }
  };

  handleAddLocationModal = () => {
    this._isMounted &&
      this.setState((prevState) => ({
        ...prevState,
        showLocationModal: !prevState.showLocationModal,
      }));
  };

  setMapDefaultParameters = (coordinates: { x: number; y: number }) => {
    // console.log('default coordinates', coordinates);
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          mapDetails: {
            ...prevState.mapDetails,
            defaultCoordinates: coordinates,
          },
        };
      });
  };

  onLocationSelectModal = (locationData: LocationListType) => {
    const { floorPlanLocationList, mapDetails, floorPlanID } = this.state;

    let tempFloorPlanLocationList = [...floorPlanLocationList];

    tempFloorPlanLocationList = tempFloorPlanLocationList.filter((item) => {
      return item.locationID !== locationData.locationID;
    });

    tempFloorPlanLocationList.push({
      floorplanID: floorPlanID ?? '',
      locationID: locationData.locationID,
      deviceID: '',
      locationName: locationData.locationName,
      x: mapDetails.defaultCoordinates?.x ?? 0,
      y: mapDetails.defaultCoordinates?.y ?? 0,
      height: 1,
      lastContact: '',
      status: '',
      uuid: locationData.uuid,
    });

    this._isMounted &&
      this.setState({
        selectedLocation: locationData,
        showLocationModal: false,
        floorPlanLocationList: tempFloorPlanLocationList,
      });
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    if (name) {
      if (
        (name === 'topLeftLat' && !checkValidation(value, { isFloat: true })) ||
        (name === 'topLeftLng' && !checkValidation(value, { isFloat: true })) ||
        (name === 'bottomRightLat' &&
          !checkValidation(value, { isFloat: true })) ||
        (name === 'bottomRightLng' &&
          !checkValidation(value, { isFloat: true }))
      ) {
        // message.error('Please enter valid input');
        return;
      }

      tempFormElements = update(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });

      this._isMounted && this.setState({ formElements: tempFormElements });
    }
  };

  onBoundsMarkerMove = (
    lat: number,
    lng: number,
    name: 'topLeft' | 'bottomRight'
  ) => {
    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (name === 'topLeft') {
      tempFormElements = update(tempFormElements, {
        topLeftLat: {
          value: { $set: lat.toString() },
        },
        topLeftLng: {
          value: { $set: lng.toString() },
        },
      });
    }
    if (name === 'bottomRight') {
      tempFormElements = update(tempFormElements, {
        bottomRightLat: {
          value: { $set: lat.toString() },
        },
        bottomRightLng: {
          value: { $set: lng.toString() },
        },
      });
    }
    this._isMounted && this.setState({ formElements: tempFormElements });
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { formElements, token, floorPlanID } = this.state;

    let formElemKey: keyof typeof formElements;

    let tempFormElements = { ...formElements };

    for (formElemKey in tempFormElements) {
      tempFormElements = update(tempFormElements, {
        [formElemKey]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[formElemKey].value,
              tempFormElements[formElemKey].validation
            ),
          },
        },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });

    for (formElemKey in tempFormElements) {
      if (!tempFormElements[formElemKey].valid) {
        // message.error('Please fill all the fields');
        return;
      }
    }

    this._isMounted && this.setState({ loading: true });

    if (token && floorPlanID) {
      (async () => {
        try {
          const { url, method, contentType } = floorPlanApi.putFloorPlan(
            undefined,
            { floorPlanID }
          );
          const response = await webViewApiCall({
            token,
            url,
            method,
            contentType,
            data: {
              name: tempFormElements.floorPlanName.value,
              description: tempFormElements.description.value,
              partnerID: tempFormElements.partnerID.value,
              bounds: {
                topLeftLat: parseFloat(tempFormElements.topLeftLat.value),
                topLeftLng: parseFloat(tempFormElements.topLeftLng.value),
                bottomRightLat: parseFloat(
                  tempFormElements.bottomRightLat.value
                ),
                bottomRightLng: parseFloat(
                  tempFormElements.bottomRightLng.value
                ),
              },
            },
            cancelToken: this.axiosCancelSource.token,
          });
          const result = response?.data;
          if (result?.status !== 'error') {
            this._isMounted &&
              this.setState({ loading: false, success: result || {} });
          } else {
            this._isMounted &&
              this.setState({ loading: false, error: result || {} });
          }
        } catch (error: any) {
          this._isMounted && this.setState({ loading: false, error });
        }
      })();
    }
  };

  render() {
    const {
      loading,
      showLocationModal,
      floorPlanLocationList,
      token,
      userLat,
      userLng,
      isEditable,
      mapDetails,
      selectedLocation,
    } = this.state;

    const { match } = this.props;

    const floorPlanID = match?.params?.floorPlanID;
    if (!floorPlanID) {
      return <Fragment></Fragment>;
    }

    return (
      <Fragment>
        <AntdCoverSpinner active={loading} size="large">
          <Row justify="center" align="middle" style={{ height: '100vh' }}>
            <Col xs={22}>
              <Row justify="center" className="pb-3">
                <Col sm={12} lg={16} className="text-center">
                  <Button
                    disabled={!isEditable}
                    onClick={this.handleAddLocationModal}
                    type="primary"
                    size="middle">
                    Add Location
                  </Button>
                </Col>
              </Row>

              <MapContent
                mapDetails={mapDetails}
                floorPlanLocationList={floorPlanLocationList}
                selectedLocation={selectedLocation}
                isEditable={isEditable}
                onUpdateLocation={this.onUpdateLocation}
                onRemoveLocation={this.onRemoveLocation}
                setMapDefaultParameters={this.setMapDefaultParameters}
              />
            </Col>
          </Row>
        </AntdCoverSpinner>
        {showLocationModal && token && (
          <AddLocationModal
            token={token}
            showLocationModal={showLocationModal}
            handleAddLocationModal={this.handleAddLocationModal}
            onLocationSelectModal={this.onLocationSelectModal}
            floorPlanLat={userLat || 0}
            floorPlanLng={userLng || 0}
            floorPlanLocationList={floorPlanLocationList}
          />
        )}
      </Fragment>
    );
  }
}

export default WebViewFloorPlanDetails;
