import { Component, FormEvent, Fragment } from 'react';
import axios from 'axios';
import { connect, ConnectedProps } from 'react-redux';
import { message, Tabs } from 'antd';

import {
  FormElementsType,
  initialElements,
  StateType,
} from '../components/FloorPlanAdd/helpers';
import { Redirect } from 'react-router-dom';
import {
  AxiosHttpAllSettledResponsesType,
  BuildingListType,
  FloorPlanLocationListType,
  LocationListType,
  PartnerListType,
} from '../type-definitions/api-types';
import {
  allSettledErrorHandling,
  apiCall,
  httpCallAllSettled,
} from '../api-services/api';
import {
  assetApi,
  buildingApi,
  floorPlanApi,
  partnerApi,
} from '../api-services/api-list';
import { updateToken } from '../redux/actions';
import { handleFormBody } from '../utils';
import { floorPlanRights } from '../utils/permission-list';
import { PageDefaultPropsType, SelectOptionsType } from '../type-definitions';
import { checkValidation } from '../utils/validation';

import PromptPopup from '../components/PromptPopup';
import FormWrapper from '../components/FormWrapper';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { floorPlanRoutes } from '../Routes/routes-list';
import { v4 } from 'uuid';

import DetailsTab from '../components/FloorPlanAdd/DetailsTab';
import LocationsTab from '../components/FloorPlanAdd/LocationsTab';
import AddLocationModal from '../components/FloorPlanAdd/AddLocationModal';
import { handleNotification } from '../utils/notification-handler';
import { updateImmutably } from '../shared/helpers';

interface PropsType extends PageDefaultPropsType, PropsFromRedux {}

class FloorPlanAdd extends Component<Readonly<PropsType>, Readonly<StateType>> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      formElements: { ...initialElements },
      loading: true,
      mapDetails: {
        width: undefined,
        height: undefined,
        image: undefined,
        defaultCoordinates: undefined,
      },
      floorPlanLocationList: [],
      selectedLocation: {},
      showLocationModal: false,
      currentTabIndex: '1',
      hasInputChanged: false,
      partnerList: [],
      fileList: [],
      createdFloorPlanID: '',
    };
  }

  _isMounted = false;
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    this._isMounted = true;

    this.handleFetchedData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.axiosCancelSource.cancel('Component Unmounted');
  }

  handleState = (data: Partial<StateType>, callback?: () => void) => {
    this._isMounted &&
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            ...data,
          };
        },
        () => {
          callback?.();
        }
      );
  };

  handleFetchedData = async () => {
    const { userData, updateToken } = this.props;
    const { formElements } = this.state;
    const getPartners = partnerApi.getPartners();
    const getBuildings = buildingApi.getBuildings();

    const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
      let tempFormElements = { ...formElements };
      const stateData: any = {
        loading: false,
      };
      if (responses?.[0].status === 'fulfilled') {
        const result = responses[0].value?.data;
        result && updateToken(result);

        let tempPartnerData: PartnerListType[] = result?.data ?? [];
        if (tempPartnerData.length > 0) {
          tempPartnerData = tempPartnerData.filter((el) =>
            el.rights.includes(floorPlanRights.create)
          );
          tempPartnerData = tempPartnerData.map((item) => ({
            ...item,
            uuid: v4(),
          }));
          tempFormElements = this.handleFormElements(tempPartnerData);
          stateData.partnerList = tempPartnerData;
          stateData.formElements = tempFormElements;
        }
      } else {
        allSettledErrorHandling(responses[0]);
      }

      if (responses?.[1].status === 'fulfilled') {
        const buildingList: BuildingListType[] =
          responses[1].value.data?.data ?? [];

        if (buildingList.length > 0) {
          const optionValues = buildingList.map((el) => ({
            value: el.buildingID,
            text: el.name,
          }));

          tempFormElements = updateImmutably(tempFormElements, {
            buildingID: {
              optionValues: {
                $set: optionValues,
              },
              value: {
                $set: optionValues[0].value,
              },
            },
          });

          stateData.formElements = tempFormElements;
        }
      } else {
        allSettledErrorHandling(responses[1]);
      }
      this.setState({ ...stateData });
    };

    httpCallAllSettled({
      requestConfig: [{ ...getPartners }, { ...getBuildings }],
      headersConfig: { token: userData.token },
      applyData: handleResponses,
    });
  };

  handleFormElements = (
    partners: PartnerListType[],
    elements?: FormElementsType
  ) => {
    const partnerListOptions: SelectOptionsType[] = partners.map((item) => ({
      value: item.partnerID,
      text: item.partnerID,
    }));

    const { formElements } = this.state;

    let tempFormElements = { ...formElements };

    if (elements) {
      tempFormElements = { ...elements };
    }

    const selected = partners.find((item) => item?.partnerID === 'AIRSENSA');

    if (tempFormElements) {
      tempFormElements = updateImmutably(tempFormElements, {
        partnerID: { optionValues: { $set: partnerListOptions } },
      });
      if (selected) {
        tempFormElements = updateImmutably(tempFormElements, {
          partnerID: { value: { $set: selected.partnerID } },
        });
      } else {
        tempFormElements = updateImmutably(tempFormElements, {
          partnerID: { value: { $set: partnerListOptions[0].value } },
        });
      }
    }
    return tempFormElements;
  };

  inputChangedHandler = (name: keyof FormElementsType, value: string) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };
    if (name) {
      if (
        value &&
        (name === 'topLeftLat' ||
          name === 'topLeftLng' ||
          name === 'bottomRightLat' ||
          name === 'bottomRightLng') &&
        !checkValidation(value, { isNegativeFloat: true })
      ) {
        message.error('Please enter valid input');
        return;
      }

      if (
        value &&
        name === 'level' &&
        !checkValidation(value, { isNumeric: true })
      ) {
        message.error('Please enter valid input');
        return;
      }

      tempFormElements = updateImmutably(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });

      this._isMounted &&
        this.setState({
          formElements: tempFormElements,
          hasInputChanged: true,
        });
    }
  };

  onBoundsMarkerMove = (
    lat: number,
    lng: number,
    name: 'topLeft' | 'bottomRight'
  ) => {
    const { formElements } = this.state;
    let tempFormElements = { ...formElements };

    if (name === 'topLeft') {
      tempFormElements = updateImmutably(tempFormElements, {
        topLeftLat: {
          value: { $set: lat.toString() },
        },
        topLeftLng: {
          value: { $set: lng.toString() },
        },
      });
    }
    if (name === 'bottomRight') {
      tempFormElements = updateImmutably(tempFormElements, {
        bottomRightLat: {
          value: { $set: lat.toString() },
        },
        bottomRightLng: {
          value: { $set: lng.toString() },
        },
      });
    }
    this._isMounted && this.setState({ formElements: tempFormElements });
  };

  onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.handleSubmit();
  };

  handleSubmit = (onConfirm?: () => void) => {
    const { formElements, loading, fileList, partnerList, mapDetails } =
      this.state;
    const { userData, updateToken } = this.props;
    let tempFormElements = { ...formElements };
    let formElemKey: keyof typeof tempFormElements;

    for (formElemKey in tempFormElements) {
      tempFormElements = updateImmutably(tempFormElements, {
        [formElemKey]: {
          touched: { $set: true },
          valid: {
            $set: checkValidation(
              tempFormElements[formElemKey].value,
              tempFormElements[formElemKey].validation
            ),
          },
        },
      });
    }

    this._isMounted && this.setState({ formElements: tempFormElements });

    for (formElemKey in tempFormElements) {
      if (!tempFormElements[formElemKey].valid) {
        message.error('Please fill all the fields');
        return;
      }
    }

    if (fileList.length === 0) {
      message.error('Please upload a file!');
      return;
    }

    !loading && this._isMounted && this.setState({ loading: true });

    (async () => {
      let stateData: Partial<StateType> = { mapDetails: mapDetails || {} };

      let assetID: string = '';

      if (fileList && fileList.length > 0) {
        const { url, method, contentType } = assetApi.postAsset();
        const multipartFormData = new FormData();
        fileList.forEach((file) => {
          multipartFormData.append('asset', file);
        });
        try {
          const response = await apiCall({
            storeToken: userData.token,
            url,
            method,
            contentType,
            data: multipartFormData,
            cancelToken: this.axiosCancelSource.token,
          });
          const result = response?.data;
          updateToken(result);
          assetID = result?.data?.assetID;
        } catch (error: any) {
          this._isMounted && handleNotification('error', error?.data);
        }
      }

      if (assetID) {
        const data = {
          name: tempFormElements.floorPlanName.value,
          description: tempFormElements.description.value,
          partnerID: tempFormElements.partnerID.value,
          assetID,
          bounds: {
            topLeftLat: parseFloat(tempFormElements.topLeftLat.value),
            topLeftLng: parseFloat(tempFormElements.topLeftLng.value),
            bottomRightLat: parseFloat(tempFormElements.bottomRightLat.value),
            bottomRightLng: parseFloat(tempFormElements.bottomRightLng.value),
          },
          buildingID: tempFormElements.buildingID.value,
          level: Number(tempFormElements.level.value),
        };

        try {
          const { url, method, contentType } = floorPlanApi.postFloorPlan();
          const response2 = await apiCall({
            storeToken: userData?.token,
            url,
            method,
            contentType,
            data,
            cancelToken: this.axiosCancelSource.token,
          });
          const result2 = response2?.data;
          updateToken(result2);
          if (result2?.status === 'ok') {
            // onConfirm?.();
            stateData = updateImmutably(stateData, {
              hasInputChanged: { $set: false },
            });

            const { url, method, contentType } = assetApi.getAsset(undefined, {
              assetID,
            });

            const imageResponse = await apiCall({
              storeToken: userData?.token,
              url,
              method,
              contentType,
              cancelToken: this.axiosCancelSource.token,
            });
            const imageResult = imageResponse?.data;
            this._isMounted && handleNotification('success', result2);

            stateData = updateImmutably(stateData, {
              formElements: {
                $set: this.handleFormElements(partnerList, {
                  ...initialElements,
                }),
              },
              fileList: { $set: [] },
            });

            if (result2?.data?.floorplanID) {
              stateData = updateImmutably(stateData, {
                createdFloorPlanID: { $set: result2?.data?.floorplanID },
              });
            }

            if (result2?.data?.imageHeight) {
              stateData = updateImmutably(stateData, {
                mapDetails: { height: { $set: result2?.data?.imageHeight } },
              });
            }

            if (result2?.data?.imageWidth) {
              stateData = updateImmutably(stateData, {
                mapDetails: { width: { $set: result2?.data?.imageWidth } },
              });
            }

            if (imageResult) {
              stateData = updateImmutably(stateData, {
                currentTabIndex: { $set: '2' },
                mapDetails: { image: { $set: imageResult } },
              });
            }
          } else {
            this._isMounted && handleNotification('error', result2);
          }
        } catch (error: any) {
          this._isMounted && handleNotification('error', error?.data);
        }
      }

      this.handleState({
        ...stateData,
        formElements: tempFormElements,
        loading: false,
      });
    })();
  };

  handlePopupConfirm = (onConfirm: () => void) => {
    this.handleSubmit(onConfirm);
  };

  // File Upload
  onFileRemove = (file: UploadFile<any>) => {
    const { fileList } = this.state;
    const index = fileList.indexOf(file);
    const newFileList = fileList.filter((el, idx) => index !== idx);
    // newFileList.splice(index, 1);
    this.setState({ fileList: newFileList });
  };

  beforeFileUpload = (file: RcFile, fileList: RcFile[]) => {
    this.setState({ fileList });
    return false;
  };

  // Locations Tab
  onUpdateLocation = async (locationData: FloorPlanLocationListType) => {
    const { userData } = this.props;
    const { floorPlanLocationList, createdFloorPlanID } = this.state;

    try {
      const data = {
        x: locationData.x,
        y: locationData.y,
      };
      const formData = handleFormBody(data);
      const { url, method, contentType } = floorPlanApi.putFloorPlanLocation(
        undefined,
        {
          floorPlanID: createdFloorPlanID,
          locationID: locationData.locationID,
        }
      );
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        data: formData,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result.status === 'ok') {
        let tempFloorPlanLocationList = [...floorPlanLocationList];

        tempFloorPlanLocationList = tempFloorPlanLocationList.filter((item) => {
          return item.locationID !== locationData.locationID;
        });

        tempFloorPlanLocationList.push({
          floorplanID: createdFloorPlanID,
          locationID: locationData.locationID,
          deviceID: locationData.deviceID,
          locationName: locationData.locationName,
          x: locationData.x,
          y: locationData.y,
          height: locationData.height,
          lastContact: locationData.lastContact,
          status: locationData.status,
          uuid: locationData.uuid,
        });
        this._isMounted && handleNotification('success', result);
        this._isMounted &&
          this.setState({
            floorPlanLocationList: tempFloorPlanLocationList,
            selectedLocation: {},
          });
      } else {
        this._isMounted && handleNotification('error', result);
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
    }
  };

  onRemoveLocation = async (locationData: FloorPlanLocationListType) => {
    const { userData } = this.props;
    const { floorPlanLocationList, createdFloorPlanID } = this.state;

    try {
      const { url, method, contentType } = floorPlanApi.deleteFloorPlanLocation(
        undefined,
        {
          floorPlanID: createdFloorPlanID,
          locationID: locationData.locationID,
        }
      );
      const response = await apiCall({
        storeToken: userData?.token,
        url,
        method,
        contentType,
        cancelToken: this.axiosCancelSource.token,
      });
      const result = response?.data;
      if (result?.status === 'ok') {
        let tempFloorPlanLocationList = [...floorPlanLocationList];
        tempFloorPlanLocationList = tempFloorPlanLocationList.filter((item) => {
          return item.locationID !== locationData.locationID;
        });
        this._isMounted && handleNotification('success', result);
        this._isMounted &&
          this.setState({
            floorPlanLocationList: tempFloorPlanLocationList,
            selectedLocation: {},
          });
      } else {
        this._isMounted && handleNotification('error', result);
      }
    } catch (error: any) {
      this._isMounted && handleNotification('error', error?.data);
    }
  };

  handleAddLocationModal = () => {
    this._isMounted &&
      this.setState((prevState) => ({
        ...prevState,
        showLocationModal: !prevState.showLocationModal,
      }));
  };

  onLocationSelectModal = (locationData: LocationListType) => {
    const { floorPlanLocationList, mapDetails, createdFloorPlanID } =
      this.state;

    let tempFloorPlanLocationList = [...floorPlanLocationList];

    tempFloorPlanLocationList = tempFloorPlanLocationList.filter((item) => {
      return item.locationID !== locationData.locationID;
    });

    tempFloorPlanLocationList.push({
      floorplanID: createdFloorPlanID,
      locationID: locationData.locationID,
      deviceID: '',
      locationName: locationData.locationName,
      x: mapDetails.defaultCoordinates?.x || 0,
      y: mapDetails.defaultCoordinates?.x || 0,
      height: 1,
      lastContact: '',
      status: '',
      uuid: locationData.uuid,
    });
    this._isMounted &&
      this.setState({
        selectedLocation: locationData,
        showLocationModal: false,
        floorPlanLocationList: tempFloorPlanLocationList,
      });
  };

  getTabData = () => {
    const {
      formElements,
      mapDetails,
      selectedLocation,
      fileList,
      floorPlanLocationList,
    } = this.state;
    const { userData } = this.props;

    const detailsTabElem = (
      <DetailsTab
        formElements={formElements}
        inputChangedHandler={this.inputChangedHandler}
        handleCancelRedirect={this.handleCancelRedirect}
        fileList={fileList}
        beforeFileUpload={this.beforeFileUpload}
        onFileRemove={this.onFileRemove}
        userData={userData}
        onBoundsMarkerMove={this.onBoundsMarkerMove}
      />
    );

    const locationsTabElem = (
      <LocationsTab
        mapDetails={mapDetails}
        floorPlanLocationList={floorPlanLocationList}
        selectedLocation={selectedLocation}
        onUpdateLocation={this.onUpdateLocation}
        onRemoveLocation={this.onRemoveLocation}
        handleAddLocationModal={this.handleAddLocationModal}
        setMapDefaultParameters={this.setMapDefaultParameters}
      />
    );

    return { detailsTabElem, locationsTabElem };
  };

  setMapDefaultParameters = (coordinates: { x: number; y: number }) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          ...prevState,
          mapDetails: {
            ...prevState.mapDetails,
            defaultCoordinates: coordinates,
          },
        };
      });
  };

  setCurrentTabIndex = (index: string) => {
    this._isMounted && this.setState({ currentTabIndex: index });
  };

  handleCancelRedirect = () => {
    const { history } = this.props;
    history.push(floorPlanRoutes.list);
  };

  render() {
    const {
      hasInputChanged,
      loading,
      showLocationModal,
      mapDetails,
      currentTabIndex,
      floorPlanLocationList,
    } = this.state;

    const { userPermissionList, userData } = this.props;

    if (userPermissionList.length === 0) {
      return <Redirect to="/" />;
    }

    const { detailsTabElem, locationsTabElem } = this.getTabData();

    return (
      <Fragment>
        {hasInputChanged && (
          <PromptPopup
            when={hasInputChanged}
            handleConfirm={this.handlePopupConfirm}
          />
        )}

        <FormWrapper loading={loading} title="FloorPlan Add">
          <form onSubmit={this.onFormSubmit} noValidate>
            <Tabs
              activeKey={currentTabIndex}
              onTabClick={this.setCurrentTabIndex}>
              <Tabs.TabPane key="1" tab="Details">
                {detailsTabElem}
              </Tabs.TabPane>

              <Tabs.TabPane
                key="2"
                tab="Locations"
                disabled={!mapDetails.image}>
                {locationsTabElem}
              </Tabs.TabPane>
            </Tabs>
          </form>
        </FormWrapper>

        {showLocationModal && (
          <AddLocationModal
            userData={userData}
            showLocationModal={showLocationModal}
            handleAddLocationModal={this.handleAddLocationModal}
            onLocationSelectModal={this.onLocationSelectModal}
            floorPlanLocationList={floorPlanLocationList}
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  updateToken: updateToken,
};
const connector = connect(null, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FloorPlanAdd);
