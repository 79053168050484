import { Component, Fragment } from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  ImageOverlay,
  FeatureGroup,
} from 'react-leaflet';
import * as Leaflet from 'leaflet';
import { Button, Col, message, Row } from 'antd';
import { EditControl } from 'react-leaflet-draw';
import intersect from '@turf/intersect';
import booleanContains from '@turf/boolean-contains';
import booleanWithin from '@turf/boolean-within';
import {
  FloorPlanMapDetailsType,
  MapBoundsType,
  MapCoordinatesType,
} from '@airsensa/react-components/dist/@types';
import {
  getLeafletIcon,
  getMapBoundsAndCenter,
} from '@airsensa/react-components';

import {
  AxiosHttpAllSettledResponsesType,
  BuildingListType,
  FloorPlanLocationListType,
  LocationListType,
} from '../../../type-definitions/api-types';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import cssStyles from './polygonMap.module.scss';
import { GeoJSONFeaturesType, GeoJSONType } from '../../../type-definitions';
import {
  customCRSSimple,
  cloneDeep,
  updateImmutably,
  handleNotification,
} from '../../../shared/helpers';
import {
  allSettledErrorHandling,
  httpCallAllSettled,
} from '../../../api-services/api';
import { v4 } from 'uuid';
import { floorPlanApi } from '../../../api-services/api-list';

interface PropsType {
  mapDetails: FloorPlanMapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  newLocation: Partial<LocationListType>;
  classes?: string;
  onUpdateLocation: (data: FloorPlanLocationListType) => void;
  onRemoveLocation: (data: FloorPlanLocationListType) => void;
  setMapDefaultParameters: (coordinates: MapCoordinatesType) => void;
  isEditable: boolean;
  floorPlanID: string;
  token: string;
  buildingList: BuildingListType[];
}

interface StateType {
  mapBounds?: MapBoundsType;
  mapCenter: MapCoordinatesType;
  floorPlanLocations: FloorPlanLocationListType[];
  movedFloorPlanLocations: FloorPlanLocationListType[];
  mapInstance?: Leaflet.Map;
}

class PolygonMap extends Component<PropsType, StateType> {
  _editableFG: any;
  _outerPolygon: GeoJSONType = { type: 'FeatureCollection' };
  _innerPolygon: GeoJSONType = { type: 'FeatureCollection' };
  _floorPlanLocations: FloorPlanLocationListType[] = [];
  _region: { _outerPolygon: GeoJSONType; _innerPolygon: GeoJSONType } = {
    _outerPolygon: { type: 'FeatureCollection' },
    _innerPolygon: { type: 'FeatureCollection' },
  };

  constructor(props: PropsType) {
    super(props);

    const { mapDetails } = props;

    let bounds: MapBoundsType | undefined;
    let center: MapCoordinatesType | undefined;

    if (mapDetails.height && mapDetails.width) {
      const result = getMapBoundsAndCenter({
        height: Number(mapDetails.height),
        width: Number(mapDetails.width),
      });

      bounds = result.bounds;
      center = result.centre;
    }

    this.state = {
      mapBounds: bounds,
      mapCenter: center ?? [0, 0],
      floorPlanLocations: [],
      movedFloorPlanLocations: [],
      mapInstance: undefined,
    };
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const { floorPlanLocationList, buildingList, floorPlanID } = this.props;
    const { floorPlanLocations, mapInstance } = this.state;

    if (
      floorPlanLocationList.length !== floorPlanLocations.length &&
      mapInstance
    ) {
      this.handleMap(mapInstance);
    }

    if (
      buildingList.length > 0 &&
      !this._outerPolygon.features &&
      !this._innerPolygon.features
    ) {
      const matched = buildingList
        .find((el) =>
          el.floorplans?.find((elem) => elem.floorplanID === floorPlanID)
        )
        ?.floorplans.find((elem) => elem.floorplanID === floorPlanID);

      if (matched && this._editableFG) {
        if (matched.regions.innerPolygons.features) {
          this._innerPolygon = matched.regions.innerPolygons;
        }
        if (matched.regions.outerPolygon.features) {
          this._outerPolygon = matched.regions.outerPolygon;
        }

        this._addPolygonLayers();
      }
    }
  }

  handleMap = (map: Leaflet.Map) => {
    const { mapDetails, floorPlanLocationList, setMapDefaultParameters } =
      this.props;
    const { mapBounds, mapCenter } = this.state;

    if (mapDetails.height && mapDetails.width && map) {
      mapBounds && map.fitBounds(mapBounds);

      this._floorPlanLocations = [...floorPlanLocationList];

      this.setState(
        {
          floorPlanLocations: [...floorPlanLocationList],
          mapInstance: map,
        },
        () => {
          if (mapCenter) {
            setMapDefaultParameters?.(mapCenter);
          }
        }
      );
    }
  };

  handleReset = (location: Partial<FloorPlanLocationListType>) => {
    const { movedFloorPlanLocations, mapInstance } = this.state;
    const temp = movedFloorPlanLocations.filter(
      (item) => item.locationID !== location.locationID
    );
    mapInstance?.closePopup?.();
    this.setState({
      movedFloorPlanLocations: temp,
    });
  };

  handleDelete = (location: FloorPlanLocationListType) => {
    const { floorPlanLocations, mapInstance } = this.state;
    const { onRemoveLocation } = this.props;

    const temp = floorPlanLocations.filter(
      (item) => item.locationID !== location.locationID
    );

    mapInstance?.closePopup?.();
    onRemoveLocation(location);
    this.setState({
      floorPlanLocations: temp,
    });
  };

  handleConfirm = (locationData: FloorPlanLocationListType) => {
    const { mapInstance, floorPlanLocations, movedFloorPlanLocations } =
      this.state;
    mapInstance?.closePopup?.();
    const { onUpdateLocation } = this.props;

    const temp = movedFloorPlanLocations.filter(
      (item) => item.locationID !== locationData.locationID
    );
    const tempFloorPlans = floorPlanLocations.filter(
      (item) => item.locationID !== locationData.locationID
    );

    tempFloorPlans.push({
      floorplanID: locationData.floorplanID,
      locationID: locationData.locationID,
      deviceID: locationData.deviceID,
      locationName: locationData.locationName,
      x: locationData.x,
      y: locationData.y,
      height: locationData.height,
      lastContact: locationData.lastContact,
      status: locationData.status,
      uuid: locationData.uuid,
      region: locationData.region,
    });
    onUpdateLocation(locationData);
    this._floorPlanLocations = cloneDeep(tempFloorPlans);

    this.setState({
      movedFloorPlanLocations: temp,
      floorPlanLocations: tempFloorPlans,
    });
  };

  onMoveMarker = (
    event: Leaflet.DragEndEvent,
    location: FloorPlanLocationListType
  ) => {
    const { movedFloorPlanLocations } = this.state;
    const coordinates = event?.target?.getLatLng?.();

    let temp: FloorPlanLocationListType[] = [];
    if (movedFloorPlanLocations.length > 0 && coordinates?.lat) {
      temp = cloneDeep(movedFloorPlanLocations);
      const matched = temp.find((el) => el.locationID === location?.locationID);
      if (matched) {
        temp = temp.map((item) => {
          if (location.locationID === item.locationID) {
            item.y = coordinates.lat;
            item.x = coordinates.lng;
          }
          return item;
        });
      } else {
        temp.push({ ...location, y: coordinates.lat, x: coordinates.lng });
      }
    } else {
      temp.push({ ...location, y: coordinates.lat, x: coordinates.lng });
    }

    this.setState({ movedFloorPlanLocations: temp });
  };

  getPolygonOptions = (isPolygonEditable: boolean) => {
    return {
      edit: {
        edit: isPolygonEditable
          ? {
              allowIntersection: false,
              shapeOptions: {
                // color: '#4390FE',
                // fillColor: '#D6E7FF',
                color: '#589EFF',
                fillColor: '#589EFF',
              },
            }
          : false,
        remove: isPolygonEditable,
        poly: isPolygonEditable,
        allowIntersection: false,
      },
      draw: {
        polyline: false,
        rectangle: false,
        circle: false,
        marker: false,
        circlemarker: false,
        polygon: isPolygonEditable
          ? {
              allowIntersection: false,
              shapeOptions: {
                // color: '#4390FE',
                color: '#589EFF',
              },
            }
          : false,
      },
    };
  };

  getFormData = () => {
    const regions: any = {
      outerPolygon: this._outerPolygon,
      innerPolygons: this._innerPolygon,
    };
    const formData = {
      regions,
    };
    return { formData };
  };

  updatePolygon = () => {
    const { floorPlanID, token } = this.props;
    if (token) {
      const { formData } = this.getFormData();
      const apiDetails = floorPlanApi.putFloorPlan(undefined, {
        floorPlanID,
      });
      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        if (responses?.[0].status === 'fulfilled') {
          handleNotification('success', {
            message: 'Polygon Updated Successfully!',
          });
        } else {
          allSettledErrorHandling(responses?.[0]);
        }
      };
      httpCallAllSettled({
        requestConfig: [
          {
            ...apiDetails,
            data: formData,
          },
        ],
        headersConfig: { token },
        applyData: handleResponses,
      });
    }
  };

  // _checkForMarkers = () => {
  //   if (this._floorPlanLocations.length > 0) {
  //     let locationsWithRegions: any[] = [];

  //     if (
  //       this._outerPolygon.features &&
  //       this._outerPolygon.features.length > 0
  //     ) {
  //       for (
  //         let index = 0;
  //         index < this._outerPolygon.features.length;
  //         index++
  //       ) {
  //         const outer = this._outerPolygon.features[index];
  //         const outerCoordinates: any = outer.geometry.coordinates;
  //         for (let idx = 0; idx < this._floorPlanLocations.length; idx++) {
  //           const location = this._floorPlanLocations[idx];

  //           const pt = turf.point([location.x, location.y]);
  //           const poly = turf.polygon(outerCoordinates);
  //           const isOuter = turf.booleanPointInPolygon(pt, poly);
  //           if (isOuter) {
  //             locationsWithRegions.push({
  //               regionID: outer.regionID,
  //               floorPlanID: outer.floorPlanID,
  //               regionType: outer.regionType,
  //               locationID: location.locationID,
  //             });

  //             break;
  //           }
  //         }
  //       }
  //     }

  //     if (
  //       this._innerPolygon.features &&
  //       this._innerPolygon.features.length > 0
  //     ) {
  //       for (
  //         let index = 0;
  //         index < this._innerPolygon.features.length;
  //         index++
  //       ) {
  //         const inner = this._innerPolygon.features[index];
  //         const innerCoordinates: any = inner.geometry.coordinates;
  //         for (let idx = 0; idx < this._floorPlanLocations.length; idx++) {
  //           const location = this._floorPlanLocations[idx];

  //           const pt = turf.point([location.x, location.y]);
  //           const poly = turf.polygon(innerCoordinates);
  //           const isInner = turf.booleanPointInPolygon(pt, poly);
  //           if (isInner) {
  //             locationsWithRegions.push({
  //               regionID: inner.regionID,
  //               floorPlanID: inner.floorPlanID,
  //               regionType: inner.regionType,
  //               locationID: location.locationID,
  //             });

  //             break;
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  _onCreated = (event: any) => {
    const { mapInstance } = this.state;
    const { floorPlanID } = this.props;
    if (event?.layerType === 'polygon') {
      let shouldUpdate = true;
      const shape = event?.layer?.toGeoJSON?.();

      let polygonData: GeoJSONFeaturesType = {
        regionID: v4(),
        floorPlanID,
        leafletID: event?.layer?._leaflet_id,
        regionType: 'inner',
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: shape?.geometry?.coordinates,
        },
      };

      if (
        this._outerPolygon.features &&
        this._outerPolygon.features.length > 0
      ) {
        for (
          let index = 0;
          index < this._outerPolygon.features.length;
          index++
        ) {
          const first: any = this._outerPolygon.features[index];
          if (booleanContains(first, shape)) {
            this._innerPolygon = updateImmutably(this._innerPolygon, {
              features: this._innerPolygon.features
                ? { $push: [polygonData] }
                : { $set: [polygonData] },
            });
            break;
          } else if (booleanWithin(first, shape)) {
            shouldUpdate = false;
            message.error(`You can't create multiple Outer Polygon!`);
            break;
          } else if (intersect(first, shape)) {
            shouldUpdate = false;
            message.error(`Polygons can't intersect with each other`);
            break;
          } else {
            polygonData.regionType = 'outer';
            this._outerPolygon = updateImmutably(this._outerPolygon, {
              features: {
                $push: [polygonData],
              },
            });
            break;
          }
        }
      } else {
        polygonData.regionType = 'outer';
        this._outerPolygon = updateImmutably(this._outerPolygon, {
          features: {
            $set: [polygonData],
          },
        });
      }

      mapInstance?.removeLayer(event?.layer);

      if (shouldUpdate && this._editableFG) {
        this.updatePolygon();
        this._addPolygonLayers();
      }
    }
  };

  _onEdited = (event: any) => {
    const { floorPlanID } = this.props;
    let tempOuter = { ...this._outerPolygon };
    let tempInner = { ...this._innerPolygon };
    event?.layers?.eachLayer?.((layer: any) => {
      const poly = new Leaflet.Polygon(layer?._latlngs);
      const shape = layer?.toGeoJSON?.();
      const regionID = v4();
      const polygonData: GeoJSONFeaturesType = {
        regionID,
        floorPlanID,
        regionType: 'inner',
        leafletID: layer?._leaflet_id,
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: shape?.geometry?.coordinates,
        },
      };

      if (
        tempOuter.features &&
        tempOuter.features.find((el) => el.leafletID === layer?._leaflet_id)
      ) {
        if (tempInner.features) {
          tempInner.features.forEach((innerEl) => {
            const first: any = innerEl;
            if (
              booleanContains(first, shape) &&
              tempInner.features &&
              tempOuter.features
            ) {
              tempInner.features = tempInner.features.filter(
                (elem) => elem.leafletID !== innerEl.leafletID
              );
              tempInner.features = [...tempInner.features, polygonData];

              tempOuter.features = tempOuter.features.filter(
                (elem) => elem.leafletID !== layer?._leaflet_id
              );
              tempOuter.features = [...tempOuter.features, innerEl];
            }
          });
        }

        const tempFeatures: any = tempOuter.features.map((el) => {
          if (el.leafletID === layer?._leaflet_id) {
            el = {
              ...el,
              geometry: {
                ...el.geometry,
                coordinates: poly.toGeoJSON().geometry.coordinates,
              },
            };
          }
          return { ...el };
        });
        const tempOuterRegion = updateImmutably(tempOuter, {
          features: { $set: tempFeatures },
        });

        this._outerPolygon = { ...tempOuterRegion };
        this._innerPolygon = { ...tempInner };
      } else if (
        tempInner.features &&
        tempInner.features.find((el) => el.leafletID === layer?._leaflet_id)
      ) {
        if (tempOuter.features) {
          tempOuter.features.forEach((outerEl) => {
            const first: any = outerEl;
            if (
              booleanWithin(first, shape) &&
              tempInner.features &&
              tempOuter.features
            ) {
              tempOuter.features = tempOuter.features.filter(
                (elem) => elem.leafletID !== outerEl.leafletID
              );
              polygonData.regionType = 'outer';
              tempOuter.features = [...tempOuter.features, polygonData];

              tempInner.features = tempInner.features.filter(
                (elem) => elem.leafletID !== layer?._leaflet_id
              );
              tempInner.features = [...tempInner.features, outerEl];
            }
          });
        }

        const tempFeatures: any = tempInner.features.map((el) => {
          if (el.leafletID === layer?._leaflet_id) {
            el = {
              ...el,
              geometry: {
                ...el.geometry,
                coordinates: poly.toGeoJSON().geometry.coordinates,
              },
            };
          }
          return { ...el };
        });
        const tempInnerRegion = updateImmutably(tempInner, {
          features: { $set: tempFeatures },
        });
        this._innerPolygon = { ...tempInnerRegion };
        this._outerPolygon = { ...tempOuter };
      }
    });

    this.updatePolygon();
  };

  _onDeleted = (event: any) => {
    let tempOuter = { ...this._outerPolygon };
    let tempInner = { ...this._innerPolygon };
    if (event?.layers) {
      event?.layers?.eachLayer((layer: any) => {
        tempOuter = updateImmutably(tempOuter, {
          features: {
            $set: tempOuter.features?.filter(
              (el) => el.leafletID !== layer._leaflet_id
            ),
          },
        });
        tempInner = updateImmutably(tempInner, {
          features: {
            $set: tempInner.features?.filter(
              (el) => el.leafletID !== layer._leaflet_id
            ),
          },
        });
      });

      if (
        tempOuter.features &&
        tempOuter.features.length === 0 &&
        tempInner.features &&
        tempInner.features.length === 1
      ) {
        tempOuter.features = [...tempInner.features];
        tempInner.features = undefined;
      }

      this._innerPolygon = { ...tempInner };
      this._outerPolygon = { ...tempOuter };

      this.updatePolygon();
    }
  };

  _addPolygonLayers = () => {
    if (!this._editableFG) {
      return;
    }
    const { mapInstance } = this.state;

    let geoJSON: GeoJSONType = { type: 'FeatureCollection', features: [] };
    if (this._outerPolygon.features) {
      this._outerPolygon.features.forEach((el) => {
        geoJSON.features?.push(el);
      });
    }
    if (this._innerPolygon.features) {
      this._innerPolygon.features.forEach((el) => {
        geoJSON.features?.push(el);
      });
    }

    mapInstance?.eachLayer?.((layer: any) => {
      if (
        layer?.feature?.regionType === 'outer' ||
        layer?.feature?.regionType === 'inner'
      ) {
        mapInstance?.removeLayer?.(layer);
      }
    });

    const leafletGeoJSON = new Leaflet.GeoJSON<GeoJSONType>(geoJSON);
    let tempRegion: GeoJSONType = { type: 'FeatureCollection' };
    leafletGeoJSON.eachLayer((layer: any) => {
      const tempGeoJSON: GeoJSONFeaturesType = layer.toGeoJSON();
      if (tempGeoJSON) {
        tempGeoJSON.leafletID = layer._leaflet_id;
        tempRegion = updateImmutably(tempRegion, {
          features: tempRegion.features
            ? { $push: [tempGeoJSON] }
            : { $set: [tempGeoJSON] },
        });
      }

      this._editableFG.addLayer(layer);
    });

    if (tempRegion.features && tempRegion.features.length > 0) {
      const outerFeatures: GeoJSONFeaturesType[] = [];
      tempRegion.features.forEach((el) => {
        if (el.regionType === 'outer') {
          outerFeatures.push(el);
        }
      });
      this._outerPolygon.features = outerFeatures;

      const innerFeatures: GeoJSONFeaturesType[] = [];
      tempRegion.features.forEach((el) => {
        if (el.regionType === 'inner') {
          innerFeatures.push(el);
        }
      });
      this._innerPolygon.features = innerFeatures;
    }
  };

  _onFeatureGroupReady = (leafletFG: any) => {
    if (leafletFG === null) {
      return;
    }
    this._editableFG = leafletFG;
  };

  render() {
    const { mapDetails, isEditable, newLocation } = this.props;
    const {
      mapBounds,
      floorPlanLocations,
      movedFloorPlanLocations,
      mapCenter,
    } = this.state;

    const mapImage = `data:image/svg+xml,${encodeURIComponent(
      mapDetails.image ?? ''
    )}`;

    let isPolygonEditable = true;
    // if (selectedLocationFromMarker?.locationID) {
    //   isPolygonEditable = true;
    // }

    const editControProps = this.getPolygonOptions(isPolygonEditable);

    return (
      <Fragment>
        <MapContainer
          tap={false}
          className={cssStyles.floorPlanMap}
          minZoom={-1}
          zoom={-1}
          center={mapCenter}
          bounds={mapBounds}
          // maxBounds={mapBounds}
          crs={customCRSSimple}
          whenCreated={this.handleMap}>
          {mapDetails.image && mapBounds && (
            <ImageOverlay bounds={mapBounds} url={mapImage} />
          )}
          <FeatureGroup
            ref={(reactFGref) => {
              this._onFeatureGroupReady(reactFGref);
            }}>
            <EditControl
              {...editControProps}
              position="topright"
              onCreated={this._onCreated}
              onEdited={this._onEdited}
              onDeleted={this._onDeleted}
            />
          </FeatureGroup>
          {floorPlanLocations.length > 0 &&
            floorPlanLocations.map((item) => {
              if (item.x !== undefined) {
                let coordinates: Leaflet.LatLngExpression = [
                  item.y ?? 0,
                  item.x ?? 0,
                ];
                const popupDetails = {
                  locationName: item.locationName,
                };

                let matchedLocation: FloorPlanLocationListType | undefined;
                if (movedFloorPlanLocations.length > 0) {
                  matchedLocation = movedFloorPlanLocations.find(
                    (el) => el.locationID === item.locationID
                  );
                }

                if (matchedLocation) {
                  popupDetails.locationName = matchedLocation.locationName;
                  coordinates = [
                    matchedLocation.y ?? 0,
                    matchedLocation.x ?? 0,
                  ];
                }

                let icon = getLeafletIcon('blue');
                // let isAddPolygon = true;
                // if (selectedMarkerLocID === item.locationID) {
                //   icon = getLeafletIcon('grey');
                // }
                if (newLocation?.locationID === item.locationID) {
                  icon = getLeafletIcon('amber');
                  // isAddPolygon = false;
                }
                if (matchedLocation?.locationID === item.locationID) {
                  icon = getLeafletIcon('red');
                  // isAddPolygon = false;
                }

                return (
                  <Fragment key={item.locationID}>
                    <Marker
                      key={item.locationID}
                      title={item.locationID}
                      icon={icon}
                      draggable={isEditable}
                      position={coordinates}
                      eventHandlers={{
                        dragend: (event) => {
                          this.onMoveMarker(event, item);
                        },
                      }}>
                      <CustomPopup
                        popupDetails={popupDetails}
                        floorPlanLocation={item}
                        matchedLocation={matchedLocation}
                        handleDelete={this.handleDelete}
                        handleConfirm={this.handleConfirm}
                        handleReset={this.handleReset}
                        isEditable={isEditable}
                      />
                    </Marker>
                  </Fragment>
                );
              }
              return null;
            })}
        </MapContainer>
      </Fragment>
    );
  }
}

export default PolygonMap;

const CustomPopup = ({
  popupDetails,
  floorPlanLocation,
  matchedLocation,
  handleReset,
  handleConfirm,
  handleDelete,
  isEditable,
}: {
  popupDetails: { locationName: string };
  floorPlanLocation: FloorPlanLocationListType;
  matchedLocation?: FloorPlanLocationListType;
  handleReset: (location: FloorPlanLocationListType) => void;
  handleConfirm: (location: FloorPlanLocationListType) => void;
  handleDelete: (location: FloorPlanLocationListType) => void;
  isEditable: boolean;
}) => {
  return (
    <Popup>
      <Row justify="center" gutter={[0, 8]}>
        <Col>
          {/* <pre>{JSON.stringify(popupDetails, null, 2)}</pre> */}
          Location Name: {popupDetails?.locationName ?? ''}
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 16]}>
        <Col>Do you want to update?</Col>
      </Row>
      <Row justify="center" gutter={[0, 16]}>
        <Col className="text-center">
          <Button
            disabled={!isEditable}
            size={`small`}
            onClick={(event) =>
              floorPlanLocation &&
              handleReset?.(matchedLocation ?? floorPlanLocation)
            }>
            Cancel
          </Button>
        </Col>
        <Col className="text-center pl-2">
          <Button
            disabled={!isEditable}
            type="primary"
            size={`small`}
            onClick={(event) =>
              floorPlanLocation &&
              handleConfirm?.(matchedLocation ?? floorPlanLocation)
            }>
            Yes
          </Button>
        </Col>
        <Col className="text-center pl-2">
          <Button
            disabled={!isEditable}
            danger
            type="primary"
            size={`small`}
            onClick={(event) =>
              floorPlanLocation &&
              handleDelete?.(matchedLocation ?? floorPlanLocation)
            }>
            Remove
          </Button>
        </Col>
      </Row>
    </Popup>
  );
};
