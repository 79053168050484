import { Row, Col, Button } from 'antd';
import { ReactNode } from 'react';
import { Popup } from 'react-leaflet';

import { FloorPlanLocationListType } from '../../type-definitions/api-types';

const PopupWithEdit = ({
  matchedLocation,
  floorPlanLocation,
  popupDetails,
  isEditable = false,
  handleReset,
  handleConfirm,
  handleDelete,
  extraRender,
}: {
  matchedLocation?: FloorPlanLocationListType;
  floorPlanLocation?: FloorPlanLocationListType;
  popupDetails?: { locationName?: string };
  isEditable?: boolean;
  handleReset?: (location: FloorPlanLocationListType) => void;
  handleConfirm?: (location: FloorPlanLocationListType) => void;
  handleDelete?: (location: FloorPlanLocationListType) => void;
  extraRender?: () => ReactNode;
}) => {
  return (
    <Popup>
      <Row justify="center" gutter={[0, 8]}>
        <Col>
          {/* <pre>{JSON.stringify(popupDetails, null, 2)}</pre> */}
          Location Name: {popupDetails?.locationName ?? ''}
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 16]}>
        <Col>Do you want to update?</Col>
      </Row>
      <Row justify="center" gutter={[0, 16]}>
        <Col className="text-center">
          <Button
            disabled={!isEditable}
            size={`small`}
            onClick={(event) =>
              floorPlanLocation &&
              handleReset?.(matchedLocation ?? floorPlanLocation)
            }>
            Cancel
          </Button>
        </Col>
        <Col className="text-center pl-2">
          <Button
            disabled={!isEditable}
            type="primary"
            size={`small`}
            onClick={(event) =>
              floorPlanLocation &&
              handleConfirm?.(matchedLocation ?? floorPlanLocation)
            }>
            Yes
          </Button>
        </Col>
        <Col className="text-center pl-2">
          <Button
            disabled={!isEditable}
            danger
            type="primary"
            size={`small`}
            onClick={(event) =>
              floorPlanLocation &&
              handleDelete?.(matchedLocation ?? floorPlanLocation)
            }>
            Remove
          </Button>
        </Col>
      </Row>

      {extraRender?.()}
    </Popup>
  );
};

export default PopupWithEdit;
