import {
  Dispatch,
  Fragment,
  useReducer,
  useCallback,
  FormEvent,
  useEffect,
} from 'react';
import { message, Tabs } from 'antd';

import {
  PageDefaultPropsType,
  ReducerHookActionType,
  SelectOptionsType,
} from '../type-definitions';

import {
  StateType,
  initState,
  reducer,
  FormElementsType,
  initFormElements,
} from '../components/BuildingAdd/helpers';
import DetailsTab from '../components/BuildingAdd/DetailsTab';
import AddressTab from '../components/BuildingAdd/AddressTab';
import PromptPopup from '../components/PromptPopup';
import FormWrapper from '../components/FormWrapper';

import { reducerHookActions } from '../shared/helpers/reducer';
import { buildingApi, partnerApi } from '../api-services/api-list';
import {
  allSettledErrorHandling,
  httpCallAllSettled,
} from '../api-services/api';
import {
  AxiosHttpAllSettledResponsesType,
  PartnerListType,
} from '../type-definitions/api-types';
import { useDispatch } from 'react-redux';
import {
  defaultPartnerID,
  dispatchUpdateToken,
  handleNotification,
  updateImmutably,
} from '../shared/helpers';
import { checkValidation } from '../utils/validation';

interface PropsType extends PageDefaultPropsType {}

const BuildingAdd = ({ userData }: PropsType) => {
  const [state, dispatchToState]: [
    state: StateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, initState);
  const dispatch = useDispatch();

  const { hasInputChanged, loading, formElements, currentTabIndex } = state;

  const handleFormElements = (
    partners: PartnerListType[],
    elements: FormElementsType
  ) => {
    const partnerListOptions: SelectOptionsType[] = partners.map((item) => ({
      value: item.partnerID,
      text: item.partnerID,
    }));

    let tempFormElements = updateImmutably(elements, { $merge: elements });

    const selected = partners.find(
      (item) => item?.partnerID === defaultPartnerID
    );

    tempFormElements = updateImmutably(tempFormElements, {
      partner: { optionValues: { $set: partnerListOptions } },
    });
    if (selected) {
      tempFormElements = updateImmutably(tempFormElements, {
        partner: { value: { $set: selected.partnerID } },
      });
    } else {
      tempFormElements = updateImmutably(tempFormElements, {
        partner: { value: { $set: partnerListOptions[0].value } },
      });
    }

    return tempFormElements;
  };

  useEffect(() => {
    let isMounted = true;
    if (formElements.partner.optionValues?.length === 0 && isMounted) {
      const getPartners = partnerApi.getPartners();

      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        const stateData: any = {
          loading: false,
        };
        if (responses?.[0].status === 'fulfilled') {
          const result = responses[0].value?.data;
          dispatchUpdateToken(dispatch, responses[0]);

          let tempPartnerData: PartnerListType[] = result?.data ?? [];
          if (tempPartnerData.length > 0) {
            stateData.formElements = handleFormElements(
              tempPartnerData,
              formElements
            );
          }
        } else {
          allSettledErrorHandling(responses[0]);
        }

        dispatchToState({
          type: reducerHookActions.setState,
          payload: { ...stateData },
        });
      };

      httpCallAllSettled({
        requestConfig: [{ ...getPartners }],
        headersConfig: { token: userData.token },
        applyData: handleResponses,
      });
    }
    return () => {
      isMounted = false;
    };
  }, [
    dispatch,
    formElements,
    formElements.partner.optionValues?.length,
    userData.token,
  ]);

  const handleSubmit = useCallback(
    (onConfirm?: () => void) => {
      let tempFormElements = { ...formElements };

      let formElemKey: keyof typeof tempFormElements;

      for (formElemKey in tempFormElements) {
        tempFormElements = updateImmutably(tempFormElements, {
          [formElemKey]: {
            touched: { $set: true },
            valid: {
              $set: checkValidation(
                tempFormElements[formElemKey].value,
                tempFormElements[formElemKey].validation
              ),
            },
          },
        });
      }

      dispatchToState({
        type: reducerHookActions.setState,
        payload: { formElements: tempFormElements },
      });

      for (formElemKey in tempFormElements) {
        if (!tempFormElements[formElemKey].valid) {
          message.error('Please fill all the fields');
          return;
        }
      }

      dispatchToState({
        type: reducerHookActions.setState,
        payload: { loading: true },
      });

      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        let stateData: any = {
          loading: false,
        };

        if (responses?.[0].status === 'fulfilled') {
          handleNotification('success', {
            message: responses[0].value.data?.message,
          });
          dispatchUpdateToken(dispatch, responses[0]);
          if (onConfirm) {
            onConfirm();
          } else {
            const matched = tempFormElements.partner.optionValues?.find(
              (el) => el.value === defaultPartnerID
            );
            stateData = {
              ...stateData,
              formElements: {
                ...initFormElements,
                partner: {
                  ...initFormElements.partner,
                  optionValues: tempFormElements.partner.optionValues,
                  value:
                    matched?.value ??
                    tempFormElements.partner.optionValues?.[0].value ??
                    '',
                },
              },
              currentTabIndex: '1',
            };
          }
        } else {
          allSettledErrorHandling(responses[0]);
        }

        dispatchToState({
          type: reducerHookActions.setState,
          payload: { ...stateData },
        });
      };

      const apiDetails = buildingApi.postBuilding();

      const data = {
        name: tempFormElements.buildingName.value,
        address: {
          street: tempFormElements.street.value,
          street2: tempFormElements.street2.value,
          city: tempFormElements.city.value,
          province: tempFormElements.province.value,
          postalcode: tempFormElements.postalCode.value,
          country: tempFormElements.country.value,
        },
        partnerID: tempFormElements.partner.value,
      };

      httpCallAllSettled({
        requestConfig: [{ ...apiDetails, data }],
        headersConfig: { token: userData.token },
        applyData: handleResponses,
      });
    },
    [dispatch, formElements, userData.token]
  );

  const inputChangedHandler = useCallback(
    (name: keyof FormElementsType, value: string) => {
      let tempFormElements = { ...formElements };

      tempFormElements = updateImmutably(tempFormElements, {
        [name]: {
          value: { $set: value },
          touched: { $set: true },
          valid: {
            $set: checkValidation(value, tempFormElements[name].validation),
          },
        },
      });

      dispatchToState({
        type: reducerHookActions.setState,
        payload: { formElements: tempFormElements, hasInputChanged: true },
      });
    },
    [formElements]
  );

  const handlePopupConfirm = (onConfirm: () => void) => {
    handleSubmit(onConfirm);
  };

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit();
  };

  const setCurrentTabIndex = (index: string) => {
    dispatchToState({
      type: reducerHookActions.setState,
      payload: { currentTabIndex: index },
    });
  };

  return (
    <Fragment>
      {hasInputChanged && (
        <PromptPopup
          when={hasInputChanged}
          handleConfirm={handlePopupConfirm}
        />
      )}

      <FormWrapper loading={loading} title="Building Add">
        <form onSubmit={onFormSubmit} noValidate>
          <Tabs activeKey={currentTabIndex} onTabClick={setCurrentTabIndex}>
            <Tabs.TabPane key="1" tab="Details">
              <DetailsTab
                formElements={formElements}
                inputChangedHandler={inputChangedHandler}
                setCurrentTabIndex={setCurrentTabIndex}
              />
            </Tabs.TabPane>
            <Tabs.TabPane key="2" tab="Address">
              <AddressTab
                formElements={formElements}
                onInputChanged={inputChangedHandler}
              />
            </Tabs.TabPane>
          </Tabs>
        </form>
      </FormWrapper>
    </Fragment>
  );
};

export default BuildingAdd;
