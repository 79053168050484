import { Fragment } from 'react';
import { Col, Row } from 'antd';
import AntdInput from '../../../components/AntdInput';
import { AddressFormElementsType } from './helpers';
import { InputChangedHandlerType } from '../../../type-definitions';

interface PropsType {
  formElements: AddressFormElementsType;
  onInputChanged: InputChangedHandlerType;
}

const Address = ({ formElements, onInputChanged }: PropsType) => {
  return (
    <Fragment>
      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput {...formElements.street} onInputChanged={onInputChanged} />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.street2}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput {...formElements.city} onInputChanged={onInputChanged} />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.province}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.postalCode}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>

      <Row gutter={[{ md: 12 }, 0]}>
        <Col xs={24}>
          <AntdInput
            {...formElements.country}
            onInputChanged={onInputChanged}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Address;
