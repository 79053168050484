import { Fragment, ReactNode } from 'react';
import { Row, Col } from 'antd';

interface PropsType {
  children?: ReactNode;
}

function AppLayout({ children }: PropsType) {
  return (
    <Fragment>
      <Row gutter={[0, { xs: 32, sm: 32, md: 48 }]}>
        <Col xs={24}>{children}</Col>
      </Row>
    </Fragment>
  );
}

export default AppLayout;
