import { Fragment, ReactNode } from 'react';
import { Col, Row } from 'antd';
import { ColProps, RowProps } from 'antd/lib/grid';
import AntdCoverSpinner from '../../components/AntdCoverSpinner';
import AntdCard from '../AntdCard';

type PropsType = Partial<{
  title: string;
  children: ReactNode;
  loading: boolean;
  classes?: {
    titleRowOne?: string;
    childrenRowOne?: string;
    card?: string;
  };
  rowDetails?: {
    one?: RowProps;
    two?: RowProps;
    three?: RowProps;
  };
  colDetails?: {
    one?: ColProps;
    two?: ColProps;
    three?: ColProps;
  };
}>;

function FormWrapper({
  title,
  children,
  loading,
  classes,
  rowDetails,
  colDetails,
}: PropsType) {
  return (
    <Fragment>
      <AntdCoverSpinner active={loading} size="large">
        <Row justify={rowDetails?.one?.justify || 'center'}>
          <Col
            xs={colDetails?.one?.xs || colDetails?.one?.sm || 20}
            sm={colDetails?.one?.sm || 20}
            md={colDetails?.one?.md || 16}
            lg={colDetails?.one?.lg || 12}
            xl={colDetails?.one?.xl || colDetails?.one?.lg || 12}
            xxl={colDetails?.one?.xxl || colDetails?.one?.lg || 12}>
            <AntdCard className={classes?.card || ''}>
              <Row
                justify={rowDetails?.two?.justify || 'center'}
                className={`pt-4 px-4 ${classes?.titleRowOne || ''}`}>
                <Col>
                  <h5>
                    <strong>{title}</strong>
                  </h5>
                </Col>
              </Row>
              <Row
                justify={rowDetails?.three?.justify || 'center'}
                className={`py-4 px-4 ${classes?.childrenRowOne || ''}`}>
                <Col
                  xs={colDetails?.three?.xs || colDetails?.three?.sm || 24}
                  sm={colDetails?.three?.sm || 24}
                  md={colDetails?.three?.md || 24}
                  lg={colDetails?.three?.lg || 24}
                  xl={colDetails?.three?.xl || colDetails?.three?.lg || 24}
                  xxl={colDetails?.three?.xxl || colDetails?.three?.lg || 24}>
                  {children}
                </Col>
              </Row>
            </AntdCard>
          </Col>
        </Row>
      </AntdCoverSpinner>
    </Fragment>
  );
}

export default FormWrapper;
