import { Component, createRef, CSSProperties, Fragment } from 'react';
import { MapContainer, TileLayer, Marker, LayersControl } from 'react-leaflet';
import * as Leaflet from 'leaflet';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import MapCustomPopup from '../MapCustomPopup';

import cssStyles from './leafletMapMultiple.module.scss';
import { getLeafletIcon } from '../../../utils/leaflet-helpers';

import 'leaflet/dist/leaflet.css';
// import { Button } from 'antd';

type PropsType = {
  latitude: number;
  longitude: number;
  dataSource: any[];
  classes?: string;
  zoom?: number;
  height?: number;
  onMarkerMove?: (lat: number, lng: number) => void;
  onMapZoom?: (zoom: number) => void;
  handleMapFilter?: (mapList: any[]) => void;
  styles?: CSSProperties;
};

const currentDateTime = moment.utc();

interface StateType {
  mapList: any[];
  mapZoom: number;
  mapInstance?: Leaflet.Map;
  // showSatelliteView: boolean;
}

class LocationListMap extends Component<PropsType, StateType> {
  tileLayerRef = createRef<Leaflet.TileLayer>();
  _isMounted = false;
  constructor(props: PropsType) {
    super(props);

    this.state = {
      mapList: [],
      mapZoom: props.zoom || 10,
      mapInstance: undefined,
      // showSatelliteView: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const { dataSource } = this.props;

    if (dataSource && dataSource.length > 0) {
      this._isMounted && this.setState({ mapList: cloneDeep(dataSource) });
    }
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const { dataSource, handleMapFilter } = this.props;
    const { mapList, mapInstance } = this.state;

    if (
      !handleMapFilter &&
      Array.isArray(dataSource) &&
      Array.isArray(mapList) &&
      mapList.length !== dataSource.length
    ) {
      this._isMounted && this.setState({ mapList: cloneDeep(dataSource) });
    }

    if (
      handleMapFilter &&
      mapInstance &&
      handleMapFilter !== prevProps.handleMapFilter
    ) {
      this._isMounted && this.onMapMoveEnd(mapInstance);
    }

    if (dataSource.length !== mapList.length && mapInstance) {
      this.setState({ mapList: cloneDeep(dataSource) }, () => {
        this._isMounted && this.onMapMoveEnd(mapInstance, dataSource);
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleMap = (map: Leaflet.Map) => {
    this._isMounted && this.setState({ mapInstance: map });
    map.on('movestart', (event) => {
      map.eachLayer(function (layer) {
        if (layer.isPopupOpen()) {
          layer.closePopup();
        }
      });
    });
    map.on('moveend', (event) => {
      this._isMounted && this.onMapMoveEnd(map);
    });
  };

  onMapMoveEnd = (map: Leaflet.Map, mapDataList?: any[]) => {
    if (map) {
      const { handleMapFilter } = this.props;
      const { mapList, mapInstance } = this.state;
      const stateData: { mapInstance?: Leaflet.Map } = {};
      if (!mapInstance) {
        stateData.mapInstance = map;
      }
      // const currentZoom: number = event?.target?.getZoom?.();
      const currentZoom: number = map?.getZoom?.();
      if (!handleMapFilter) {
        this._isMounted &&
          this.setState({ mapZoom: currentZoom, ...stateData });
      } else {
        let tempMapList = cloneDeep(mapList);
        const filteredMarkers: Array<Leaflet.LatLng> = [];
        if (!mapDataList) {
          map.eachLayer(function (layer) {
            if (layer.isPopupOpen()) {
              layer.closePopup();
            }
            if (layer instanceof Leaflet.Marker) {
              if (map.getBounds().contains(layer.getLatLng())) {
                const latLng = layer?.getLatLng?.();
                filteredMarkers.push(latLng);
              }
            }
          });

          if (filteredMarkers && filteredMarkers.length > 0) {
            tempMapList = tempMapList.filter((item) => {
              return filteredMarkers.find((el) => {
                return item.lat === el.lat && item.lng === el.lng;
              });
            });

            this._isMounted &&
              this.setState({ mapZoom: currentZoom, ...stateData }, () => {
                this._isMounted && handleMapFilter?.(tempMapList);
              });
          } else if (filteredMarkers && filteredMarkers.length === 0) {
            this._isMounted &&
              this.setState({ mapZoom: currentZoom, ...stateData }, () => {
                this._isMounted && handleMapFilter?.([]);
              });
          }
        } else {
          tempMapList = cloneDeep(mapDataList);
          this._isMounted &&
            this.setState({ mapZoom: currentZoom, ...stateData }, () => {
              map.eachLayer(function (layer) {
                if (layer instanceof Leaflet.Marker) {
                  if (map.getBounds().contains(layer.getLatLng())) {
                    const latLng = layer?.getLatLng?.();
                    filteredMarkers.push(latLng);
                  }
                }
              });

              if (filteredMarkers && filteredMarkers.length > 0) {
                tempMapList = tempMapList.filter((item) => {
                  return filteredMarkers.find((el) => {
                    return item.lat === el.lat && item.lng === el.lng;
                  });
                });

                this._isMounted && handleMapFilter?.(tempMapList);
              } else if (filteredMarkers && filteredMarkers.length === 0) {
                this._isMounted && handleMapFilter?.([]);
              }
            });
        }
      }
    }
  };

  // handleMapView = () => {
  //   this._isMounted &&
  //     this.setState((prevState) => {
  //       return {
  //         ...prevState,
  //         showSatelliteView: !prevState.showSatelliteView,
  //       };
  //     });
  // };

  render() {
    const { classes, latitude, longitude, styles } = this.props;
    const { mapZoom, mapList } = this.state;

    return (
      <Fragment>
        <MapContainer
          tap={false}
          style={styles}
          className={classes || cssStyles.map}
          whenCreated={this.handleMap}
          center={[latitude || 0, longitude || 0]}
          // bounds={bounds}
          zoom={mapZoom}
          // minZoom={10}
          maxZoom={19}>
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Normal View">
              <TileLayer
                ref={this.tileLayerRef}
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                maxZoom={19}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite View">
              <TileLayer
                ref={this.tileLayerRef}
                url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                maxZoom={19}
              />
            </LayersControl.BaseLayer>
          </LayersControl>

          {/* <CustomButton
            position="topright"
            handleMapView={this.handleMapView}
            showSatelliteView={showSatelliteView}
          /> */}

          {mapList &&
            mapList.length > 0 &&
            mapList.map((item, index) => {
              let leafletIcon = getLeafletIcon();
              if (item.status === 'LIVE') {
                const lastContact = moment.utc(item?.lastContact);
                const timeDiff = currentDateTime.diff(
                  lastContact,
                  'hours',
                  true
                );
                if (timeDiff && timeDiff < 3) {
                  leafletIcon = getLeafletIcon('green');
                } else if (timeDiff && timeDiff < 48) {
                  leafletIcon = getLeafletIcon('amber');
                } else {
                  leafletIcon = getLeafletIcon('red');
                }
              } else if (item.status === 'OFFLINE') {
                leafletIcon = getLeafletIcon('grey');
              }
              return (
                <Fragment key={index}>
                  <Marker
                    position={[item.lat || 0, item.lng || 0]}
                    icon={leafletIcon}>
                    <MapCustomPopup item={item} />
                  </Marker>
                </Fragment>
              );
            })}
        </MapContainer>
      </Fragment>
    );
  }
}

export default LocationListMap;

// const POSITION_CLASSES = {
//   bottomleft: 'leaflet-bottom leaflet-left',
//   bottomright: 'leaflet-bottom leaflet-right',
//   topleft: 'leaflet-top leaflet-left',
//   topright: 'leaflet-top leaflet-right',
// };

// const CustomButton = ({
//   position,
//   showSatelliteView,
//   handleMapView,
// }: {
//   position?: 'bottomleft' | 'bottomright' | 'topleft' | 'topright';
//   showSatelliteView: boolean;
//   handleMapView: () => void;
// }) => {
//   return (
//     <Fragment>
//       <div
//         className={
//           (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
//         }>
//         <div className="leaflet-control leaflet-bar">
//           <Button type="primary" onClick={handleMapView} size="small">
//             {showSatelliteView ? 'Normal' : 'Satellite'}
//           </Button>
//         </div>
//       </div>
//     </Fragment>
//   );
// };
