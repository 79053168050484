import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Fragment } from 'react';
import PrivateRoute from './PrivateRoute';
import Login from '../pages/Login';
import Devices from '../pages/Devices';
import DeviceAdd from '../pages/DeviceAdd';
import DeviceDetails from '../pages/DeviceDetails';
import {
  authRoutes,
  deviceRoutes,
  locationRoutes,
  userRoutes,
  groupRoutes,
  floorPlanRoutes,
  partnerRoutes,
  webViewRoutes,
  hardwareRoutes,
  buildingRoutes,
} from './routes-list';
import Users from '../pages/Users';
import UserAdd from '../pages/UserAdd';
import UserDetails from '../pages/UserDetails';
import LocationList from '../pages/LocationList';
import Groups from '../pages/Groups';
import LocationAdd from '../pages/LocationAdd';
import LocationDetails from '../pages/LocationDetails';
import GroupAdd from '../pages/GroupAdd';
import GroupDetails from '../pages/GroupDetails';
import FloorPlans from '../pages/FloorPlans';
import Partners from '../pages/Partners';
import FloorPlanAdd from '../pages/FloorPlanAdd';
import ProfileEdit from '../pages/ProfileEdit';
import FloorPlanDetails from '../pages/FloorPlanDetails';
import ResetPassword from '../pages/ResetPassword';
import ChangePassword from '../pages/ChangePassword';
import UserChangePassword from '../pages/UserChangePassword';
import AppLayout from '../components-shared/AppLayout';
import WebViewFloorPlanDetails from '../web-view/pages/WebViewFloorPlanDetails';
import HardwareList from '../pages/HardwareList';
import HardwareAdd from '../pages/HardwareAdd';
import HardwareDetails from '../pages/HardwareDetails';
import WebViewFloorPlanEngineer from '../web-view/pages/WebViewFloorPlanEngineer';
import PartnerAdd from '../pages/PartnerAdd';
import PartnerDetails from '../pages/PartnerDetails';
import WebViewFloorPlanAssignTo from '../web-view/pages/WebViewFloorPlanAssignTo';
import BugReporting from '../pages/BugReporting';
import {
  getUserData,
  getUserPermissionList,
  getUserRightList,
} from '../redux/selectors';

import LoadingPage from '../components-shared/LoadingPage';
import Home from '../pages/Home';
import WebViewFloorPlanAdmin from '../web-view/pages/WebViewFloorPlanAdmin';
import Buildings from '../pages/Buildings';
import BuildingAdd from '../pages/BuildingAdd';
import { getFromLocal } from '../shared/helpers';

function Routes() {
  const userData = useSelector(getUserData);
  const userPermissionList = useSelector(getUserPermissionList);
  const userRightList = useSelector(getUserRightList);

  const userToken = getFromLocal('token');
  let homeElem = <LoadingPage />;
  if (!userToken) {
    homeElem = <Redirect to={authRoutes.login} />;
  }
  if (userData.token && userPermissionList.length > 0) {
    // homeElem = <Redirect to={deviceRoutes.list} />;
    homeElem = <Home />;
  }

  return (
    <Fragment>
      <Switch>
        {/* // ## Public Routes */}
        <Route exact path="/" render={(renderProps) => homeElem} />

        <Route
          exact
          path={authRoutes.login}
          render={(renderProps) => {
            return (
              <AppLayout>
                <Login
                  {...renderProps}
                  isAuth={!!userData.token}
                  userData={userData}
                />
              </AppLayout>
            );
          }}
        />

        <Route
          exact
          path={authRoutes.resetPassword()}
          render={(renderProps) => {
            return (
              <AppLayout>
                <ResetPassword
                  {...renderProps}
                  userData={userData}
                  userPermissionList={userPermissionList}
                />
              </AppLayout>
            );
          }}
        />

        <Route
          exact
          path={authRoutes.changePassword()}
          render={(renderProps: RouteComponentProps<any, any, any>) => {
            return (
              <AppLayout>
                <ChangePassword
                  {...renderProps}
                  isAuth={!!userData.token}
                  userData={userData}
                  userPermissionList={userPermissionList}
                />
              </AppLayout>
            );
          }}
        />

        {/* // ## Private Routes */}
        <PrivateRoute
          isAuth={!!userData.token}
          path={authRoutes.profileChangePassword()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={UserChangePassword}
        />
        <PrivateRoute
          isAuth={!!userData.token}
          path={deviceRoutes.list}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={Devices}
        />
        <PrivateRoute
          isAuth={!!userData.token}
          path={deviceRoutes.add}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={DeviceAdd}
        />
        <PrivateRoute
          isAuth={!!userData.token}
          path={userRoutes.list}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={Users}
        />
        <PrivateRoute
          isAuth={!!userData.token}
          path={userRoutes.add}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={UserAdd}
        />
        <PrivateRoute
          isAuth={!!userData.token}
          path={userRoutes.details()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={UserDetails}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={locationRoutes.list}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={LocationList}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={locationRoutes.add}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={LocationAdd}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={locationRoutes.details()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={LocationDetails}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={groupRoutes.list}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={Groups}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={groupRoutes.add}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={GroupAdd}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={groupRoutes.details()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={GroupDetails}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={floorPlanRoutes.list}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={FloorPlans}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={floorPlanRoutes.add}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={FloorPlanAdd}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={partnerRoutes.list}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={Partners}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={authRoutes.profileEdit()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={ProfileEdit}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={floorPlanRoutes.details()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={FloorPlanDetails}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={deviceRoutes.details()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={DeviceDetails}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={hardwareRoutes.list}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={HardwareList}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={hardwareRoutes.add}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={HardwareAdd}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={hardwareRoutes.details()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={HardwareDetails}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={partnerRoutes.add}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={PartnerAdd}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={partnerRoutes.details()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={PartnerDetails}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={authRoutes.bugReporting()}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={BugReporting}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={buildingRoutes.add}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={BuildingAdd}
        />

        <PrivateRoute
          isAuth={!!userData.token}
          path={buildingRoutes.list}
          userData={userData}
          userPermissionList={userPermissionList}
          userRightList={userRightList}
          component={Buildings}
        />

        {/* // ## Dynamic Routes */}
        <Route
          exact
          path={`${authRoutes.login}/:uname`}
          render={(props) => {
            return (
              <AppLayout>
                <Login
                  {...props}
                  isAuth={!!userData.token}
                  userData={userData}
                />
              </AppLayout>
            );
          }}
        />

        <Route
          exact
          path={`${authRoutes.login}/:uname/:pwd`}
          render={(props) => {
            return (
              <AppLayout>
                <Login
                  {...props}
                  isAuth={!!userData.token}
                  userData={userData}
                />
              </AppLayout>
            );
          }}
        />

        {/* Web View */}
        <Route
          exact
          path={webViewRoutes.floorPlanDetails()}
          component={WebViewFloorPlanDetails}
        />

        <Route
          exact
          path={webViewRoutes.floorPlanEngineer()}
          component={WebViewFloorPlanEngineer}
        />
        <Route
          exact
          path={webViewRoutes.floorPlanAssignTo()}
          component={WebViewFloorPlanAssignTo}
        />
        <Route
          exact
          path={webViewRoutes.floorPlanAdmin()}
          component={WebViewFloorPlanAdmin}
        />
      </Switch>
    </Fragment>
  );
}

export default Routes;
