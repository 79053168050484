import { ApiMethodType } from '../type-definitions/api-types';
import { getApiPath, getApiPathMaint } from './api-path';

const apiService = {
  api: `api`,
  prod: `prod`,
  ghs: `ghs`,
};

const apiMethod: ApiMethodType = {
  get: `GET`,
  post: `POST`,
  put: `PUT`,
  delete: `DELETE`,
  patch: `PATCH`,
  purge: `PURGE`,
};

// apiMethod.

const apiVersion = {
  v01: `V01`,
  v02: `V02`,
  v03: `V03`,
};

export type HttpMethodContentType =
  | 'json'
  | 'formUrlEncoded'
  | 'multipartFormData';

const contentType = {
  json: 'application/json',
  formUrlEncoded: 'application/x-www-form-urlencoded',
  multipartFormData: 'multipart/form-data',
};

class AuthApi {
  // getLogin(params: { uname: string; pwd: string; newpwd?: string }) {
  //   const apiPath = getApiPath(apiService.api);
  //   const url = `${apiPath}/${apiService.api}/${apiVersion.v02}/admin/login`;
  //   return {
  //     url,
  //     method: apiMethod.get,
  //     contentType: contentType.formUrlEncoded,
  //     params,
  //   };
  // }

  getLogin(params: {
    username: string;
    password: string;
    newpassword?: string;
  }) {
    const apiPath = getApiPath(apiService.api);
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/login`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  // getTokenLogin() {
  //   const apiPath = getApiPath(apiService.api);
  //   const url = `${apiPath}/${apiService.api}/${apiVersion.v02}/admin/tokenlogin`;
  //   return {
  //     url,
  //     method: apiMethod.get,
  //     contentType: contentType.formUrlEncoded,
  //   };
  // }

  getTokenLogin() {
    const apiPath = getApiPath(apiService.api);
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/tokenlogin`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.formUrlEncoded,
    };
  }

  getUserRights() {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/admin/rights`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.formUrlEncoded,
    };
  }

  getResetPassword(params: { username: string }) {
    const apiPath = getApiPath(apiService.api);
    const url = `${apiPath}/${apiService.api}/${apiVersion.v03}/passwordreset`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }
}

export const authApi = new AuthApi();

class DeviceApi {
  getDevices(
    params?: { omitLocationID: string } | null,
    queryParms?: {} | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postDevice(params?: {} | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params,
    };
  }

  putDevice(params?: {} | null, queryParms?: { deviceID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices/${queryParms?.deviceID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params,
    };
  }

  getStockLocations(params?: {} | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/stocklocations`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  deleteDevice(params?: {} | null, queryParms?: { deviceID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices/${queryParms?.deviceID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }

  getDeviceDetails(
    params?: {} | null,
    queryParms?: { deviceID: string } | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices/${queryParms?.deviceID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getStockDevices(
    params?: { locationID: string } | null,
    queryParms?: {} | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/stockdevices`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getAssignableLocations(params?: {} | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/assignablelocations`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  putAssignToLocation(
    params?: {},
    queryParms?: { deviceID: string; locationID: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices/${queryParms?.deviceID}/assignto/${queryParms?.locationID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params,
    };
  }

  putSwapDevice(
    params?: {},
    queryParms?: { previousDeviceID: string; newDeviceID: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices/${queryParms?.previousDeviceID}/swap/${queryParms?.newDeviceID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params,
    };
  }

  putSubstituteDevices(
    params?: { deviceID: string } | null,
    queryParms?: {} | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/substitutedevices`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params: {
        for: params?.deviceID,
      },
    };
  }

  putAssignableDevices(
    params?: { locationID: string } | null,
    queryParms?: {} | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/assignabledevices`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params: {
        for: params?.locationID,
      },
    };
  }

  getDeviceJournals(
    params?: {
      page?: number;
      pagesize?: number;
      entryType?: string;
      locationID?: string;
    },
    queryParms?: { deviceID: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices/${queryParms?.deviceID}/journal/entries`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postDeviceJournalNotes(params?: {}, queryParms?: { deviceID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices/${queryParms?.deviceID}/journal/notes`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params,
    };
  }

  getDeviceJournalDetails(
    params?: {},
    queryParms?: { deviceID: string; ts: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devices/${queryParms?.deviceID}/journal/entries/${queryParms?.ts}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }
}

export const deviceApi = new DeviceApi();

class LocationApi {
  // GET
  getAssignableLocations(
    params?: { deviceID: string } | null,
    queryParms?: {} | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/assignablelocations`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params: { for: params?.deviceID },
    };
  }

  getLocations(
    params?: {
      region?: string;
      centre?: string;
      radius?: string;
      groupID?: string;
    },
    queryParms?: {}
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getLocationDetails(
    params?: {} | null,
    queryParms?: { locationID: string } | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations/${queryParms?.locationID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getLocationGroups(
    params?: {} | null,
    queryParms?: { locationID: string } | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations/${queryParms?.locationID}/groups`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getAssignableLocationGroups(params?: {} | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/assignablelocationgroups`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  // POST
  postLocation(params?: {} | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  postLocationGroup(params?: {}, queryParms?: { locationID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations/${queryParms?.locationID}/groups`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  // PUT
  putLocation(params?: {} | null, queryParms?: { locationID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations/${queryParms?.locationID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  // DELETE
  deleteLocation(
    params?: { locationID: string } | null,
    queryParms?: {} | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations/${params?.locationID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }

  deleteLocationGroup(
    params?: {},
    queryParms?: { locationID: string; groupID: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations/${queryParms?.locationID}/groups/${queryParms?.groupID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }

  getLocationJournals(
    params?: {
      page?: number;
      pagesize?: number;
      deviceID?: string;
      entryType?: string;
    },
    queryParms?: { locationID: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations/${queryParms?.locationID}/journal/entries`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postLocationJournalNotes(params?: {}, queryParms?: { locationId: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations/${queryParms?.locationId}/journal/notes`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params,
    };
  }

  getLocationJournalDetails(
    params?: {},
    queryParms?: { locationID: string; ts: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/locations/${queryParms?.locationID}/journal/entries/${queryParms?.ts}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }
}
export const locationApi = new LocationApi();

class UserApi {
  getUsers(params?: {} | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postUser(params?: {} | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  getUserDetails(params?: {} | null, queryParms?: { userID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users/${queryParms?.userID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  putUser(params?: {} | null, queryParms?: { userID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users/${queryParms?.userID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  deleteUser(params?: {} | null, queryParms?: { userID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users/${queryParms?.userID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }

  getGroups(params?: {} | null, queryParms?: { userID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users/${queryParms?.userID}/groups`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  deleteGroup(
    params?: {} | null,
    queryParms?: { userID: string; groupID: string } | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users/${queryParms?.userID}/groups/${queryParms?.groupID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }

  putGroup(
    params?: {} | null,
    queryParms?: { userID: string; groupID: string } | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users/${queryParms?.userID}/groups/${queryParms?.groupID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params,
    };
  }

  getApiKeys(params?: {} | null, queryParms?: { userID: string } | null) {
    const apiPath = getApiPath(apiService.api);
    const url = `${apiPath}/${apiService.api}/${apiVersion.v02}/users/${queryParms?.userID}/apikeys`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  putApiKeys(
    params?: {} | null,
    queryParms?: { userID: string; key: string } | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users/${queryParms?.userID}/apikeys/${queryParms?.key}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params,
    };
  }

  deleteApiKeys(
    params?: {} | null,
    queryParms?: { userID: string; key: string } | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/users/${queryParms?.userID}/apikeys/${queryParms?.key}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }

  getAssignableUserGroups(params?: {} | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/assignableusergroups`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }
}
export const userApi = new UserApi();

class GroupApi {
  getGroups(params?: {} | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/groups`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getGroupDetails(params?: {} | null, queryParms?: { groupID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/groups/${queryParms?.groupID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postGroup(
    params?: {},
    queryParms?: {},
    contentTypeKey?: HttpMethodContentType
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/groups`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentTypeKey
        ? contentType[contentTypeKey]
        : contentType.json,
      params,
    };
  }

  putGroup(params?: {} | null, queryParms?: { groupID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/groups/${queryParms?.groupID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  deleteGroup(params?: {} | null, queryParms?: { groupID: string } | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/groups/${queryParms?.groupID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }

  getGroupUsers(params?: {}, queryParms?: { groupID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/groups/${queryParms?.groupID}/users`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  getGroupAssignableUsers(params?: {}, queryParms?: { groupID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/groups/${queryParms?.groupID}/assignableusers`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getGroupAssignableLocations(params?: {}, queryParms?: { groupID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/groups/${queryParms?.groupID}/assignablelocations`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }
}
export const groupApi = new GroupApi();

class PartnerApi {
  getPartners(params?: { right: string } | null, queryParms?: {} | null) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/partners`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getPartnerDetails(params?: {}, queryParms?: { partnerID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/partners/${queryParms?.partnerID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  putPartnerDetails(params?: {}, queryParms?: { partnerID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/partners/${queryParms?.partnerID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params,
    };
  }

  postPartnerDetails(params?: {}, queryParms?: {}) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/partners`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params,
    };
  }

  deletePartnerDetails(params?: {}, queryParms?: { partnerId: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/partners/${queryParms?.partnerId}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }

  getPartnerRoles(params?: {}, queryParms?: { partnerID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/partners/${queryParms?.partnerID}/roles`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
    };
  }

  getPartnerPermissions(
    params?: { type: string } | null,
    queryParms?: { partnerID: string } | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/partners/${queryParms?.partnerID}/rights`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getPartnerPhenoms(
    params?: {} | null,
    queryParms?: { partnerID: string } | null
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/partners/${queryParms?.partnerID}/phenoms`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getPartnerTemplateRoles(params?: {}, queryParms?: {}) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/partners/TEMPLATE/roles`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }
}
export const partnerApi = new PartnerApi();

class FloorPlanApi {
  getFloorPlans(params?: {}, queryParms?: {}) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/floorplans`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  getFloorPlanDetails(params?: {}, queryParms?: { floorPlanID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/floorplans/${queryParms?.floorPlanID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postFloorPlan(params?: {}, queryParms?: {}) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/floorplans`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params,
    };
  }

  putFloorPlan(params?: {}, queryParms?: { floorPlanID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/floorplans/${queryParms?.floorPlanID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params,
    };
  }

  deleteFloorPlan(params?: {}, queryParms?: { floorPlanID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/floorplans/${queryParms?.floorPlanID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }

  getFloorPlanLocations(params?: {}, queryParms?: { floorPlanID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/floorplans/${queryParms?.floorPlanID}/locations`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  putFloorPlanLocation(
    params?: {},
    queryParms?: { floorPlanID: string; locationID: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/floorplans/${queryParms?.floorPlanID}/locations/${queryParms?.locationID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  /**
   * PATCH method for updating floor plan location with device(s)
   */
  patchFloorPlanLocation(
    params?: {},
    queryParms?: { floorPlanID: string; locationID: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/floorplans/${queryParms?.floorPlanID}/locations/${queryParms?.locationID}`;
    return {
      url,
      method: apiMethod.patch,
      contentType: contentType.formUrlEncoded,
      params,
    };
  }

  deleteFloorPlanLocation(
    params?: {},
    queryParms?: { floorPlanID: string; locationID: string }
  ) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/floorplans/${queryParms?.floorPlanID}/locations/${queryParms?.locationID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }
}
export const floorPlanApi = new FloorPlanApi();

class AssetApi {
  getAsset(params?: {}, queryParms?: { assetID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/asset/${queryParms?.assetID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postAsset(params?: {}, queryParms?: {}) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/asset`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.multipartFormData,
      params,
    };
  }
}
export const assetApi = new AssetApi();

class HardwareApi {
  getHardwareList(params?: {}, queryParms?: {}) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devicetemplates`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postHardwareDetails(params?: {}, queryParms?: {}) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devicetemplates`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params,
    };
  }

  getHardwareDetails(params?: {}, queryParms?: { templateID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devicetemplates/${queryParms?.templateID}`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  putHardwareDetails(params?: {}, queryParms?: { templateID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devicetemplates/${queryParms?.templateID}`;
    return {
      url,
      method: apiMethod.put,
      contentType: contentType.json,
      params,
    };
  }

  deleteHardwareDetails(params?: {}, queryParms?: { templateID: string }) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/devicetemplates/${queryParms?.templateID}`;
    return {
      url,
      method: apiMethod.delete,
      contentType: contentType.json,
      params,
    };
  }
}

export const hardwareApi = new HardwareApi();

class BuildingApi {
  getBuildings(params?: {}, queryParms?: {}) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/buildings`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  // @@  name, partnerID, address: {street: 'flat1',street2: 'high street',city: 'london',province: 'greater london',postalcode: 'so31 8ab',country: 'UK'}
  postBuilding(params?: {}, queryParms?: {}) {
    const apiPath = getApiPath(apiService.prod);
    const url = `${apiPath}/${apiService.prod}/${apiVersion.v01}/buildings`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params,
    };
  }
}

export const buildingApi = new BuildingApi();

class PrivateApi {
  getTags(params?: {}, queryParms?: {}) {
    const apiPath = getApiPathMaint();
    const url = `${apiPath}/${apiService.ghs}/${apiVersion.v01}/tags`;
    return {
      url,
      method: apiMethod.get,
      contentType: contentType.json,
      params,
    };
  }

  postIssues(params?: {}, queryParms?: {}) {
    const apiPath = getApiPathMaint();
    const url = `${apiPath}/${apiService.ghs}/${apiVersion.v01}/issues`;
    return {
      url,
      method: apiMethod.post,
      contentType: contentType.json,
      params,
    };
  }
}
export const privateApi = new PrivateApi();
