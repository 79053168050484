import { Card, Col, Row } from 'antd';
import { AxiosResponse } from 'axios';
import { Dispatch, Fragment, useReducer, useEffect, useCallback } from 'react';
import update from 'immutability-helper';
import Leaflet from 'leaflet';

import { assetApi, floorPlanApi } from '../../api-services/api-list';
import useHttp from '../../hooks/use-http-webview';
import { ReducerHookActionType } from '../../type-definitions';
import {
  ApiResponseDataType,
  FloorPlanLocationListType,
} from '../../type-definitions/api-types';
import FloorPlanMapMultiple from '../components/WebViewFloorPlanAdmin/FloorPlanMapMultiple';
import {
  PropsType,
  StateType,
  reducer,
  actionTypes,
} from '../components/WebViewFloorPlanAdmin/helpers';

const nativeWindow: any = window;

const WebViewFloorPlanAdmin = ({ match, location }: PropsType) => {
  let isEditable = false;
  const tempRight = match?.params?.isEditable;
  if (tempRight) {
    isEditable = JSON.parse(tempRight);
  }

  const search = location?.search;
  let assetID: string | undefined;
  let mapWidth: number | undefined;
  let mapHeight: number | undefined;
  let userLat: number | undefined;
  let userLng: number | undefined;
  let selectedLocationIDFromParam: string | undefined;
  let createLocation: boolean | undefined;
  let relocateLocation: boolean | undefined;

  if (search) {
    const params = new URLSearchParams(search);
    const tempAssetID = params.get('assetid');
    const tempWidth = params.get('mapwidth');
    const tempHeight = params.get('mapheight');
    const tempLat = params.get('lat');
    const tempLng = params.get('lng');
    const tempSelectedLoc = params.get('selectedlocationid');
    const tempCreateLoc = params.get('createLocation');
    const tempRelocateLoc = params.get('relocateLocation');

    if (tempAssetID) {
      assetID = tempAssetID;
    }
    if (tempWidth) {
      mapWidth = JSON.parse(tempWidth);
    }
    if (tempHeight) {
      mapHeight = JSON.parse(tempHeight);
    }
    if (tempLat) {
      userLat = JSON.parse(tempLat);
    }
    if (tempLng) {
      userLng = JSON.parse(tempLng);
    }
    if (tempSelectedLoc) {
      selectedLocationIDFromParam = tempSelectedLoc;
    }
    if (tempCreateLoc) {
      createLocation = JSON.parse(tempCreateLoc);
    }
    if (tempRelocateLoc) {
      relocateLocation = JSON.parse(tempRelocateLoc);
    }
  }

  const [state, dispatchToState]: [
    state: StateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, {
    loading: true,
    mapDetails: { width: mapWidth, height: mapHeight },
    floorPlanLocationList: [],
    selectedLocationDetails: {},
    isFloorPlanFetched: false,

    token: match?.params?.token,
    floorPlanID: match?.params?.floorPlanID,
    isEditable,

    assetID,
    userLat,
    userLng,
    selectedLocationIDFromParam,
    createLocation,
    relocateLocation,
  });
  const { isLoading, sendRequest } = useHttp();

  useEffect(() => {
    if (state.token && state.floorPlanID && state.assetID) {
      const floorPlanLocationsApi = floorPlanApi.getFloorPlanLocations(
        undefined,
        { floorPlanID: state.floorPlanID }
      );

      const assetImageApi = assetApi.getAsset(undefined, {
        assetID: state.assetID,
      });

      const handleResponses = (
        responses: AxiosResponse<ApiResponseDataType>[]
      ) => {
        if (responses.length > 0) {
          let floorPlans: Partial<FloorPlanLocationListType>[] | null =
            responses?.[0]?.data?.data;
          let apiResponseContentType = '';
          if (responses?.[1]?.headers) {
            apiResponseContentType = responses?.[1].headers?.['content-type'];
          }

          const assetImage =
            apiResponseContentType === 'image/x-icon'
              ? ''
              : responses?.[1]?.data;

          dispatchToState({
            type: actionTypes.setFloorPlansAndAsset,
            payload: {
              floorPlans,
              assetImage,
            },
          });
        }
      };

      sendRequest({
        requestConfig: [
          {
            url: floorPlanLocationsApi.url,
            method: floorPlanLocationsApi.method,
            contentType: floorPlanLocationsApi.contentType,
          },
          {
            url: assetImageApi.url,
            method: assetImageApi.method,
            contentType: assetImageApi.contentType,
          },
        ],
        headersConfig: { storeToken: state.token },
        applyData: handleResponses,
      });
    }
  }, [
    sendRequest,
    state.assetID,
    state.createLocation,
    state.floorPlanID,
    state.token,
  ]);

  useEffect(() => {
    let floorPlans: Partial<FloorPlanLocationListType>[] = [
      ...state.floorPlanLocationList,
    ];

    if (state.isFloorPlanFetched) {
      if (state.createLocation === true) {
        const tempObj = {
          floorplanID: state.floorPlanID,
          locationID: 'createLocation',
          // x: state.mapDetails.defaultCoordinates?.x || 0,
          // y: state.mapDetails.defaultCoordinates?.y || 0,
        };
        floorPlans = update(floorPlans, {
          $push: [tempObj],
        });
      }

      dispatchToState({
        type: actionTypes.setState,
        payload: {
          floorPlanLocationList: floorPlans,
          loading: false,
          isFloorPlanFetched: false,
        },
      });
    }
  }, [
    state.createLocation,
    state.floorPlanID,
    state.floorPlanLocationList,
    state.isFloorPlanFetched,
    state.mapDetails?.defaultCoordinates?.x,
    state.mapDetails?.defaultCoordinates?.y,
  ]);

  const onMarkerClick = useCallback(
    (locationDetails?: FloorPlanLocationListType) => {
      if (locationDetails) {
        dispatchToState({
          type: actionTypes.setState,
          payload: { selectedLocationDetails: locationDetails },
        });

        nativeWindow?.ReactNativeWebView?.postMessage?.(
          `locationID:${locationDetails.locationID}`
        );
      }
    },
    []
  );

  const onMapClick = useCallback(() => {
    dispatchToState({
      type: actionTypes.setState,
      payload: { selectedLocationDetails: {} },
    });
  }, []);

  const onMarkerMove = useCallback(
    (record: FloorPlanLocationListType) => {
      let tempFloorPlanLocationList = [...state.floorPlanLocationList];

      tempFloorPlanLocationList = tempFloorPlanLocationList.map((el) => {
        let tempObj = { ...el };
        if (el.locationID === record.locationID) {
          tempObj = update(tempObj, {
            x: { $set: record.x },
            y: { $set: record.y },
            position: { $set: record.position },
          });
        }
        return tempObj;
      });

      dispatchToState({
        type: actionTypes.setState,
        payload: {
          floorPlanLocationList: tempFloorPlanLocationList,
          selectedLocationDetails: record,
        },
      });

      nativeWindow?.ReactNativeWebView?.postMessage(
        `coordinates:${record?.x},${record?.y}`
      );

      nativeWindow?.ReactNativeWebView?.postMessage(
        `newCoordinates:${record?.x},${record?.y}`
      );

      nativeWindow?.ReactNativeWebView?.postMessage(
        `relocateLocationCoordinates:${record?.x},${record?.y}`
      );
    },
    [state.floorPlanLocationList]
  );

  // useEffect(() => {
  //   const btnSave = window.document.getElementById('btnSave')!;
  //   btnSave.onclick = () => {
  //     nativeWindow?.ReactNativeWebView?.postMessage(
  //       `coordinates:${state.selectedLocationDetails?.x},${state.selectedLocationDetails?.y}`
  //     );
  //   };
  // }, [
  //   state.selectedLocationDetails?.locationID,
  //   state.selectedLocationDetails?.x,
  //   state.selectedLocationDetails?.y,
  // ]);

  const setMapDefaultParameters = useCallback(
    (
      defaultPosition: Leaflet.LatLng,
      defaultCoordinates: { x: number; y: number }
    ) => {
      nativeWindow?.ReactNativeWebView?.postMessage(
        `newCoordinates:${defaultCoordinates?.x},${defaultCoordinates?.y}`
      );
      dispatchToState({
        type: actionTypes.setMapDetails,
        payload: { defaultPosition, defaultCoordinates },
      });
    },
    []
  );

  return (
    <Fragment>
      <Row style={{ height: '100vh' }}>
        <Col xs={24} style={{ height: '100vh' }}>
          <Row style={{ height: '100vh' }}>
            <Col xs={24} style={{ height: '100vh' }}>
              <Card
                style={{ height: '100vh' }}
                bodyStyle={{ height: '100vh', padding: 12 }}
                bordered={false}
                loading={isLoading || state.loading}>
                <FloorPlanMapMultiple
                  mapDetails={state.mapDetails}
                  floorPlanLocationList={state.floorPlanLocationList}
                  isEditable={state.isEditable}
                  classes={'floor-plan-map floor-plan-map-admin'}
                  onMarkerClick={onMarkerClick}
                  selectedLocationDetails={state.selectedLocationDetails}
                  onMapClick={onMapClick}
                  onUpdateLocation={onMarkerMove}
                  selectedLocationIDFromParam={
                    state.selectedLocationIDFromParam
                  }
                  setMapDefaultParameters={setMapDefaultParameters}
                  createLocation={state.createLocation}
                  relocateLocation={relocateLocation}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
};

export default WebViewFloorPlanAdmin;
