import { Component, Fragment, Key, ReactInstance, MouseEvent } from 'react';
import { RouteComponentProps } from 'react-router';
import { Menu, Row, Col, Card } from 'antd';
import { BiBuildingHouse } from 'react-icons/bi';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import { RiPencilFill } from 'react-icons/ri';
import { FiPlusSquare } from 'react-icons/fi';
import { v4 } from 'uuid';
import { Fab } from 'react-tiny-fab';

import CardWrapper from '../components-shared/CardWrapper';
import { StateType } from '../components/Buildings/helpers';
import MapContent from '../components/Buildings/MapContent';
import {
  AxiosHttpAllSettledResponsesType,
  BuildingFloorPlanType,
  BuildingListType,
  FloorPlanLocationListType,
  LocationListType,
  RequestConfigType,
  UserDataType,
} from '../type-definitions/api-types';
import { assetApi, buildingApi, floorPlanApi } from '../api-services/api-list';
import {
  httpCallAllSettled,
  allSettledErrorHandling,
} from '../api-services/api';
import { handleFormBody } from '../utils';
import { handleNotification } from '../utils/notification-handler';

import 'react-tiny-fab/dist/styles.css';
import cssStyles from '../components/Buildings/styles/buildings.module.scss';
import AddLocationModal from '../components/Buildings/AddLocationModal';
import withBreakpoint from '../HOC/withBreakpoint';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { MapCoordinatesType } from '@airsensa/react-components/dist/@types';
import { buildingRoutes, floorPlanRoutes } from '../Routes/routes-list';

const { SubMenu } = Menu;

interface PropsType extends RouteComponentProps {
  userData: Partial<UserDataType>;
  userPermissionList: string[];
  screens: Partial<Record<Breakpoint, boolean>>;
}

interface MenuInfo {
  key: Key;
  keyPath: Key[];
  item: ReactInstance;
  domEvent: MouseEvent<HTMLElement>;
}

class Buildings extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      rootSubmenuKeys: [], // submenu keys of first level
      openMenuKeys: ['sub_1'],
      selectedMenuItemKeys: ['sub_1_item_1'],
      buildingList: [],
      loading: true,
      selectedFloorPlan: {},
      mapDetails: {},
      floorPlanLocationList: [],
      showLocationModal: false,
      selectedLocationFromModal: {},
      defaultCoordinates: [0, 0],
    };
  }

  componentDidMount() {
    const { buildingList } = this.state;
    if (buildingList.length === 0) {
      this.fetchBuildings();
    }
  }

  fetchBuildings = () => {
    const { userData } = this.props;

    const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
      let buildingList: BuildingListType[] = [];
      if (responses.length > 0) {
        const stateData: any = {
          loading: false,
        };
        if (responses[0].status === 'fulfilled') {
          buildingList = responses[0].value?.data?.data;
          if (buildingList && buildingList.length > 0) {
            buildingList = buildingList.map((el) => ({ ...el, uuid: v4() }));
            const rootSubmenuKeys = buildingList.map(
              (_, idx) => `sub_${idx + 1}`
            );

            stateData.rootSubmenuKeys = rootSubmenuKeys;
            stateData.buildingList = buildingList;
          } else {
            stateData.buildingList = [];
          }
        } else {
          allSettledErrorHandling(responses[0]);
        }
        this.setState({ ...stateData }, () => {
          if (
            buildingList &&
            buildingList.length > 0 &&
            buildingList[0].floorplans?.[0]
          ) {
            this.onLocationSelect({ fp: buildingList[0].floorplans[0] });
          }
        });
      }
    };

    if (userData.token) {
      const apiDetails = buildingApi.getBuildings();
      httpCallAllSettled({
        requestConfig: [{ ...apiDetails }],
        headersConfig: { token: userData.token },
        applyData: handleResponses,
      });
    }
  };

  onLocationSelect = ({
    fp,
    fetchAsset = true,
    showLoading = true,
  }: {
    fp: BuildingFloorPlanType;
    fetchAsset?: boolean;
    showLoading?: boolean;
  }) => {
    const { userData } = this.props;
    const { mapDetails } = this.state;

    const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
      const stateData: Partial<StateType> = {
        mapDetails: { ...mapDetails },
        floorPlanLocationList: [],
      };
      stateData.mapDetails = {
        ...stateData.mapDetails,
        width: fp.assetWidth,
        height: fp.assetHeight,
      };

      if (responses?.[0]?.status === 'fulfilled') {
        const fpl: FloorPlanLocationListType[] = responses[0].value.data?.data;
        if (fpl) {
          if (
            stateData.mapDetails?.height !== undefined &&
            stateData.mapDetails?.width !== undefined &&
            stateData.mapDetails.height > 1000 &&
            stateData.mapDetails.width > 1000
          ) {
            stateData.floorPlanLocationList = fpl.map((el) => ({
              ...el,
              x: el.x / 10,
              y: el.y / 10,
              uuid: v4(),
            }));
          } else {
            stateData.floorPlanLocationList = fpl.map((el: any) => ({
              ...el,
              uuid: v4(),
            }));
          }
        }
      } else {
        allSettledErrorHandling(responses[0]);
      }

      if (responses?.[1]?.status === 'fulfilled') {
        const image: any = responses[1].value.data;
        if (image) {
          stateData.mapDetails = {
            ...stateData.mapDetails,
            image,
          };
        }
      } else if (responses?.[1]) {
        allSettledErrorHandling(responses[1]);
      }

      this.setState((prev) => ({
        ...prev,
        ...stateData,
        loading: false,
        selectedFloorPlan: fp,
        selectedLocationFromModal: {},
      }));
    };

    if (userData.token) {
      const fetchFloorPlanLocations = () => {
        const apiDetails = floorPlanApi.getFloorPlanLocations(undefined, {
          floorPlanID: fp.floorplanID,
        });

        const requestConfig: RequestConfigType[] = [{ ...apiDetails }];

        if (fetchAsset) {
          const apiDetails2 = assetApi.getAsset(undefined, {
            assetID: fp.assetID,
          });
          requestConfig.push({ ...apiDetails2 });
        }
        httpCallAllSettled({
          requestConfig: [...requestConfig],
          headersConfig: { token: userData.token },
          applyData: handleResponses,
        });
      };
      if (showLoading) {
        this.setState({ loading: true }, fetchFloorPlanLocations);
      } else {
        fetchFloorPlanLocations();
      }
    }
  };

  onUpdateLocation = async (locationData: FloorPlanLocationListType) => {
    const { userData } = this.props;
    const { selectedFloorPlan, mapDetails } = this.state;

    const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
      if (responses?.[0]?.status === 'fulfilled') {
        handleNotification('success', {
          message: responses?.[0]?.value?.data?.message,
        });
        if (selectedFloorPlan.floorplanID) {
          const temp = selectedFloorPlan as BuildingFloorPlanType;
          this.onLocationSelect({
            fp: temp,
            fetchAsset: false,
            showLoading: false,
          });
        }
      } else {
        allSettledErrorHandling(responses[0]);
      }
    };

    if (selectedFloorPlan.floorplanID && userData.token) {
      const floorPlanAPIUpdateMethod = locationData.deviceID
        ? floorPlanApi.patchFloorPlanLocation
        : floorPlanApi.putFloorPlanLocation;
      const apiDetails = floorPlanAPIUpdateMethod(undefined, {
        floorPlanID: selectedFloorPlan.floorplanID ?? '',
        locationID: locationData.locationID,
      });

      if (locationData.x !== undefined && locationData.y !== undefined) {
        const data = {
          x: locationData.x,
          y: locationData.y,
        };

        if (
          mapDetails?.height !== undefined &&
          mapDetails?.width !== undefined &&
          mapDetails.height > 1000 &&
          mapDetails.width > 1000
        ) {
          data.x = data.x * 10;
          data.y = data.y * 10;
        }
        const formData = handleFormBody(data);

        httpCallAllSettled({
          requestConfig: [{ ...apiDetails, data: formData }],
          headersConfig: { token: userData.token },
          applyData: handleResponses,
        });
      }
    }
  };

  onRemoveLocation = async (locationData: FloorPlanLocationListType) => {
    const { userData } = this.props;
    const { selectedFloorPlan } = this.state;

    const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
      if (responses?.[0]?.status === 'fulfilled') {
        handleNotification('success', {
          message: responses?.[0]?.value?.data?.message,
        });

        const temp = selectedFloorPlan as BuildingFloorPlanType;
        this.onLocationSelect({
          fp: temp,
          fetchAsset: false,
          showLoading: false,
        });
      } else if (responses?.[0]) {
        allSettledErrorHandling(responses[0]);
      }
    };

    if (selectedFloorPlan.floorplanID && userData.token) {
      const apiDetails = floorPlanApi.deleteFloorPlanLocation(undefined, {
        floorPlanID: selectedFloorPlan.floorplanID,
        locationID: locationData.locationID,
      });
      httpCallAllSettled({
        requestConfig: [{ ...apiDetails }],
        headersConfig: { token: userData.token },
        applyData: handleResponses,
      });
    }
  };

  onMenuOpenChange = (keys: any[]) => {
    const { openMenuKeys, rootSubmenuKeys } = this.state;

    const latestOpenKey = keys.find((key) => openMenuKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openMenuKeys: keys });
    } else {
      this.setState({ openMenuKeys: latestOpenKey ? [latestOpenKey] : [] });
    }
  };

  onMenuItemClicked = (parms: MenuInfo) => {
    this.setState({ selectedMenuItemKeys: [parms.key.toString()] });
  };

  onUpArrowClick = () => {
    const { buildingList, selectedMenuItemKeys } = this.state;

    if (
      selectedMenuItemKeys.length > 0 &&
      selectedMenuItemKeys[0] !== 'addFloorPlan'
    ) {
      const splitted = selectedMenuItemKeys?.[0]?.split?.('_');

      if (splitted && splitted.length > 0) {
        const currentMenu = Number(splitted?.[1]);
        const currentItem = Number(splitted?.[3]);

        const currentBuildingLength = buildingList.length;

        if (
          currentBuildingLength > 0 &&
          currentMenu !== undefined &&
          currentItem !== undefined
        ) {
          let menuCount = currentMenu;
          let itemCount = currentItem;
          if (menuCount > 1 && itemCount > 1) {
            itemCount -= 1;
          } else if (menuCount > 1 && itemCount === 1) {
            menuCount -= 1;
            itemCount = buildingList[menuCount - 1].floorplans.length;
          } else if (menuCount === 1 && itemCount > 1) {
            itemCount -= 1;
          } else if (menuCount === 1 && itemCount === 1) {
            menuCount = currentBuildingLength;
            itemCount = buildingList[menuCount - 1].floorplans.length;
          }

          const buildingData = buildingList[menuCount - 1];
          const currentFloorPlanLength =
            buildingData?.floorplans && buildingData.floorplans.length;

          if (currentFloorPlanLength > 0) {
            let menu = [`sub_${menuCount}`];
            let submenu = [`sub_${menuCount}_item_${itemCount}`];
            const tempBuildingData = buildingList[menuCount - 1];
            const floorPlanData = tempBuildingData.floorplans[itemCount - 1];

            this.setState(
              { openMenuKeys: menu, selectedMenuItemKeys: submenu },
              () => {
                floorPlanData &&
                  this.onLocationSelect({
                    fp: floorPlanData,
                    showLoading: true,
                    fetchAsset: true,
                  });
              }
            );
          }
        }
      }
    }
  };

  onDownArrowClick = () => {
    const { buildingList, selectedMenuItemKeys } = this.state;

    if (
      selectedMenuItemKeys.length > 0 &&
      selectedMenuItemKeys[0] !== 'addFloorPlan'
    ) {
      const splitted = selectedMenuItemKeys?.[0]?.split?.('_');

      if (splitted && splitted.length > 0) {
        const currentMenu = Number(splitted?.[1]);
        const currentItem = Number(splitted?.[3]);

        const currentBuildingLength = buildingList.length;

        if (
          currentBuildingLength > 0 &&
          currentMenu !== undefined &&
          currentItem !== undefined
        ) {
          const buildingData = buildingList[currentMenu - 1];
          const currentFloorPlanLength =
            buildingData?.floorplans && buildingData.floorplans.length;

          if (currentFloorPlanLength > 0) {
            let menu = [`sub_${currentMenu}`];
            let submenu = [`sub_${currentMenu}_item_${currentItem}`];
            if (
              currentBuildingLength > currentMenu &&
              currentFloorPlanLength > currentItem
            ) {
              submenu = [`sub_${currentMenu}_item_${currentItem + 1}`];
            } else if (
              currentBuildingLength > currentMenu &&
              currentFloorPlanLength === currentItem
            ) {
              menu = [`sub_${currentMenu + 1}`];
              submenu = [`sub_${currentMenu + 1}_item_1`];
            } else if (
              currentBuildingLength === currentMenu &&
              currentFloorPlanLength > currentItem
            ) {
              menu = [`sub_${currentMenu}`];
              submenu = [`sub_${currentMenu}_item_${currentItem + 1}`];
            } else if (
              currentBuildingLength === currentMenu &&
              currentFloorPlanLength === currentItem
            ) {
              menu = [`sub_1`];
              submenu = [`sub_1_item_1`];
            }

            const tempSplitted = submenu[0].split('_');
            const tempMenu = Number(tempSplitted?.[1]);
            const tempItem = Number(tempSplitted?.[3]);
            const tempBuildingData = buildingList[tempMenu - 1];
            const floorPlanData = tempBuildingData.floorplans[tempItem - 1];

            this.setState(
              { openMenuKeys: menu, selectedMenuItemKeys: submenu },
              () => {
                this.onLocationSelect({
                  fp: floorPlanData,
                  showLoading: true,
                  fetchAsset: true,
                });
              }
            );
          }
        }
      }
    }
  };

  setMapDefaultParameters = (coordinates: MapCoordinatesType) => {
    this.setState({ defaultCoordinates: coordinates });
  };

  handleAddLocationModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      showLocationModal: !prevState.showLocationModal,
    }));
  };

  onLocationSelectFromModal = (locationData: LocationListType) => {
    const { floorPlanLocationList, selectedFloorPlan, defaultCoordinates } =
      this.state;

    let tempFloorPlanLocationList = [...floorPlanLocationList];

    tempFloorPlanLocationList = tempFloorPlanLocationList.filter((item) => {
      return item.locationID !== locationData.locationID;
    });

    tempFloorPlanLocationList.push({
      floorplanID: selectedFloorPlan.floorplanID ?? '',
      locationID: locationData.locationID,
      deviceID: '',
      locationName: locationData.locationName,
      x: defaultCoordinates[0],
      y: defaultCoordinates[1],
      height: 1,
      lastContact: '',
      status: '',
      uuid: locationData.uuid,
    });

    this.setState({
      selectedLocationFromModal: locationData,
      showLocationModal: false,
      floorPlanLocationList: tempFloorPlanLocationList,
    });
  };

  onAddRedirect = () => {
    const { history } = this.props;
    history.push(floorPlanRoutes.add);
  };

  onEditRedirect = ({ fp }: { fp: BuildingFloorPlanType }) => {
    const { history } = this.props;
    const { floorplanID, assetID } = fp;
    history.push({
      pathname: floorPlanRoutes.details(),
      state: {
        floorPlanID: floorplanID,
        assetID,
      },
    });
  };

  onAddBuildingClick = () => {
    const { history } = this.props;
    history?.push?.(buildingRoutes.add);
  };

  render() {
    const {
      openMenuKeys,
      loading,
      buildingList,
      mapDetails,
      floorPlanLocationList,
      selectedMenuItemKeys,
      showLocationModal,
      selectedFloorPlan,
      selectedLocationFromModal,
    } = this.state;

    const { screens, userData } = this.props;

    let radiusCenterLat = userData.lat ?? 0;
    let radiusCenterLng = userData.lng ?? 0;
    if (selectedFloorPlan?.bounds?.bottomRightLat) {
      radiusCenterLat =
        (selectedFloorPlan.bounds.topLeftLat +
          selectedFloorPlan.bounds.bottomRightLat) /
        2;
      radiusCenterLng =
        (selectedFloorPlan.bounds.topLeftLng +
          selectedFloorPlan.bounds.bottomRightLng) /
        2;
    }

    return (
      <Fragment>
        <CardWrapper>
          <Row justify="center" align="middle">
            <Col
              xs={24}
              md={4}
              style={{ paddingLeft: 24, cursor: 'pointer' }}
              onClick={this.onAddBuildingClick}>
              <Row align="middle">
                <Col style={{ paddingTop: 4, paddingRight: 4 }}>
                  <FiPlusSquare size="1.4em" />
                </Col>
                <Col>Add Building</Col>
              </Row>
            </Col>
            <Col offset={20} />
          </Row>
          <Row
            justify="center"
            className={`h-100 ${cssStyles.root}`}
            gutter={[
              { xs: 32, sm: 32, md: 32, lg: 0 },
              { xs: 16, sm: 16, md: 16 },
            ]}>
            {buildingList.length > 0 ? (
              <Fragment>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={6}
                  className={`${cssStyles.menuParent} hide-scrollbar-y`}>
                  {buildingList.length > 0 && (
                    <Menu
                      mode={screens.lg ? 'inline' : 'horizontal'}
                      openKeys={openMenuKeys}
                      onOpenChange={this.onMenuOpenChange}
                      onClick={this.onMenuItemClicked}
                      selectedKeys={selectedMenuItemKeys}
                      style={{ width: '100%' }}>
                      {buildingList.map((el, idx) => {
                        return (
                          <Fragment key={el.uuid}>
                            <SubMenu
                              key={`sub_${idx + 1}`}
                              icon={<BiBuildingHouse />}
                              title={el.name}>
                              {el.floorplans && (
                                <Fragment>
                                  <Menu.Item
                                    key="addFloorPlan"
                                    onClick={this.onAddRedirect}>
                                    <Row gutter={[8, 0]} align="middle">
                                      <Col style={{ paddingTop: 4 }}>
                                        <FiPlusSquare size="1.2em" />
                                      </Col>
                                      <Col>Add Floor</Col>
                                    </Row>
                                  </Menu.Item>
                                  {el.floorplans.map((fp, i) => {
                                    return (
                                      <Fragment key={i}>
                                        <Menu.Item
                                          key={`sub_${idx + 1}_item_${i + 1}`}>
                                          <Row gutter={[8, 0]} align="middle">
                                            <Col
                                              style={{ paddingTop: 4 }}
                                              onClick={() =>
                                                this.onEditRedirect({ fp })
                                              }>
                                              <RiPencilFill size="1.2em" />
                                            </Col>
                                            <Col
                                              onClick={() =>
                                                this.onLocationSelect({ fp })
                                              }>
                                              {fp.name}
                                            </Col>
                                          </Row>
                                        </Menu.Item>
                                      </Fragment>
                                    );
                                  })}
                                </Fragment>
                              )}
                            </SubMenu>
                          </Fragment>
                        );
                      })}
                    </Menu>
                  )}
                </Col>
              </Fragment>
            ) : (
              <Fragment />
            )}

            {loading ? (
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={18}
                className={`pl-5 ${cssStyles.mapCol}`}>
                <Card
                  loading={loading}
                  className="h-100"
                  bodyStyle={{ height: '100%', padding: 0 }}
                  bordered={false}>
                  <Fragment />
                </Card>
              </Col>
            ) : (
              <Fragment>
                {buildingList.length > 0 ? (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={18}
                    className={`pl-5 ${cssStyles.mapCol}`}>
                    <Card
                      loading={loading}
                      className="h-100"
                      bodyStyle={{ height: '100%', padding: 0 }}
                      bordered={false}>
                      <MapContent
                        mapDetails={mapDetails}
                        floorPlanLocationList={floorPlanLocationList}
                        onRemoveLocation={this.onRemoveLocation}
                        onUpdateLocation={this.onUpdateLocation}
                        setMapDefaultParameters={this.setMapDefaultParameters}
                        selectedLocationFromModal={selectedLocationFromModal}
                        selectedFloorPlan={selectedFloorPlan}
                        token={userData.token!}
                        buildingList={buildingList}
                      />
                    </Card>
                  </Col>
                ) : (
                  <Fragment>
                    <Col
                      className="text-center"
                      style={{
                        fontSize: 24,
                        color: `#1890FF`,
                        fontStyle: 'italic',
                        fontFamily: `"Poppins","Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif`,
                      }}>
                      No buildings to show
                    </Col>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Row>

          {buildingList.length > 0 && (
            <Fragment>
              <Fab
                mainButtonStyles={{
                  backgroundColor: '#1890ff',
                  color: '#fff',
                  fontSize: 16,
                }}
                style={{ bottom: 156, right: 24 }}
                icon={
                  <Fragment>
                    <Row>
                      <Col xs={24}>
                        <BsFillCaretUpFill />
                      </Col>
                    </Row>
                  </Fragment>
                }
                event={'click'}
                alwaysShowTitle={false}
                onClick={this.onUpArrowClick}></Fab>

              <Fab
                mainButtonStyles={{
                  backgroundColor: '#1890ff',
                  color: '#fff',
                  fontSize: 16,
                }}
                style={{ bottom: 88, right: 24 }}
                icon={
                  <Fragment>
                    <Row align="middle">
                      <Col xs={24}>
                        <BsFillCaretDownFill />
                      </Col>
                    </Row>
                  </Fragment>
                }
                event={'click'}
                alwaysShowTitle={false}
                onClick={this.onDownArrowClick}></Fab>

              <Fab
                mainButtonStyles={{
                  backgroundColor: '#1890ff',
                  color: '#fff',
                  fontSize: 24,
                }}
                icon={'+'}
                event={'click'}
                alwaysShowTitle={false}
                onClick={this.handleAddLocationModal}></Fab>
            </Fragment>
          )}
        </CardWrapper>

        {showLocationModal && (
          <AddLocationModal
            userData={userData}
            showLocationModal={showLocationModal}
            handleAddLocationModal={this.handleAddLocationModal}
            onLocationSelectModal={this.onLocationSelectFromModal}
            floorPlanLocationList={floorPlanLocationList}
            radiusCenterLat={radiusCenterLat}
            radiusCenterLng={radiusCenterLng}
          />
        )}
      </Fragment>
    );
  }
}

export default withBreakpoint(Buildings);
