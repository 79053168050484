import { ReactNode, Fragment } from 'react';
import { Row, Col } from 'antd';

import AntdCoverSpinner from '../AntdCoverSpinner';
import AntdCard from '../../components-shared/AntdCard';

type PropsType = Partial<{
  title: string;
  children: ReactNode;
  loading: boolean;
  cardClasses: string;
}>;

function FormWrapper({ title, children, loading, cardClasses }: PropsType) {
  return (
    <Fragment>
      <AntdCoverSpinner active={loading} size="large">
        <Row justify="center">
          <Col xs={22} sm={20} md={16} lg={12}>
            <AntdCard className={cardClasses}>
              <Row>
                <Col xs={24}>
                  <h5>
                    <strong>{title}</strong>
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col xs={24}>{children}</Col>
              </Row>
            </AntdCard>
          </Col>
        </Row>
      </AntdCoverSpinner>
    </Fragment>
  );
}

export default FormWrapper;
