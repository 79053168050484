import { FloorPlanMapDetailsType } from '@airsensa/react-components/dist/@types';
import { Button, Row, Col } from 'antd';
import { Fragment } from 'react';

import {
  FloorPlanLocationListType,
  LocationListType,
} from '../../../type-definitions/api-types';

import MapContent from './MapContent';

function LocationsTab({
  mapDetails,
  floorPlanLocationList,
  selectedLocation,
  onUpdateLocation,
  onRemoveLocation,
  handleAddLocationModal,
  setMapDefaultParameters,
}: {
  mapDetails: FloorPlanMapDetailsType;
  floorPlanLocationList: FloorPlanLocationListType[];
  selectedLocation: Partial<LocationListType>;
  onUpdateLocation: (data: FloorPlanLocationListType) => void;
  onRemoveLocation: (data: FloorPlanLocationListType) => void;
  handleAddLocationModal: () => void;
  setMapDefaultParameters: (coordinates: { x: number; y: number }) => void;
}) {
  return (
    <Fragment>
      <Row justify="center" className="py-3">
        <Col xs={24} sm={12} lg={16} className="text-center">
          <Button onClick={handleAddLocationModal} type="primary" size="middle">
            Add Location
          </Button>
        </Col>
      </Row>

      <MapContent
        mapDetails={mapDetails}
        selectedLocation={selectedLocation}
        floorPlanLocationList={floorPlanLocationList}
        setMapDefaultParameters={setMapDefaultParameters}
        onUpdateLocation={onUpdateLocation}
        onRemoveLocation={onRemoveLocation}
      />
    </Fragment>
  );
}

export default LocationsTab;
