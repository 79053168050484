import {
  ChangeEvent,
  Dispatch,
  Fragment,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Modal,
  Skeleton,
  message,
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { AiOutlineClear } from 'react-icons/ai';

import { deviceApi } from '../../../api-services/api-list';
import useHttp from '../../../hooks/use-http-allSettled';
import { getUserData } from '../../../redux/selectors';
import {
  AntdTableColumnsType,
  FormInputType,
  ReducerHookActionType,
} from '../../../type-definitions';
import {
  AxiosHttpAllSettledResponsesType,
  JournalListType,
  RequestConfigType,
} from '../../../type-definitions/api-types';
import {
  JournalTabStateType,
  initJournalState,
  reducer,
  actionTypes,
} from '../helpers';
import { useCallback } from 'react';
import { handleTableSearch } from '../../../utils';

import cssStyles from '../styles/deviceDetails.module.scss';
import { handleNotification } from '../../../utils/notification-handler';
import AntdInput from '../../AntdInput';
import { allSettledErrorHandling } from '../../../api-services/api';
import AntdTable from '../../AntdTable';
import SwapContent from '../../../shared/components/Journal/SwapContent';
import NoteContent from '../../../shared/components/Journal/NoteContent';
import MovementContent from '../../../shared/components/Journal/MovementContent';
import AssignmentContent from '../../../shared/components/Journal/AssignmentContent';
import RemovalContent from '../../../shared/components/Journal/RemovalContent';
import GroupRemovalContent from '../../../shared/components/Journal/GroupRemovalContent';
import GroupAssignmentContent from '../../../shared/components/Journal/GroupAssignmentContent';

interface PropsType {
  deviceID?: string;
  journalNote: FormInputType;
  inputChangedHandler: (name: any, value: any) => void;
}

const JournalTab = ({
  deviceID,
  journalNote,
  inputChangedHandler,
}: PropsType) => {
  const [state, dispatchToState]: [
    state: JournalTabStateType,
    dispatchToState: Dispatch<ReducerHookActionType>
  ] = useReducer(reducer, initJournalState);
  const { isLoading, sendRequest } = useHttp();
  const userData = useSelector(getUserData);

  const {
    journalList,
    initJournalList,
    searchValue,
    tablePagination,
    locationIDList,
    entryTypeList,
    filterEntryTypeValue,
    filterLocationIDValue,
    showNoteModal,
    selectedJournalTS,
  } = state;

  const fetchData = useCallback(
    ({
      currentPage,
      entryType,
      locationID,
    }: {
      currentPage: number;
      entryType?: string;
      locationID?: string;
    }) => {
      if (userData.token && deviceID) {
        const { url, method, contentType, params } =
          deviceApi.getDeviceJournals(
            {
              page: currentPage,
              pagesize: tablePagination.perPage,
              entryType,
              locationID,
            },
            { deviceID }
          );

        const handleResponses = (
          responses: AxiosHttpAllSettledResponsesType
        ) => {
          if (responses.length > 0) {
            if (responses[0].status === 'fulfilled') {
              const result = responses?.[0].value;
              if (result.data?.data) {
                const data = result.data.data;
                let journalEntries: JournalListType[] = data?.entries;
                if (journalEntries) {
                  journalEntries = journalEntries.map((el) => ({
                    ...el,
                    uuid: v4(),
                  }));

                  let payload: {
                    journalList: JournalListType[];
                    initJournalList: JournalListType[];
                    locationIDList?: string[];
                    entryTypeList?: string[];
                    tablePagination: {
                      totalCount: number;
                      currentPage: number;
                    };
                  } = {
                    journalList: journalEntries,
                    initJournalList: journalEntries,
                    tablePagination: {
                      totalCount: data?.totalCount || 0,
                      currentPage,
                    },
                  };

                  if (!entryType && !locationID) {
                    let tempLocIDs = journalEntries.map(
                      (el) => el.locationID && el.locationID
                    );
                    tempLocIDs = Array.from(new Set(tempLocIDs));

                    let tempEntryTypes = journalEntries.map(
                      (el) => el.entryType && el.entryType
                    );
                    tempEntryTypes = Array.from(new Set(tempEntryTypes));

                    payload.locationIDList = tempLocIDs;
                    payload.entryTypeList = tempEntryTypes;
                  }

                  dispatchToState({
                    type: actionTypes.setInitJournals,
                    payload: {
                      ...payload,
                    },
                  });
                }
              }
            } else {
              handleNotification('error', {
                message: responses[0].reason?.response?.data?.message,
              });
            }
          }
        };

        sendRequest({
          requestConfig: [{ url, method, contentType, params }],
          headersConfig: { storeToken: userData.token },
          applyData: handleResponses,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deviceID, sendRequest, userData.token]
  );

  useEffect(() => {
    fetchData({ currentPage: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearchValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e?.currentTarget?.value;
      let tempInitJournals = cloneDeep(initJournalList);
      if (value) {
        const columns = getColumns().map((el) => el.key);
        const tempJournals = handleTableSearch({
          data: tempInitJournals,
          columnList: columns,
          searchData: value,
        });

        dispatchToState({
          type: actionTypes.setSearchValue,
          payload: {
            searchValue: e.currentTarget.value,
            journalList: tempJournals,
            tablePagination: {
              currentPage: 1,
              lastPagination: !searchValue
                ? tablePagination.currentPage
                : tablePagination.lastPagination,
            },
          },
        });
      } else {
        dispatchToState({
          type: actionTypes.setSearchValue,
          payload: {
            searchValue: e.currentTarget.value,
            journalList: tempInitJournals,
            tablePagination: { currentPage: tablePagination.lastPagination },
          },
        });
      }
    },
    [
      initJournalList,
      searchValue,
      tablePagination.currentPage,
      tablePagination.lastPagination,
    ]
  );

  const fetchMultipleData = useCallback(
    (apiCollections: RequestConfigType[]) => {
      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        if (responses.length > 0) {
          let journalEntries: JournalListType[] = [];
          responses.forEach((el) => {
            if (el.status === 'fulfilled') {
              const result = el.value;
              if (result.data?.data) {
                const data = result.data.data;
                let tempJournals: JournalListType[] = data?.entries;
                if (tempJournals) {
                  tempJournals = tempJournals.map((el) => ({
                    ...el,
                    uuid: v4(),
                  }));

                  journalEntries.push(...tempJournals);
                }
              }
            } else {
              handleNotification('error', {
                message: el.reason?.response?.data?.message,
              });
            }
          });
          const currentPage =
            apiCollections[apiCollections.length - 1].params?.page;

          dispatchToState({
            type: actionTypes.setJournals,
            payload: {
              journalList: journalEntries,
              initJournalList: journalEntries,
              tablePagination: {
                currentPage,
              },
            },
          });
        }
      };

      sendRequest({
        requestConfig: [...apiCollections],
        headersConfig: { storeToken: userData.token },
        applyData: handleResponses,
      });
    },
    [sendRequest, userData.token]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize?: number) => {
      if (initJournalList.length < tablePagination.totalCount) {
        if (tablePagination.currentPage + 1 === page) {
          fetchData({
            currentPage: page,
            entryType: filterEntryTypeValue || undefined,
            locationID: filterLocationIDValue || undefined,
          });
        } else if (page > tablePagination.currentPage + 1) {
          let apiCollections: RequestConfigType[] = [];
          for (
            let index = tablePagination.currentPage + 1;
            index <= page;
            index++
          ) {
            if (deviceID) {
              const { url, method, contentType, params } =
                deviceApi.getDeviceJournals(
                  {
                    page: index,
                    pagesize: tablePagination.perPage,
                    entryType: filterEntryTypeValue || undefined,
                    locationID: filterLocationIDValue || undefined,
                  },
                  { deviceID }
                );

              apiCollections.push({ url, method, contentType, params });
            }
          }

          fetchMultipleData(apiCollections);
        } else {
          dispatchToState({
            type: actionTypes.resetPagination,
            payload: {
              currentPage: page,
            },
          });
        }
      } else {
        dispatchToState({
          type: actionTypes.setPagination,
          payload: {
            currentPage: page,
          },
        });
      }
    },
    [
      deviceID,
      fetchData,
      fetchMultipleData,
      filterEntryTypeValue,
      filterLocationIDValue,
      initJournalList.length,
      tablePagination.currentPage,
      tablePagination.perPage,
      tablePagination.totalCount,
    ]
  );

  const onSelectChange = useCallback(
    (value: string, name: 'filterLocationIDValue' | 'filterEntryTypeValue') => {
      if (value && name) {
        dispatchToState({
          type: actionTypes.setSelectValue,
          payload: { value, name },
        });

        fetchData({
          currentPage: 1,
          entryType: name === 'filterEntryTypeValue' ? value : undefined,
          locationID: name === 'filterLocationIDValue' ? value : undefined,
        });
      }
    },
    [fetchData]
  );

  const onSelectClear = useCallback(
    (name: 'filterLocationIDValue' | 'filterEntryTypeValue') => {
      dispatchToState({
        type: actionTypes.clearSelectValue,
        payload: { name },
      });

      fetchData({ currentPage: 1 });
    },
    [fetchData]
  );

  let paginationTotal = tablePagination.totalCount;
  if (initJournalList.length === tablePagination.totalCount) {
    paginationTotal = journalList.length;
  }
  if (state.searchValue) {
    paginationTotal = journalList.length;
  }

  const onModalOkay = () => {
    if (!journalNote.value) {
      message.warning('Please fill the field!');
      return;
    }

    if (deviceID) {
      const handleResponses = (responses: AxiosHttpAllSettledResponsesType) => {
        if (responses?.[0].status === 'fulfilled') {
          handleNotification('success', {
            message: responses[0].value.data?.data,
          });
          dispatchToState({
            type: actionTypes.setState,
            payload: { showNoteModal: false },
          });
          inputChangedHandler('journalNote', '');
          fetchData({ currentPage: 1 });
        } else {
          allSettledErrorHandling(responses[0]);
        }
      };

      const apiDetails = deviceApi.postDeviceJournalNotes(undefined, {
        deviceID,
      });

      sendRequest({
        requestConfig: [{ ...apiDetails, data: { notes: journalNote.value } }],
        headersConfig: { storeToken: userData.token },
        applyData: handleResponses,
      });
    }
  };

  const onOpenJournalDetailsModal = (record: any) => {
    dispatchToState({
      type: actionTypes.setState,
      payload: {
        selectedJournalTS: record.ts,
      },
    });
  };

  const onCloseJournalDetailsModal = () => {
    dispatchToState({
      type: actionTypes.setState,
      payload: { selectedJournalTS: '' },
    });
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Row justify="center">
            <Col>
              <Button
                type="primary"
                onClick={() =>
                  dispatchToState({
                    type: actionTypes.setState,
                    payload: { showNoteModal: true },
                  })
                }>
                Add Note
              </Button>
            </Col>
          </Row>
          <Row justify="center" align="middle" className="pt-3">
            <Col xs={24} md={12} className="pr-md-1">
              <Select
                id="filterLocationIDValue"
                allowClear
                clearIcon={<AiOutlineClear size="1.5em" color="#40A9FF" />}
                className={cssStyles.journalTabSelect}
                value={filterLocationIDValue || undefined}
                onChange={(value) =>
                  onSelectChange(value, 'filterLocationIDValue')
                }
                onClear={() => onSelectClear('filterLocationIDValue')}
                style={{ width: '100%' }}
                placeholder="Select Location...">
                {locationIDList.length > 0 &&
                  locationIDList.map((item, index) => {
                    if (item) {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    }
                    return null;
                  })}
              </Select>
            </Col>

            <Col xs={24} md={12} className="pl-md-1 pt-2 pt-md-0">
              <Select
                id="filterEntryTypeValue"
                allowClear
                clearIcon={<AiOutlineClear size="1.5em" color="#40A9FF" />}
                className={cssStyles.journalTabSelect}
                value={filterEntryTypeValue || undefined}
                onChange={(value) =>
                  onSelectChange(value, 'filterEntryTypeValue')
                }
                onClear={() => onSelectClear('filterEntryTypeValue')}
                style={{ width: '100%' }}
                placeholder="Select EntryType...">
                {entryTypeList.length > 0 &&
                  entryTypeList.map((item, index) => {
                    if (item) {
                      return (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      );
                    }
                    return null;
                  })}
              </Select>
            </Col>
          </Row>

          <Row justify="end" className="pt-3 pb-3">
            <Col>
              <Input
                value={searchValue}
                onChange={onSearchValueChange}
                placeholder="Search..."
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <AntdTable
                dataSource={journalList}
                columns={getColumns()}
                loading={isLoading}
                pagination={{
                  responsive: true,
                  total: paginationTotal,
                  pageSize: 10,
                  size: 'small',
                  showSizeChanger: false,
                  onChange: onPaginationChange,
                  current: tablePagination.currentPage,
                }}
                customConfig={{
                  rowKeyValue: 'uuid',
                  onRowClick: onOpenJournalDetailsModal,
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        closable={false}
        visible={showNoteModal}
        onCancel={() =>
          dispatchToState({
            type: actionTypes.setState,
            payload: { showNoteModal: false },
          })
        }
        onOk={onModalOkay}>
        <Row justify="center">
          <Col>
            <AntdInput {...journalNote} onInputChanged={inputChangedHandler} />
          </Col>
        </Row>
      </Modal>

      {!!selectedJournalTS && (
        <JournalDetailsModal
          showModal={!!selectedJournalTS}
          onClose={onCloseJournalDetailsModal}
          timeStamp={selectedJournalTS}
          deviceID={deviceID}
        />
      )}
    </Fragment>
  );
};
export default JournalTab;
const getColumns = (): AntdTableColumnsType<any>[] => {
  return [
    { title: 'Location ID', key: 'locationID', dataIndex: 'locationID' },
    { title: 'Time Stamp', key: 'ts', dataIndex: 'ts' },
    { title: 'Entry Type', key: 'entryType', dataIndex: 'entryType' },
    { title: 'Description', key: 'text', dataIndex: 'text' },
  ];
};

interface JournalDetailsModalPropsType {
  showModal: boolean;
  onClose: () => void;
  timeStamp: string;
  deviceID?: string;
}
export const JournalDetailsModal = ({
  showModal,
  onClose,
  timeStamp,
  deviceID,
}: JournalDetailsModalPropsType) => {
  const { isLoading, sendRequest } = useHttp();
  const [journalDetails, setJournalDetails] = useState<
    Partial<JournalListType>
  >({});
  const userData = useSelector(getUserData);

  useEffect(() => {
    if (timeStamp && !journalDetails.ts && deviceID && userData.token) {
      const handleResponses = (respones: AxiosHttpAllSettledResponsesType) => {
        if (respones?.[0].status === 'fulfilled') {
          const data = respones[0].value?.data?.data;
          if (data) {
            setJournalDetails(data);
          }
        } else {
          allSettledErrorHandling(respones?.[0]);
        }
      };

      const apiDetails = deviceApi.getDeviceJournalDetails(undefined, {
        deviceID: deviceID,
        ts: timeStamp,
      });

      sendRequest({
        requestConfig: [{ ...apiDetails }],
        headersConfig: { storeToken: userData.token },
        applyData: handleResponses,
      });
    }
  }, [timeStamp, journalDetails, deviceID, userData.token, sendRequest]);

  let content = <Fragment></Fragment>;
  if (journalDetails?.entryType === 'DeviceSwap') {
    content = (
      <SwapContent
        deviceID={journalDetails?.deviceID ?? ''}
        locationID={journalDetails.locationID ?? ''}
        otherDevice={journalDetails?.data?.otherDevice ?? ''}
        otherLocation={journalDetails?.data?.otherLocation ?? ''}
        userName={journalDetails.userName ?? ''}
        entryType={journalDetails.entryType ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'DeviceNote') {
    content = (
      <NoteContent
        text={journalDetails.data}
        userName={journalDetails.userName ?? ''}
        entryType={journalDetails.entryType ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'DeviceMovement') {
    content = (
      <MovementContent
        deviceID={journalDetails?.deviceID ?? ''}
        locationID={journalDetails.locationID ?? ''}
        otherLocation={journalDetails?.data?.from ?? ''}
        userName={journalDetails.userName ?? ''}
        entryType={journalDetails.entryType ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'DeviceAssignment') {
    content = (
      <AssignmentContent
        deviceID={journalDetails?.deviceID ?? ''}
        locationID={journalDetails.locationID ?? ''}
        otherLocation={journalDetails?.data?.from ?? ''}
        userName={journalDetails.userName ?? ''}
        entryType={journalDetails.entryType ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'DeviceRemoval') {
    content = (
      <RemovalContent
        deviceID={journalDetails?.deviceID ?? ''}
        locationID={journalDetails.locationID ?? ''}
        otherDevice={journalDetails.data?.deviceID ?? ''}
        otherLocation={journalDetails?.data?.to ?? ''}
        userName={journalDetails.userName ?? ''}
        entryType={journalDetails.entryType ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'LocationGroupRemoval') {
    content = (
      <GroupRemovalContent
        locationID={journalDetails.locationID ?? ''}
        groupID={journalDetails.data?.groupID ?? ''}
        userName={journalDetails.userName ?? ''}
        entryType={journalDetails.entryType ?? ''}
      />
    );
  } else if (journalDetails?.entryType === 'LocationGroupAssignment') {
    content = (
      <GroupAssignmentContent
        locationID={journalDetails.locationID ?? ''}
        groupID={journalDetails.data?.groupID ?? ''}
        userName={journalDetails.userName ?? ''}
        entryType={journalDetails.entryType ?? ''}
      />
    );
  }

  return (
    <Fragment>
      <Modal
        closable={false}
        visible={showModal}
        onCancel={onClose}
        footer={null}
        width={720}
        style={{ height: 400 }}
        className={`antdModalBodyFull test ${cssStyles.journalModal}`}>
        {isLoading ? <Skeleton loading={isLoading} active /> : content}
      </Modal>
    </Fragment>
  );
};
