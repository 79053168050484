import { Dispatch } from 'redux';
import { updateToken } from '../../redux/actions';

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function dispatchUpdateToken(dispatch: Dispatch<any>, response: any) {
  const result = response?.value?.data;
  result && dispatch(updateToken(result));
}
